import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BurgerMenuComponent} from "./components/burger-menu/burger-menu.component";
import {RouterModule} from "@angular/router";
import {SafeHtmlPipe} from "./pipes/safe-html.pipe";
import {TestimonialCardComponent} from "./components/testimonial-card/testimonial-card.component";
import {
  EmploymentPositionCardComponent
} from "./components/employment-position-card/employment-position-card.component";
import {ShareDialogComponent} from "./components/share-dialog/share-dialog.component";
import {ClickOutsideDirective} from "./directives/click-outside.directive";
import {BasicLoadingSkeletonComponent} from "./components/basic-loading-skeleton/basic-loading-skeleton.component";
import {WorkTypePipe} from "./pipes/work-type.pipe";
import {ContactFormularComponent} from "../general/contact-formular/contact-formular.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {AccordionComponent} from "./components/accordion/accordion.component";
import {CompetitionComparisonComponent} from "./components/competition-comparison/competition-comparison.component";


@NgModule({
  declarations: [
    BurgerMenuComponent,
    SafeHtmlPipe,
    WorkTypePipe,
    TestimonialCardComponent,
    EmploymentPositionCardComponent,
    ShareDialogComponent,
    ClickOutsideDirective,
    BasicLoadingSkeletonComponent,
    ContactFormularComponent,
    AccordionComponent,
    CompetitionComparisonComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MatCheckboxModule
    ],
    exports: [
        BurgerMenuComponent,
        SafeHtmlPipe,
        WorkTypePipe,
        TestimonialCardComponent,
        EmploymentPositionCardComponent,
        ShareDialogComponent,
        BasicLoadingSkeletonComponent,
        ContactFormularComponent,
        AccordionComponent,
        ClickOutsideDirective,
        CompetitionComparisonComponent
    ]
})
export class UtilityModule { }
