import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {CreateSubjectGroupToProfessionMappingData, JsonWrappedValue, CreateSubjectToGroupMappingData, SubjectProfessionPathData, LocationOptionsData, ProfessionRelationData, TalentMatchingReportData, PagedData, SubjectMappingMatchesData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.ProfessionMatchingOptimizingController
 */

@Injectable()
@ResourceParams({})
export class ProfessionMatchingOptimizingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/matching/professionToSubjectGroupMappings',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addProfessionToSubjectGroupMapping: IResourceMethodObservableStrict<null, {subjectGroupId: number, professionId: number}, null, null> | undefined
    addProfessionToSubjectGroupMapping(requestParams: {subjectGroupId: number, professionId: number}): Promise<null> {
        if (!this._addProfessionToSubjectGroupMapping) throw new Error("resource has not been properly initialized")
        return this._addProfessionToSubjectGroupMapping(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/matching/subjectGroupToProfessionMatchings',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addSubjectGroupToProfessionMapping: IResourceMethodObservableStrict<CreateSubjectGroupToProfessionMappingData, null, null, null> | undefined
    addSubjectGroupToProfessionMapping(requestBody: CreateSubjectGroupToProfessionMappingData): Promise<null> {
        if (!this._addSubjectGroupToProfessionMapping) throw new Error("resource has not been properly initialized")
        return this._addSubjectGroupToProfessionMapping(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/matching/subjectToGroupMappings',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addSubjectToGroupMapping: IResourceMethodObservableStrict<CreateSubjectToGroupMappingData, null, null, null> | undefined
    addSubjectToGroupMapping(requestBody: CreateSubjectToGroupMappingData): Promise<null> {
        if (!this._addSubjectToGroupMapping) throw new Error("resource has not been properly initialized")
        return this._addSubjectToGroupMapping(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/matching/profession/{id}/matches',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _countPossibleMatchesForProfessionAndLocation: IResourceMethodObservableStrict<LocationOptionsData, null, {id: number}, JsonWrappedValue<number>> | undefined
    countPossibleMatchesForProfessionAndLocation(requestBody: LocationOptionsData, id: number): Promise<JsonWrappedValue<number>> {
        if (!this._countPossibleMatchesForProfessionAndLocation) throw new Error("resource has not been properly initialized")
        return this._countPossibleMatchesForProfessionAndLocation(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/pathfinder',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllPathsBetweenSubjectAndProfession: IResourceMethodObservableStrict<null, {subjectId: number, professionId: number}, null, SubjectProfessionPathData[]> | undefined
    getAllPathsBetweenSubjectAndProfession(requestParams: {subjectId: number, professionId: number}): Promise<SubjectProfessionPathData[]> {
        if (!this._getAllPathsBetweenSubjectAndProfession) throw new Error("resource has not been properly initialized")
        return this._getAllPathsBetweenSubjectAndProfession(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/reports/{talentId}/{positionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMatchingReport: IResourceMethodObservableStrict<null, null, {talentId: number, positionId: number}, TalentMatchingReportData> | undefined
    getMatchingReport(pathVariables: {talentId: number, positionId: number}): Promise<TalentMatchingReportData> {
        if (!this._getMatchingReport) throw new Error("resource has not been properly initialized")
        return this._getMatchingReport(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/reports',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMatchingReports: IResourceMethodObservableStrict<null, {page: number, pageSize: number, positionIds: number[], matchingLevels: ('Unmatchable' | 'NoMatch' | 'Area' | 'MainGroup' | 'Group' | 'SubGroup' | 'ProfessionType' | 'Profession' | 'Filter')[]}, null, PagedData<TalentMatchingReportData>> | undefined
    getMatchingReports(requestParams: {page: number, pageSize: number, positionIds: number[], matchingLevels: ('Unmatchable' | 'NoMatch' | 'Area' | 'MainGroup' | 'Group' | 'SubGroup' | 'ProfessionType' | 'Profession' | 'Filter')[]}): Promise<PagedData<TalentMatchingReportData>> {
        if (!this._getMatchingReports) throw new Error("resource has not been properly initialized")
        return this._getMatchingReports(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/professionSubjectGroupRelation/get',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionSubjectGroupRelations: IResourceMethodObservableStrict<null, {subjectId: number, professionId: number}, null, ProfessionRelationData[]> | undefined
    getProfessionSubjectGroupRelations(requestParams: {subjectId: number, professionId: number}): Promise<ProfessionRelationData[]> {
        if (!this._getProfessionSubjectGroupRelations) throw new Error("resource has not been properly initialized")
        return this._getProfessionSubjectGroupRelations(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/professionSubjectGroupRelationByTalentId/get',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionSubjectGroupRelationsByTalentId: IResourceMethodObservableStrict<null, {talentId: number, professionId: number}, null, ProfessionRelationData[]> | undefined
    getProfessionSubjectGroupRelationsByTalentId(requestParams: {talentId: number, professionId: number}): Promise<ProfessionRelationData[]> {
        if (!this._getProfessionSubjectGroupRelationsByTalentId) throw new Error("resource has not been properly initialized")
        return this._getProfessionSubjectGroupRelationsByTalentId(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/subjectGroupToProfessionMatchings/{groupId}/{professionId}/queuedMatches',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSubjectGroupToProfessionMappingQueuedMatches: IResourceMethodObservableStrict<null, null, {groupId: number, professionId: number}, SubjectMappingMatchesData[]> | undefined
    getSubjectGroupToProfessionMappingQueuedMatches(pathVariables: {groupId: number, professionId: number}): Promise<SubjectMappingMatchesData[]> {
        if (!this._getSubjectGroupToProfessionMappingQueuedMatches) throw new Error("resource has not been properly initialized")
        return this._getSubjectGroupToProfessionMappingQueuedMatches(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/subjectToGroupMappings/{subjectId}/{groupId}/queuedMatches',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSubjectToGroupMappingQueuedMatches: IResourceMethodObservableStrict<null, null, {subjectId: number, groupId: number}, SubjectMappingMatchesData> | undefined
    getSubjectToGroupMappingQueuedMatches(pathVariables: {subjectId: number, groupId: number}): Promise<SubjectMappingMatchesData> {
        if (!this._getSubjectToGroupMappingQueuedMatches) throw new Error("resource has not been properly initialized")
        return this._getSubjectToGroupMappingQueuedMatches(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/subjectGroupToProfessionMatchings/{groupId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _isSubjectGroupMappedToProfession: IResourceMethodObservableStrict<null, {professionId: number}, {groupId: number}, JsonWrappedValue<boolean>> | undefined
    isSubjectGroupMappedToProfession(groupId: number, requestParams: {professionId: number}): Promise<JsonWrappedValue<boolean>> {
        if (!this._isSubjectGroupMappedToProfession) throw new Error("resource has not been properly initialized")
        return this._isSubjectGroupMappedToProfession(null, requestParams, {groupId: groupId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/subjectToGroupMappings/{subjectId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _isSubjectMappedToGroup: IResourceMethodObservableStrict<null, {groupId: number}, {subjectId: number}, JsonWrappedValue<boolean>> | undefined
    isSubjectMappedToGroup(subjectId: number, requestParams: {groupId: number}): Promise<JsonWrappedValue<boolean>> {
        if (!this._isSubjectMappedToGroup) throw new Error("resource has not been properly initialized")
        return this._isSubjectMappedToGroup(null, requestParams, {subjectId: subjectId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/matching/blacklist/profession/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeProfessionFromMatching: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    removeProfessionFromMatching(id: number): Promise<null> {
        if (!this._removeProfessionFromMatching) throw new Error("resource has not been properly initialized")
        return this._removeProfessionFromMatching(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/matching/blacklist/group/profession/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeProfessionGroupFromMatching: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    removeProfessionGroupFromMatching(id: number): Promise<null> {
        if (!this._removeProfessionGroupFromMatching) throw new Error("resource has not been properly initialized")
        return this._removeProfessionGroupFromMatching(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/matching/subjectGroupToProfessionMatchings/{groupId}/{professionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeSubjectGroupToProfessionMapping: IResourceMethodObservableStrict<null, null, {groupId: number, professionId: number}, null> | undefined
    removeSubjectGroupToProfessionMapping(pathVariables: {groupId: number, professionId: number}): Promise<null> {
        if (!this._removeSubjectGroupToProfessionMapping) throw new Error("resource has not been properly initialized")
        return this._removeSubjectGroupToProfessionMapping(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/matching/subjectGroupToProfessionMatchings/byProfession/{professionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeSubjectGroupToProfessionMappingsForProfession: IResourceMethodObservableStrict<JsonWrappedValue<number[]>, null, {professionId: number}, null> | undefined
    removeSubjectGroupToProfessionMappingsForProfession(requestBody: JsonWrappedValue<number[]>, professionId: number): Promise<null> {
        if (!this._removeSubjectGroupToProfessionMappingsForProfession) throw new Error("resource has not been properly initialized")
        return this._removeSubjectGroupToProfessionMappingsForProfession(requestBody, null, {professionId: professionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/matching/subjectToGroupMappings/byGroup/{groupid}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeSubjectMappingsForGroup: IResourceMethodObservableStrict<JsonWrappedValue<number[]>, null, {groupid: number}, null> | undefined
    removeSubjectMappingsForGroup(requestBody: JsonWrappedValue<number[]>, groupid: number): Promise<null> {
        if (!this._removeSubjectMappingsForGroup) throw new Error("resource has not been properly initialized")
        return this._removeSubjectMappingsForGroup(requestBody, null, {groupid: groupid}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/matching/subjectToGroupMappings/{subjectId}/{groupId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeSubjectToGroupMapping: IResourceMethodObservableStrict<null, null, {subjectId: number, groupId: number}, null> | undefined
    removeSubjectToGroupMapping(pathVariables: {subjectId: number, groupId: number}): Promise<null> {
        if (!this._removeSubjectToGroupMapping) throw new Error("resource has not been properly initialized")
        return this._removeSubjectToGroupMapping(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/matching/replace/subject',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _replaceSubjectWithAnotherSubject: IResourceMethodObservableStrict<null, {oldSubjectId: number, newSubjectId: number}, null, null> | undefined
    replaceSubjectWithAnotherSubject(requestParams: {oldSubjectId: number, newSubjectId: number}): Promise<null> {
        if (!this._replaceSubjectWithAnotherSubject) throw new Error("resource has not been properly initialized")
        return this._replaceSubjectWithAnotherSubject(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/matching/professionSubjectGroupRelation/matchingType/update',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateProfessionSubjectGroupRelationMatchingType: IResourceMethodObservableStrict<JsonWrappedValue<('Px' | 'P3' | 'P4' | 'P5')>, {subjectGroupId: number, professionId: number}, null, null> | undefined
    updateProfessionSubjectGroupRelationMatchingType(requestBody: JsonWrappedValue<('Px' | 'P3' | 'P4' | 'P5')>, requestParams: {subjectGroupId: number, professionId: number}): Promise<null> {
        if (!this._updateProfessionSubjectGroupRelationMatchingType) throw new Error("resource has not been properly initialized")
        return this._updateProfessionSubjectGroupRelationMatchingType(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/matching/profession/{id}/specialized',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateSpecializedFlagForProfession: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    updateSpecializedFlagForProfession(id: number): Promise<null> {
        if (!this._updateSpecializedFlagForProfession) throw new Error("resource has not been properly initialized")
        return this._updateSpecializedFlagForProfession(null, null, {id: id}).toPromise()
    }

}