import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, ChatMinimalData, ChatData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentChatController
 */

@Injectable()
@ResourceParams({})
export class TalentChatResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/chat-overview/{chatId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getChatForOverview: IResourceMethodObservableStrict<null, null, {chatId: number}, ChatData> | undefined
    getChatForOverview(chatId: number): Promise<ChatData> {
        if (!this._getChatForOverview) throw new Error("resource has not been properly initialized")
        return this._getChatForOverview(null, null, {chatId: chatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/chat-overview',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getChatMinimalDataForOverview: IResourceMethodObservableStrict<null, null, null, ChatMinimalData[]> | undefined
    getChatMinimalDataForOverview(): Promise<ChatMinimalData[]> {
        if (!this._getChatMinimalDataForOverview) throw new Error("resource has not been properly initialized")
        return this._getChatMinimalDataForOverview(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/chat-overview/recruiterPicture/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRecruiterProfilePicture: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getRecruiterProfilePicture(id: number): Promise<Blob> {
        if (!this._getRecruiterProfilePicture) throw new Error("resource has not been properly initialized")
        return this._getRecruiterProfilePicture(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/chat-overview/unseen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUnseenChatCount: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getUnseenChatCount(): Promise<JsonWrappedValue<number>> {
        if (!this._getUnseenChatCount) throw new Error("resource has not been properly initialized")
        return this._getUnseenChatCount(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/chat-overview/{chatId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendChatMessage: IResourceMethodObservableStrict<null, {message: string}, {chatId: number}, null> | undefined
    sendChatMessage(chatId: number, requestParams: {message: string}): Promise<null> {
        if (!this._sendChatMessage) throw new Error("resource has not been properly initialized")
        return this._sendChatMessage(null, requestParams, {chatId: chatId}).toPromise()
    }

}