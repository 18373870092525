import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LandingPagesComponent} from "./landing-pages.component";
import {UtilityModule} from "../../utility/utility.module";
import {PublicOfferPageResource} from "../../../generated/hid/resources";



@NgModule({
  declarations: [
    LandingPagesComponent
  ],
  imports: [
    CommonModule,
    UtilityModule
  ],
  providers: [
    PublicOfferPageResource
  ]
})
export class LandingPagesModule { }
