import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, TalentCoachRatingData, TalentCoachData, ConsultantData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentCoachController
 */

@Injectable()
@ResourceParams({})
export class TalentCoachResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/coach/assess',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _assessTalentCoach: IResourceMethodObservableStrict<TalentCoachRatingData, null, null, null> | undefined
    assessTalentCoach(requestBody: TalentCoachRatingData): Promise<null> {
        if (!this._assessTalentCoach) throw new Error("resource has not been properly initialized")
        return this._assessTalentCoach(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/me/coaching/requestedAppointment',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getHasRequestedAppointmentForCurrentCoaching: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    getHasRequestedAppointmentForCurrentCoaching(): Promise<boolean> {
        if (!this._getHasRequestedAppointmentForCurrentCoaching) throw new Error("resource has not been properly initialized")
        return this._getHasRequestedAppointmentForCurrentCoaching(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/me/coach',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentCoach: IResourceMethodObservableStrict<null, null, null, TalentCoachData> | undefined
    getTalentCoach(): Promise<TalentCoachData> {
        if (!this._getTalentCoach) throw new Error("resource has not been properly initialized")
        return this._getTalentCoach(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/me/coach/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentCoachProfilePicture: IResourceMethodObservableStrict<null, null, null, Blob> | undefined
    getTalentCoachProfilePicture(): Promise<Blob> {
        if (!this._getTalentCoachProfilePicture) throw new Error("resource has not been properly initialized")
        return this._getTalentCoachProfilePicture(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/me/coach',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _requestTalentAgent: IResourceMethodObservableStrict<number[], null, null, ConsultantData> | undefined
    requestTalentAgent(requestBody: number[]): Promise<ConsultantData> {
        if (!this._requestTalentAgent) throw new Error("resource has not been properly initialized")
        return this._requestTalentAgent(requestBody, null, null).toPromise()
    }

}