import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {AdminCompanyInvoiceData, InvoiceFilterData, InvoiceData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminInvoiceController
 */

@Injectable()
@ResourceParams({})
export class AdminInvoiceResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/invoices/createOrUpdate',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateInvoice: IResourceMethodObservableStrict<InvoiceData, null, null, InvoiceData> | undefined
    createOrUpdateInvoice(requestBody: InvoiceData): Promise<InvoiceData> {
        if (!this._createOrUpdateInvoice) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateInvoice(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/invoices/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredInvoices: IResourceMethodObservableStrict<InvoiceFilterData, {pageNum?: number, pageSize?: number}, null, PagedData<InvoiceData>> | undefined
    getFilteredInvoices(requestBody: InvoiceFilterData, requestParams?: {pageNum?: number, pageSize?: number}): Promise<PagedData<InvoiceData>> {
        if (!this._getFilteredInvoices) throw new Error("resource has not been properly initialized")
        return this._getFilteredInvoices(requestBody, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/invoices/company/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredInvoicesPerCompany: IResourceMethodObservableStrict<InvoiceFilterData, null, null, AdminCompanyInvoiceData[]> | undefined
    getFilteredInvoicesPerCompany(requestBody: InvoiceFilterData): Promise<AdminCompanyInvoiceData[]> {
        if (!this._getFilteredInvoicesPerCompany) throw new Error("resource has not been properly initialized")
        return this._getFilteredInvoicesPerCompany(requestBody, null, null).toPromise()
    }

}