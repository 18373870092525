import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import {environment} from "../../../../environments/environment";
import {PublicEmploymentPositionDetailsData} from "../../../../generated/data";
import {PublicEmploymentPositionResource} from "../../../../generated/resources";
import {MediaService} from "../../../utility/services/media.service";
import {MetaService} from "../../../utility/services/meta.service";

@Component({
  selector: 'app-position-details',
  templateUrl: './position-details.component.html',
  styleUrls: ['./position-details.component.scss']
})
export class PositionDetailsComponent implements OnInit {

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    this.scrolled = document.body.scrollTop > (window.innerHeight * 0.5) || document.getElementsByTagName('html')[0].scrollTop > (window.innerHeight * 0.5);
  }

  talentagentApiUrl = environment.apiUrl
  scrolled: boolean = false;
  forceGetSplit: boolean = false;

  loading: boolean = true
  positionId: number
  position: PublicEmploymentPositionDetailsData;

  splitId: number

  constructor(
    private publicEmploymentPositionResource: PublicEmploymentPositionResource,
    private route: ActivatedRoute,
    private router: Router,
    public mediaService: MediaService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private meta: MetaService
  ) {
  }

  ngOnInit(): void {
    this.positionId = parseInt(this.route.snapshot.paramMap.get('positionId'));
    this.splitId = this.route.snapshot.queryParamMap.get('splitId')? parseInt(this.route.snapshot.queryParamMap.get('splitId')) : null;
    this.getPosition()
  }

  videoUrl: SafeResourceUrl = '';
  companyLogoUrl = '';
  positionImageUrl = '';

  getPosition() {
    this.loading = true
    this.publicEmploymentPositionResource.getSingleEmploymentPositionById(this.positionId, {splitId: this.splitId}).then((result: any) => {
      this.position = result
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.content.videoEmbedLink)
      this.companyLogoUrl = result.companyLogoUrl != null ? result.companyLogoUrl : environment.apiUrl + '/public/positions/' + this.positionId + '/companyLogo';
      if (this.position.googleJobPosting) {
        this.createGoogleCrawlScriptIfNecessary()
        this.meta.setRobotsTag(true)
      } else {
        this.meta.setRobotsTag(false)
      }
      this.loading = false
    })
  }

  getPositionDescriptionHtmlForGoogleCrawler(): string {
    return document.getElementsByTagName('app-position-content')[0]?.innerHTML;
  }

  getPositionLocationsForGoogleCrawler():string {
    let locationsString = ''
    this.position.locations.forEach((loc: any, index: any) => {
      locationsString += `{
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "${loc.name}"
          ${loc.zipCode? ",\"postalCode\": \"" + loc.zipCode + "\"": ""}
          ${loc.zipCode? ",\"addressCountry\": \"DE\"" : ""}
          ${loc.name == 'Luxembourg'? ",\"addressCountry\": \"LU\"" : ""}
          ${loc.name == 'Tegelen'? ",\"addressCountry\": \"NL\"" : ""}
        }
      }`;
      if (index+1 < this.position.locations.length) locationsString += ', '
      }
    )
    return locationsString
  }

  googleScriptCreated: boolean = false

  createGoogleCrawlScriptIfNecessary() {
    if (!this.position?.showCompany || this.googleScriptCreated) return
    this.googleScriptCreated = true
    let description = (this.position?.content?.positionDescription as unknown as HTMLElement).innerHTML

    let script = document.createElement('script');

    script.setAttribute('type', 'application/ld+json');
    script.text = `
    {
    "@context" : "https://schema.org/",
    "@type": "JobPosting",
    "workHours": "${this.position.weeklyHoursTo}h pro Woche",
        "title": "${this.position.title}",
    "datePosted": "${this.position.creationDate}",
    "jobStartDate": "${this.position.startingDate}",
    "jobImmediateStart": ${(new Date()) >= this.position.startingDate},
    "experienceRequirements": "${this.position?.content?.positionRequirements?.toString()}",
    "employerOverview": "${this.position?.content?.companyDescription?.replace(/<[^>]*>/g, "")}",
    "directApply": true,
    "description": "${this.position?.content?.positionDescription?.replace(/<[^>]*>/g, "")}",
    "url": "${window.location.href.split('?', 1)[0]}",
    "hiringOrganization": {
      "@type": "Organization",
      "name": "${this.position.hiringCompanyName}",
      "sameAs": "${this.position.hiringCompanyWebsite}",
      "logo": "${this.companyLogoUrl}"
    },
    "jobLocation": [${this.getPositionLocationsForGoogleCrawler()}],
    "jobBenefits": "${this.position.content.benefits.map((ben: any) => ben.name).toString()}",
    "employmentType": "${this.getEmploymentType()}"
  }
    `
    document.head.appendChild(script);
  }


  routeToApplicationOnboarding() {
    window.open(environment.talentAgentFrontendUrl + `/onboarding/application?positionId=${this.positionId}?utm_medium=talent_cv_reminder&amp;utm_campaign=tp_upsell&amp;utm_content=originid-2146&amp;utm_source=tp-email`, "_blank");
  }

  routeToOnboarding() {
    window.open(environment.talentAgentFrontendUrl + `/onboarding`, "_blank");
  }

  getEmploymentType(): string {
    switch (this.position.employmentCategory) {
      case "DualStudies": return 'OTHER';
      case "PermanentEmployment": return 'FULL_TIME';
      case "MandatoryInternship": return 'INTERN';
      case "VoluntaryInternship": return 'INTERN';
      case "Trainee": return 'PART_TIME';
      case "Thesis": return 'OTHER';
      case "TempoaryJob": return 'OTHER';
      case "ProjectWork": return 'OTHER';
      case "WorkingStudent": return 'OTHER';
      case "VocationalTraining": return 'OTHER';
      default: return 'OTHER'
    }
  }

  getStartingDate(): string | null {
    if (!this.position.startingDate) {
      return null;
    }

    let date = new Date(this.position.startingDate);
    let now = new Date();
    let pipe = new DatePipe('de-DE');
    return date <= now ? 'ab sofort' : pipe.transform(date);
  }

  getThirdPartyPositionTooltip() {
    return `Vermittlung über unseren Partner ${this.position.company.name}.
    Bei Unternehmen, die anonym über einen unserer Partner nach Bewerbern suchen, erfährst du nach deiner Bewerbung, um welches Unternehmen es sich handelt.`
  }

  navigateToCompanyWebsite() {
    window.open(this.position.company.website, '_blank');
  }
}
