import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.StudentSessionController
 */

@Injectable()
@ResourceParams({})
export class StudentSessionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/handshake',
        endpoint: 'net.innospire.hidcms'
    })
    private _performSessionHandshake: IResourceMethodObservableStrict<null, null, null, null> | undefined
    performSessionHandshake(): Promise<null> {
        if (!this._performSessionHandshake) throw new Error("resource has not been properly initialized")
        return this._performSessionHandshake(null, null, null).toPromise()
    }

}