<div class="content-container wrapper flex row flex-space-between content-padding" *ngIf="!loading">
  <div id="page-content" class="width-100" *ngIf="position">
    <div
      class="flex column start">
  <app-position-content class="width-100" [position]="position"></app-position-content>
    </div>
  </div>

  <div style="width: 250px; margin-left: 40px" *ngIf="!mediaService.getMaxWidthQuery(870).matches"><div style="width:250px"></div></div>
  <div id="side-bar"
       class="flex column center"
       *ngIf="!mediaService.getMaxWidthQuery(870).matches">
    <div class="side-bar-content flex column center">
    <div class="company-picture">
      <div class="flex column center">
        <img *ngIf="position.showCompany" class="company-picture" [src]="companyLogoUrl">
        <img *ngIf="!position.showCompany" class="company-picture" src="./assets/images/TA_KeinLogo3.2.jpg">
      </div>
    </div>

    <div class="position-header flex column start-center">
      <p *ngIf="position.hiringCompanyWebsite != null && position.hiringCompanyWebsite != ''"
         (click)="navigateToCompanyWebsite()" class="company-name">{{position.hiringCompanyName}}
        <span *ngIf="position.isThirdPartyPosition"
              style="color: #42a5f5;"><i class="mdi mdi-information-outline"></i></span>
      </p>
      <p *ngIf="position.hiringCompanyWebsite == null || position.hiringCompanyWebsite == ''"
         class="company-name">{{position.hiringCompanyName}}
        <span *ngIf="position.isThirdPartyPosition"
              style="color: #42a5f5;"><i class="mdi mdi-information-outline"></i></span>
      </p>
    </div>

    <div id="actions" class="flex column center button-bar-wrapper width-100">
      <button
                      style="width: 100%"
                      class="margin-sm accent-button hid-button primary ita_nav_job-offer ita_initiate-request-grant ita_initiate-request"
                      (click)="routeToApplicationOnboarding()">
        Jetzt bewerben
      </button>

      <button
        style="width: 100%"
        class="margin-sm hid-button dark-stroked-button secondary inverse ita_nav_job-offer ita_initiate-request-grant ita_initiate-request"
        (click)="routeToOnboarding()">
        Mehr Stellen finden
      </button>


    </div>
  </div>
  </div>
  <div
    id="button-bar-mobile-downside"
       class="flex row center button-bar-wrapper downside w-100 visable"
       [ngClass]="{'downside-ios': mediaService.isIOs()}"
       *ngIf="mediaService.getMaxWidthQuery(870).matches">
    <button
            class="accent-button hid-button primary ita_nav_job-offer ita_initiate-application"
            (click)="routeToApplicationOnboarding()">
      Jetzt bewerben
    </button>
  </div>
</div>
