import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http: HttpClient
  ) { }

  getCareerGuideCardBanner(routingUrl: string): Observable<any>  {
    return this.http.get(environment.apiUrlHidCms + "/public/careerArticle/" + routingUrl + "/cardBanner", {
      headers: new HttpHeaders(),
      responseType: 'blob'
    })
  }

  getCareerGuideBanner(routingUrl: string): Observable<any> {
    return this.http.get(environment.apiUrlHidCms + "/public/careerArticle/"+ routingUrl + "/banner", {
      headers: new HttpHeaders(),
      responseType: 'blob'
    })
  }

  getWebinarImage(id: number): Observable<any> {
    return this.http.get(environment.apiUrl + "/public/webinar/" + id + "/image", {
      headers: new HttpHeaders(),
      responseType: "blob"
    })
  }
}
