<div id="dialog-wrapper">
  <button class="clean-icon-button" (click)="close()"><i class="mdi mdi-close"></i></button>

  <div class="flex column gap">
    <a class="whatsapp-button flex row start-center" href="https://wa.me/?text={{data.shareText}}{{encodedShareLink}}" target="_blank" data-action="share/whatsapp/share">
      <i class="icon whatsapp"></i>WhatsApp
    </a>

    <a class="telegram-button flex row start-center" href="https://telegram.me/share/url?url= {{data.shareLink}}&text={{encodedShareLink}}" target="_blank" >
      <i class="icon telegram"></i>Telegram
    </a>

    <a class="email-button flex row start-center" href="mailto:?subject=Cooles Angebot für dich?&body={{data.shareText}} {{data.shareLink}}">
      <i class="icon mdi mdi-email"></i>E-Mail
    </a>

    <a class="link-button flex row start-center" (click)="copyToClipboard()">
      <i class="icon mdi mdi-link-variant"></i>Link kopieren
    </a>
  </div>

</div>
