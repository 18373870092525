import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, NotificationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.NotificationController
 */

@Injectable()
@ResourceParams({})
export class NotificationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/user/notifications/unseen/count',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCountOfUnseenNotificationsForUser: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getCountOfUnseenNotificationsForUser(): Promise<number> {
        if (!this._getCountOfUnseenNotificationsForUser) throw new Error("resource has not been properly initialized")
        return this._getCountOfUnseenNotificationsForUser(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/user/notifications/unseen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUnseenNotificationsForUser: IResourceMethodObservableStrict<null, null, null, NotificationData[]> | undefined
    getUnseenNotificationsForUser(): Promise<NotificationData[]> {
        if (!this._getUnseenNotificationsForUser) throw new Error("resource has not been properly initialized")
        return this._getUnseenNotificationsForUser(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/user/notifications/seen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _setUserNotificationsToSeen: IResourceMethodObservableStrict<null, null, null, null> | undefined
    setUserNotificationsToSeen(): Promise<null> {
        if (!this._setUserNotificationsToSeen) throw new Error("resource has not been properly initialized")
        return this._setUserNotificationsToSeen(null, null, null).toPromise()
    }

}