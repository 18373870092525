<div class="content-padding flex column center gap-xl">

  <section class="flex column center">
    <h1 class="page-header center-text">Alle <br *ngIf="mediaService.isMobile()">
      <span class="highlight highlight-show">Karriereguides</span>
    </h1>
  </section>

  <section class="flex center row-wrap row-wrap-gap-lg content-width width-100">
    <div class="flex row-wrap center width-100" *ngIf="loading">
        <app-basic-loading-skeleton class="skeleton" *ngFor="let skeleton of skeletonList"></app-basic-loading-skeleton>
    </div>

    <div *ngIf="!loading" class="flex center row-wrap">
      <div *ngFor="let guide of careerGuides" [ngClass]="{'width-100': mediaService.isMobile()}">
        <app-career-guide-card [careerGuide]="guide" [size]="'Medium'"></app-career-guide-card>
      </div>
    </div>

  </section>

  <div class="flex center gap width-66 content-width" [ngClass]="{'column': mediaService.isMobile(), 'row': !mediaService.isMobile()}">
    <div class="flex row center gap">
      <button class="light-icon-button" (click)="previousPage()" [disabled]="loading">
        <i class="mdi mdi-arrow-left"></i>
      </button>
      <button class="dark-icon-button" (click)="nextPage()" [disabled]="loading">
        <i class="mdi mdi-arrow-right"></i>
      </button>
    </div>

    <div *ngIf="mediaService.isMobile()">
      {{this.page + 1}} / {{this.totalPages}}
    </div>


    <mat-progress-bar [value]="getProgressBarValue()" [color]="'accent'" *ngIf="!mediaService.isMobile()">
    </mat-progress-bar>
  </div>
</div>
