import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {CoachingTypeData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.public.PublicCoachingTypeController
 */

@Injectable()
@ResourceParams({})
export class PublicCoachingTypeResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/coachings',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllCoachingTypes: IResourceMethodObservableStrict<null, null, null, CoachingTypeData[]> | undefined
    getAllCoachingTypes(): Promise<CoachingTypeData[]> {
        if (!this._getAllCoachingTypes) throw new Error("resource has not been properly initialized")
        return this._getAllCoachingTypes(null, null, null).toPromise()
    }

}