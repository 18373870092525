import {Component} from '@angular/core';

@Component({
  selector: 'app-case-studies-page',
  templateUrl: './case-studies-page.component.html',
  styleUrl: './case-studies-page.component.scss'
})
export class CaseStudiesPageComponent {

}
