<!--TODO THIS NEEDS TO BE REWORKED ONCE POSITIONS ARE UPDATED WITH THE NEW B2C SEARCH UPDATE - BECAUSE THIS IS PRETTY SHITTY -->

<div class="description-position-details flex column start content-padding">
  <div class="position-desciption" *ngIf="position">

    <h1 class="title" *ngIf="!mediaService.getMaxWidthQuery(870).matches">{{position?.content?.title}}</h1>

    <div *ngIf="mediaService.getMaxWidthQuery(870).matches" id="mobile-header">
      <h1 class="title">{{position?.content?.title}}</h1>

      <div>
        <p *ngIf="position.hiringCompanyWebsite != null && position.hiringCompanyWebsite != ''"
           (click)="navigateToCompanyWebsite()" class="company-name">{{position.hiringCompanyName}}
          <span *ngIf="position.isThirdPartyPosition"
                style="color: #42a5f5;"><i class="mdi mdi-information-outline"></i></span>
        </p>
        <p *ngIf="position.hiringCompanyWebsite == null || position.hiringCompanyWebsite == ''"
           class="company-name">{{position.hiringCompanyName}}
          <span *ngIf="position.isThirdPartyPosition"

                style="color: #42a5f5;"><i class="mdi mdi-information-outline"></i></span>
        </p>
      </div>
    </div>

    <div
         class="flex row-wrap center usps m-top20" *ngIf="position?.content?.keywords?.length > 0">
      <div *ngFor="let usp of position?.content?.keywords">
        <div
          class="usp flex row start-center" >
          <i class="fas fa-check fa margin-right" style="color: #ffffff;"></i>
          <div class="m-left10">{{usp}}</div>
        </div>
      </div>
    </div>

    <div class="section" *ngIf="position?.content?.companyDescription && position?.content?.companyDescription.length > 0">
      <div class="section-title">Über Uns</div>
      <div class="description-position-details" [innerHTML]="position?.content?.companyDescription | safeHtml"></div>

      <img *ngFor="let image of positionImageUrls" [src]="image" class="width-100">

      <div *ngIf="position?.content?.videoEmbedLink " class="position-video">
        <iframe style="max-width: 100%; width: 100%;" height="100%" [src]="videoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>

    <div class="section">
      <div class="section-title">Über die Stelle</div>
      <div
           class="margin-vertical flex row center">
        <div class="flex column start-center margin-horizontal">
          <i class="mdi mdi-clock info-icon"></i>
          <div class="m-top10"><b>{{getWeeklyHoursString()}}</b></div>
        </div>

        <div
          class="flex column start-center margin-horizontal">
          <i class="mdi mdi-map-marker info-icon"></i>
          <div class="m-top10"><b>{{position.locationShortDescription}}</b></div>
        </div>

        <div
          class="flex column start-center margin-horizontal">
          <i class="mdi mdi-briefcase info-icon"></i>
          <div class="m-top10"><b>{{position.employmentCategory | workType}}</b></div>
        </div>
      </div>
      <div class="description-position-details" [innerHtml]="position?.content?.positionDescription | safeHtml"></div>
    </div>

    <div class="section" *ngIf="position?.content?.benefits.length > 0">
      <div class="section-title">Benefits</div>
      <div
        class="flex column start">
        <div
          class="flex row start-center margin-bottom"
          *ngFor="let benefit of position?.content?.benefits">
          <i style="color: #4a4a4a; font-size: 22px; margin-right: 1rem;" [class]="'mdi mdi-' + benefit.icon"></i>
          <div class="m-left10">{{benefit.name}}</div>
        </div>
      </div>

    </div>

    <div class="section" *ngIf="position?.content?.positionRequirements?.length > 0">
      <div class="section-title">Anforderungen</div>
      <div class="bullets">
        <div *ngFor="let bullet of position?.content?.positionRequirements"
            class="flex row start-center margin-bottom">
          <i class="fas fa-check fa" style="color: #00A0FF;"></i>
          <div class="bullet-text">{{bullet}}</div>
        </div>
      </div>
    </div>

      <div class="section" *ngIf="position?.content?.miscellaneousDescription && position?.content?.miscellaneousDescription != ''">
        <div class="section-title">Was wir dir noch sagen wollen</div>
        <div class="description-position-details" [innerHTML]="position?.content?.miscellaneousDescription | safeHtml"></div>
      </div>


    </div>
  </div>

