import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnimationsService {

  constructor() { }

  handleOnScrollAnimation(queryClass: string, animationClass: string, reverseAnimation = false){

    const relevantElements = document.querySelectorAll(queryClass)
    const observer = new IntersectionObserver((entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(animationClass)
        } else{
          if(reverseAnimation) entry.target.classList.remove(animationClass)
        }
      })
    })

    relevantElements.forEach((element: any) => {
      observer.observe(element)
    })
  }
}
