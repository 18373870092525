import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {TalentLocationPreferenceData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.TalentLocationPreferencesController
 */

@Injectable()
@ResourceParams({})
export class TalentLocationPreferencesResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/preferences/location',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentLocationPreferences: IResourceMethodObservableStrict<null, null, null, TalentLocationPreferenceData> | undefined
    getTalentLocationPreferences(): Promise<TalentLocationPreferenceData> {
        if (!this._getTalentLocationPreferences) throw new Error("resource has not been properly initialized")
        return this._getTalentLocationPreferences(null, null, null).toPromise()
    }

}