<div class="flex column center gap-xl content-padding">
  <div class="content-width card-shadow">
    <h1>Impressum</h1>



    <p><i>Talentagent ist ein Angebot der</i> <br><br> Uniwunder GmbH <br>
      Bergstraße 29<br>
      01069&nbsp;Dresden</p>
    <p>
      <span class="address-format">Tel.:    </span>
      <a href="tel:+4935179903384 ">+49 (0) 351 799 033 84</a> <br>
      <span class="address-format">Mail:  </span>
      <a href="mailto:info@uniwunder.com">
        info&#64;uniwunder.com
      </a>
    </p>
    <p>Geschäftsführer: Matthias Auer</p>
    <p>Registernummer: HRB 35586, Amtsgericht Dresden</p>
    <p>USt-IdNr. gem. § 27a UStG: DE307260681</p>


    <h3>Inhaltlich verantwortlich i.S.v. § 18 Abs. 2 MStV   </h3>
    <p>Matthias Auer, c/o Uniwunder GmbH, Bergstraße 29, 01069 Dresden</p>

    <h3>Alternative Streitbeilegung</h3>
    <p>
      Die EU-Kommission stellt eine Plattform zur Online-Streitbeilegung (OS-Plattform) bereit. Diese ist unter dem folgenden Link erreichbar:
      <a href="http://ec.europa.eu/consumers/odr" target="_blank">http://ec.europa.eu/consumers/odr</a> erreichen</p>
    <p>
      Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet.</p>



  </div>
</div>
