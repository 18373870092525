import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {ConsultantControllingData, JsonWrappedValue, ConsultantRatingData, ConsultantData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminConsultantController
 */

@Injectable()
@ResourceParams({})
export class AdminConsultantResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultants/active',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getActiveConsultants: IResourceMethodObservableStrict<null, {page?: number, q?: string, pageSize?: number}, null, PagedData<ConsultantControllingData>> | undefined
    getActiveConsultants(requestParams?: {page?: number, q?: string, pageSize?: number}): Promise<PagedData<ConsultantControllingData>> {
        if (!this._getActiveConsultants) throw new Error("resource has not been properly initialized")
        return this._getActiveConsultants(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultants/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getConsultant: IResourceMethodObservableStrict<null, null, {id: number}, ConsultantData> | undefined
    getConsultant(id: number): Promise<ConsultantData> {
        if (!this._getConsultant) throw new Error("resource has not been properly initialized")
        return this._getConsultant(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultants/{id}/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getConsultantProfilePicture: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getConsultantProfilePicture(id: number): Promise<Blob> {
        if (!this._getConsultantProfilePicture) throw new Error("resource has not been properly initialized")
        return this._getConsultantProfilePicture(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultants/{id}/rating',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getConsultantRating: IResourceMethodObservableStrict<null, {page?: number, pageSize?: number}, {id: number}, PagedData<ConsultantRatingData>> | undefined
    getConsultantRating(id: number, requestParams?: {page?: number, pageSize?: number}): Promise<PagedData<ConsultantRatingData>> {
        if (!this._getConsultantRating) throw new Error("resource has not been properly initialized")
        return this._getConsultantRating(null, requestParams || {}, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultants/deleted',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getDeletedConsultants: IResourceMethodObservableStrict<null, null, null, ConsultantData[]> | undefined
    getDeletedConsultants(): Promise<ConsultantData[]> {
        if (!this._getDeletedConsultants) throw new Error("resource has not been properly initialized")
        return this._getDeletedConsultants(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultants/filtered/active',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredActiveConsultants: IResourceMethodObservableStrict<null, {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<ConsultantData>> | undefined
    getFilteredActiveConsultants(requestParams?: {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<ConsultantData>> {
        if (!this._getFilteredActiveConsultants) throw new Error("resource has not been properly initialized")
        return this._getFilteredActiveConsultants(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultants/login',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _loginAsConsultant: IResourceMethodObservableStrict<null, {email: string}, null, JsonWrappedValue<string>> | undefined
    loginAsConsultant(requestParams: {email: string}): Promise<string> {
        if (!this._loginAsConsultant) throw new Error("resource has not been properly initialized")
        return this._loginAsConsultant(null, requestParams, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/consultants/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateConsultant: IResourceMethodObservableStrict<ConsultantData, null, {id: number}, ConsultantData> | undefined
    updateConsultant(requestBody: ConsultantData, id: number): Promise<ConsultantData> {
        if (!this._updateConsultant) throw new Error("resource has not been properly initialized")
        return this._updateConsultant(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultants/{id}/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateConsultantProfilePicture: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, {id: number}, null> | undefined
    updateConsultantProfilePicture(requestBody: ArrayBuffer, id: number, requestParams: {fileName: string}): Promise<null> {
        if (!this._updateConsultantProfilePicture) throw new Error("resource has not been properly initialized")
        return this._updateConsultantProfilePicture(requestBody, requestParams, {id: id}).toPromise()
    }

}