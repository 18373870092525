import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {EmploymentPositionLandingPageData, JsonWrappedValue, TalentPositionDetailsData, TalentPositionPreviewData, PublicEmploymentPositionDetailsData, CityData, PositionChipData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.public.PublicEmploymentPositionController
 */

@Injectable()
@ResourceParams({})
export class PublicEmploymentPositionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/positions/allForIndex',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllEmploymentPositionsForIndexing: IResourceMethodObservableStrict<null, null, null, EmploymentPositionLandingPageData[]> | undefined
    getAllEmploymentPositionsForIndexing(): Promise<EmploymentPositionLandingPageData[]> {
        if (!this._getAllEmploymentPositionsForIndexing) throw new Error("resource has not been properly initialized")
        return this._getAllEmploymentPositionsForIndexing(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/company/{id}/logo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyLogoByCompany: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getCompanyLogoByCompany(id: number): Promise<Blob> {
        if (!this._getCompanyLogoByCompany) throw new Error("resource has not been properly initialized")
        return this._getCompanyLogoByCompany(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/positions/{id}/logo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyLogoByPositionId: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getCompanyLogoByPositionId(id: number): Promise<Blob> {
        if (!this._getCompanyLogoByPositionId) throw new Error("resource has not been properly initialized")
        return this._getCompanyLogoByPositionId(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/positions/{employmentPositionId}/companyLogo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyLogoForEmploymentPosition: IResourceMethodObservableStrict<null, null, {employmentPositionId: number}, Blob> | undefined
    getCompanyLogoForEmploymentPosition(employmentPositionId: number): Promise<Blob> {
        if (!this._getCompanyLogoForEmploymentPosition) throw new Error("resource has not been properly initialized")
        return this._getCompanyLogoForEmploymentPosition(null, null, {employmentPositionId: employmentPositionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/positions/{employmentPositionId}/positionDetails',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionById: IResourceMethodObservableStrict<null, {origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive'), splitId: number}, {employmentPositionId: number}, TalentPositionDetailsData> | undefined
    getEmploymentPositionById(employmentPositionId: number, requestParams: {origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive'), splitId: number}): Promise<TalentPositionDetailsData> {
        if (!this._getEmploymentPositionById) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionById(null, requestParams, {employmentPositionId: employmentPositionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/positions/{employmentPositionId}/chips',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionChipsById: IResourceMethodObservableStrict<null, null, {employmentPositionId: number}, PositionChipData[]> | undefined
    getEmploymentPositionChipsById(employmentPositionId: number): Promise<PositionChipData[]> {
        if (!this._getEmploymentPositionChipsById) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionChipsById(null, null, {employmentPositionId: employmentPositionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/positions/{employmentPositionId}/usps',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionUspsById: IResourceMethodObservableStrict<null, {splitId?: number}, {employmentPositionId: number}, PositionChipData[]> | undefined
    getEmploymentPositionUspsById(employmentPositionId: number, requestParams?: {splitId?: number}): Promise<PositionChipData[]> {
        if (!this._getEmploymentPositionUspsById) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionUspsById(null, requestParams || {}, {employmentPositionId: employmentPositionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/positions/{employmentPositionId}/locations',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLocationsForPositionById: IResourceMethodObservableStrict<null, null, {employmentPositionId: number}, CityData[]> | undefined
    getLocationsForPositionById(employmentPositionId: number): Promise<CityData[]> {
        if (!this._getLocationsForPositionById) throw new Error("resource has not been properly initialized")
        return this._getLocationsForPositionById(null, null, {employmentPositionId: employmentPositionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/positions/{id}/personalizedLocation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPersonalizedLocationString: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<string>> | undefined
    getPersonalizedLocationString(id: number): Promise<string> {
        if (!this._getPersonalizedLocationString) throw new Error("resource has not been properly initialized")
        return this._getPersonalizedLocationString(null, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/contentSplits/{id}/picture/{pictureId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionSplitPicture: IResourceMethodObservableStrict<null, null, {id: number, pictureId: number}, Blob> | undefined
    getPositionSplitPicture(pathVariables: {id: number, pictureId: number}): Promise<Blob> {
        if (!this._getPositionSplitPicture) throw new Error("resource has not been properly initialized")
        return this._getPositionSplitPicture(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/positions/{employmentPositionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleEmploymentPositionById: IResourceMethodObservableStrict<null, {splitId: number}, {employmentPositionId: number}, PublicEmploymentPositionDetailsData> | undefined
    getSingleEmploymentPositionById(employmentPositionId: number, requestParams: {splitId: number}): Promise<PublicEmploymentPositionDetailsData> {
        if (!this._getSingleEmploymentPositionById) throw new Error("resource has not been properly initialized")
        return this._getSingleEmploymentPositionById(null, requestParams, {employmentPositionId: employmentPositionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/positions/{employmentPositionId}/preview',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleEmploymentPositionPreviewById: IResourceMethodObservableStrict<null, {splitId: number}, {employmentPositionId: number}, TalentPositionPreviewData> | undefined
    getSingleEmploymentPositionPreviewById(employmentPositionId: number, requestParams: {splitId: number}): Promise<TalentPositionPreviewData> {
        if (!this._getSingleEmploymentPositionPreviewById) throw new Error("resource has not been properly initialized")
        return this._getSingleEmploymentPositionPreviewById(null, requestParams, {employmentPositionId: employmentPositionId}).toPromise()
    }

}