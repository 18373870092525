import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  navBarStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor() { }

  setNavBarStatus(status: boolean) {
    this.navBarStatus.next(status);
  }
}
