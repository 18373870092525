import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GDPRConsentService} from "./gdprconsent.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CookieService} from "./cookie.service";
import {GDPRConsentDialogService} from "./gdprconsent-dialog.service";
import {GDPRConsentComponent} from "../../components/gdprconsent/gdprconsent.component";
import {LocalStorageService} from "../local-storage.service";
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
  declarations: [GDPRConsentComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  providers: [
    CookieService,
    GDPRConsentService,
    {
      provide: APP_INITIALIZER, useFactory: (gs: GDPRConsentService) => function () {
        return gs.init();
      },
      deps: [GDPRConsentService],
      multi: true
    },
    GDPRConsentDialogService,
    LocalStorageService
  ]
})
export class GdprConsentModule {
}
