import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {ProfessionFieldPreferenceData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.ProfessionFieldPreferenceController
 */

@Injectable()
@ResourceParams({})
export class ProfessionFieldPreferenceResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/interesting/professionFieldPreferences',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getInterestingProfessionFieldPreferencesForTalent: IResourceMethodObservableStrict<null, null, null, LabelData[]> | undefined
    getInterestingProfessionFieldPreferencesForTalent(): Promise<LabelData[]> {
        if (!this._getInterestingProfessionFieldPreferencesForTalent) throw new Error("resource has not been properly initialized")
        return this._getInterestingProfessionFieldPreferencesForTalent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/{id}/professionFieldPreferences',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionFieldPreferencesForTalentAsAdmin: IResourceMethodObservableStrict<null, null, {id: number}, ProfessionFieldPreferenceData[]> | undefined
    getProfessionFieldPreferencesForTalentAsAdmin(id: number): Promise<ProfessionFieldPreferenceData[]> {
        if (!this._getProfessionFieldPreferencesForTalentAsAdmin) throw new Error("resource has not been properly initialized")
        return this._getProfessionFieldPreferencesForTalentAsAdmin(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/professionFieldPreferences',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRelevantProfessionFieldPreferencesForTalent: IResourceMethodObservableStrict<null, null, null, ProfessionFieldPreferenceData[]> | undefined
    getRelevantProfessionFieldPreferencesForTalent(): Promise<ProfessionFieldPreferenceData[]> {
        if (!this._getRelevantProfessionFieldPreferencesForTalent) throw new Error("resource has not been properly initialized")
        return this._getRelevantProfessionFieldPreferencesForTalent(null, null, null).toPromise()
    }

}