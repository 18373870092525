import {Component, Input, OnInit} from '@angular/core';
import {CareerArticleData} from "../../../../generated/hid/data";
import {FileService} from "../../../utility/services/file.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-career-guide-card',
  templateUrl: './career-guide-card.component.html',
  styleUrl: './career-guide-card.component.scss'
})
export class CareerGuideCardComponent implements OnInit{

  bannerCardImage: any = null

  @Input() careerGuide: CareerArticleData = <CareerArticleData>{}
  @Input() size: "Small" | "Medium" | "Large" = "Medium"

  constructor(
    private fileService: FileService
  ) {

  }

  ngOnInit() {
    let sub = this.fileService.getCareerGuideCardBanner(this.careerGuide.routingUrl).subscribe(next => {
      this.bannerCardImage = URL.createObjectURL(next)
      sub.unsubscribe()
    })
  }

}
