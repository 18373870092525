import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentMatchingStateController
 */

@Injectable()
@ResourceParams({})
export class TalentMatchingStateResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/matching/state',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMatchingState: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<('Matchable' | 'MatchingPaused' | 'Unmatchable' | 'MatchingDenied')>> | undefined
    getMatchingState(): Promise<JsonWrappedValue<('Matchable' | 'MatchingPaused' | 'Unmatchable' | 'MatchingDenied')>> {
        if (!this._getMatchingState) throw new Error("resource has not been properly initialized")
        return this._getMatchingState(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/matching/state',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reactivateMatching: IResourceMethodObservableStrict<null, null, null, null> | undefined
    reactivateMatching(): Promise<null> {
        if (!this._reactivateMatching) throw new Error("resource has not been properly initialized")
        return this._reactivateMatching(null, null, null).toPromise()
    }

}