<div class="flex column gap card-flat catcher" [ngClass]="{'cap-catcher': !mediaService.isTablet()}">
  <div class="inner-content" [innerHTML]="content | safeHtml"></div>

  <div class="flex column gap">
    <button class="primary-button" (click)="routeToTalentAgent()">
      <span [innerHTML]="buttonText | safeHtml"></span>
    </button>

    <button class="dark-stroked-button flex row center gap-sm" (click)="openShareDialog()">
      <span>Artikel teilen</span>
      <i class="mdi mdi-share"></i>
    </button>
  </div>

</div>
