import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {AdminTalentFeedPositionRelationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminTalentPositionRelationController
 */

@Injectable()
@ResourceParams({})
export class AdminTalentPositionRelationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/proposals/{talentId}/{positionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionProposal: IResourceMethodObservableStrict<null, null, {talentId: number, positionId: number}, AdminTalentFeedPositionRelationData> | undefined
    getPositionProposal(pathVariables: {talentId: number, positionId: number}): Promise<AdminTalentFeedPositionRelationData> {
        if (!this._getPositionProposal) throw new Error("resource has not been properly initialized")
        return this._getPositionProposal(null, null, pathVariables).toPromise()
    }

}