import { Component } from '@angular/core';

@Component({
  selector: 'app-competition-comparison',
  templateUrl: './competition-comparison.component.html',
  styleUrl: './competition-comparison.component.scss'
})
export class CompetitionComparisonComponent {

}
