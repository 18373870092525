import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.TalentImageController
 */

@Injectable()
@ResourceParams({})
export class TalentImageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talents/admins/{adminId}/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAdminPicture: IResourceMethodObservableStrict<null, null, {adminId: number}, Blob> | undefined
    getAdminPicture(adminId: number): Promise<Blob> {
        if (!this._getAdminPicture) throw new Error("resource has not been properly initialized")
        return this._getAdminPicture(null, null, {adminId: adminId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talents/{id}/profilePic',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentProfilePic: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getTalentProfilePic(id: number): Promise<Blob> {
        if (!this._getTalentProfilePic) throw new Error("resource has not been properly initialized")
        return this._getTalentProfilePic(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/{id}/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _uploadProfileImage: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, {id: number}, null> | undefined
    uploadProfileImage(requestBody: ArrayBuffer, id: number, requestParams: {fileName: string}): Promise<null> {
        if (!this._uploadProfileImage) throw new Error("resource has not been properly initialized")
        return this._uploadProfileImage(requestBody, requestParams, {id: id}).toPromise()
    }

}