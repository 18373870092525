import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, ApplicantPersonaData, CompanyLatestEmploymentPositionData, PositionContentSplitData, RecruiterPositionData, CompanyPositionFilterData, CompanyEmploymentPositionDisplayData, CompanyEmploymentPositionData, AssignedRecruiterData, PagedData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyEmploymentPositionController
 */

@Injectable()
@ResourceParams({})
export class CompanyEmploymentPositionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/contentSplits/{id}/picture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addPositionSplitPicture: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string, description?: string}, {id: number}, LabelData> | undefined
    addPositionSplitPicture(requestBody: ArrayBuffer, id: number, requestParams: {fileName: string, description?: string}): Promise<LabelData> {
        if (!this._addPositionSplitPicture) throw new Error("resource has not been properly initialized")
        return this._addPositionSplitPicture(requestBody, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/positions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createEmploymentPosition: IResourceMethodObservableStrict<CompanyEmploymentPositionData, null, null, JsonWrappedValue<number>> | undefined
    createEmploymentPosition(requestBody: CompanyEmploymentPositionData): Promise<JsonWrappedValue<number>> {
        if (!this._createEmploymentPosition) throw new Error("resource has not been properly initialized")
        return this._createEmploymentPosition(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/positions/applicantPersona/createOrUpdate',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateApplicantPersona: IResourceMethodObservableStrict<ApplicantPersonaData, null, null, null> | undefined
    createOrUpdateApplicantPersona(requestBody: ApplicantPersonaData): Promise<null> {
        if (!this._createOrUpdateApplicantPersona) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateApplicantPersona(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/positions/{id}/matchingState',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deactivatePosition: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    deactivatePosition(id: number): Promise<null> {
        if (!this._deactivatePosition) throw new Error("resource has not been properly initialized")
        return this._deactivatePosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/contentSplits/{id}/pictures/{fileId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deletePositionSplitPicture: IResourceMethodObservableStrict<null, null, {id: number, fileId: number}, null> | undefined
    deletePositionSplitPicture(pathVariables: {id: number, fileId: number}): Promise<null> {
        if (!this._deletePositionSplitPicture) throw new Error("resource has not been properly initialized")
        return this._deletePositionSplitPicture(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/positions/all',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllEmploymentPositionsForCompany: IResourceMethodObservableStrict<null, null, null, CompanyEmploymentPositionDisplayData[]> | undefined
    getAllEmploymentPositionsForCompany(): Promise<CompanyEmploymentPositionDisplayData[]> {
        if (!this._getAllEmploymentPositionsForCompany) throw new Error("resource has not been properly initialized")
        return this._getAllEmploymentPositionsForCompany(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/positions/{id}/recruiters',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllRecruitersForPosition: IResourceMethodObservableStrict<null, null, {id: number}, RecruiterPositionData[]> | undefined
    getAllRecruitersForPosition(id: number): Promise<RecruiterPositionData[]> {
        if (!this._getAllRecruitersForPosition) throw new Error("resource has not been properly initialized")
        return this._getAllRecruitersForPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/positions/{id}/applicantPersona',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicantPersonaForId: IResourceMethodObservableStrict<null, null, {id: number}, ApplicantPersonaData> | undefined
    getApplicantPersonaForId(id: number): Promise<ApplicantPersonaData> {
        if (!this._getApplicantPersonaForId) throw new Error("resource has not been properly initialized")
        return this._getApplicantPersonaForId(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/positions/{id}/splits',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getContentsplitsForPosition: IResourceMethodObservableStrict<null, null, {id: number}, PositionContentSplitData[]> | undefined
    getContentsplitsForPosition(id: number): Promise<PositionContentSplitData[]> {
        if (!this._getContentsplitsForPosition) throw new Error("resource has not been properly initialized")
        return this._getContentsplitsForPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/positions/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPosition: IResourceMethodObservableStrict<null, null, {id: number}, CompanyEmploymentPositionData> | undefined
    getEmploymentPosition(id: number): Promise<CompanyEmploymentPositionData> {
        if (!this._getEmploymentPosition) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/positions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositions: IResourceMethodObservableStrict<CompanyPositionFilterData, {page?: number, pageSize?: number, sortAsc?: boolean}, null, PagedData<CompanyEmploymentPositionDisplayData>> | undefined
    getEmploymentPositions(requestBody: CompanyPositionFilterData, requestParams?: {page?: number, pageSize?: number, sortAsc?: boolean}): Promise<PagedData<CompanyEmploymentPositionDisplayData>> {
        if (!this._getEmploymentPositions) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositions(requestBody, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/positions/unseen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionsWithUnseenApplications: IResourceMethodObservableStrict<null, {pageSize: number}, null, PagedData<CompanyLatestEmploymentPositionData>> | undefined
    getEmploymentPositionsWithUnseenApplications(requestParams: {pageSize: number}): Promise<PagedData<CompanyLatestEmploymentPositionData>> {
        if (!this._getEmploymentPositionsWithUnseenApplications) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionsWithUnseenApplications(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/positions/{id}/update',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateEmploymentPosition: IResourceMethodObservableStrict<CompanyEmploymentPositionData, null, {id: number}, null> | undefined
    updateEmploymentPosition(requestBody: CompanyEmploymentPositionData, id: number): Promise<null> {
        if (!this._updateEmploymentPosition) throw new Error("resource has not been properly initialized")
        return this._updateEmploymentPosition(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/positions/{id}/recruiters/edit',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateRecruitersOfPosition: IResourceMethodObservableStrict<null, {recruiters: number[]}, {id: number}, AssignedRecruiterData[]> | undefined
    updateRecruitersOfPosition(id: number, requestParams: {recruiters: number[]}): Promise<AssignedRecruiterData[]> {
        if (!this._updateRecruitersOfPosition) throw new Error("resource has not been properly initialized")
        return this._updateRecruitersOfPosition(null, requestParams, {id: id}).toPromise()
    }

}