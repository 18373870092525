import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AboutPageComponent} from "./about-page.component";
import {UtilityModule} from "../../utility/utility.module";
import {PublicWebinarResource} from "../../../generated/resources";


@NgModule({
  declarations: [
    AboutPageComponent
  ],
  imports: [
    CommonModule,
    UtilityModule
  ],
  providers: [
    PublicWebinarResource
  ]
})
export class AboutPageModule { }
