import { Injectable } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ShareDialogComponent} from "../components/share-dialog/share-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor(
    private dialog: MatDialog
  ) { }

  openSharingDialog(shareText: string, shareLink: string) {
    const dialogRef = this.dialog.open(ShareDialogComponent, {
      data: {
        shareText: shareText,
        shareLink: shareLink
      }
    })

  }
}
