import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MediaService} from "../../utility/services/media.service";
import {AnimationsService} from "../../utility/services/animations.service";
import {WebinarData} from "../../../generated/data";
import {CaseStudyData} from "../case-studies/single-case-study/single-case-study.component";
import {PublicLandingPageResource, PublicWebinarResource} from "../../../generated/resources";
import {MetaService} from '../../utility/services/meta.service';
import {AnalyticsService} from '../../utility/services/gdprconsent/analytics.service';
import {environment} from '../../../environments/environment';
import {MetaDefinition} from "@angular/platform-browser";
import {DemodeskService} from "../demo-booking/demodesk.service";
import {AccordionItem} from "../../utility/components/accordion/accordion.component";
import {UtmService} from "../../utility/services/utm.service";

@Component({
    selector: 'app-company-page',
    templateUrl: './company-page.component.html',
    styleUrl: './company-page.component.scss'
})
export class CompanyPageComponent implements OnInit, AfterViewInit{

  @ViewChild("faqItem") faqItem: TemplateRef<any>

    environment = environment

    webinarData: WebinarData[] = []
    caseStudyData: CaseStudyData = {
        companyName: "Userlike",
        studyTitle: "Userlike generiert mit Talentagent gezielt dreimal mehr Bewerbungen von Top-Absolventen",
        companyLogo: 1,
        videoUrl: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        videoTagline: "Userlike ist die führende deutsche Plattform für Kunden-Messaging und KI-gestützte Support-Automatisierung.",
        studyText:
            "Die Customer-Experience-Plattform Userlike setzt in ihrem Recruiting einen besonderen Fokus auf das Potenzial junger Talente. " +
            "Für ihr hauseigenes Traineeship-Programm suchte das Unternehmen gezielt nach Absolventen - und fand mit Talentagent die entscheidende Expertise dafür.\n" +
            "\n" + "<br>" + "<br>" +
            "Userlike bietet innovative Kommunikationslösungen für Unternehmen, die auch bei der digital versierten Generation Z Anklang finden." +
            " Auf Talentagent konnte Userlike genau diese Absolventen, deren Technikaffinität und Know-How einen idealen Match für das hauseigene Produkt darstellt, " +
            "finden und ansprechen. Das zeigt sich am Beispiel der ersten Trainee, die durch Talentagent zu Userlike kam:" +
            " Sie erzielte bereits kurze Zeit nach ihrem Hiring bemerkenswerte Erfolge in ihrer Position und bringt täglich ein hohes Maß an Freude für ihre Aufgaben mit.\n" +
            "\n" + "<br>" + "<br>" +
            "Die Partnerschaft hat es Userlike ermöglicht, ihren Recruiting-Funnel stets mit vielversprechenden Gesprächen" +
            " und Potentialen versorgt zu wissen. Hierbei sorgt Talentagent bereits im Vorhinein für zielgruppengerechte Stelleninserate und" +
            " qualitativ hochwertige Bewerber, sodass sich Userlike zu 100% auf die stattfindenden Kandidateninterviews fokussieren kann.",
        quoteText:
            "“Seit wir mit Talentagent zusammenarbeiten, haben wir stets einen gefüllten Recruiting Funnel für unsere Trainee- & Juniorpositionen.”",
        quoteAuthor: "Kristina Nolte",
        quoteAuthorTitle: "Recruiterin bei Userlike"
    }

    faqItems: AccordionItem[] = []

    primeCompanyTooltipText: string = "Mit dem Premiumprofil wird dein Unternehmen auf der Plattform prominent hervorgehoben und erhält maximale Sichtbarkeit – inklusive Integration von Bildern und Videos."
    topJobTooltipText: string = "Die Top Job Platzierung lässt deine Stellenanzeige aus der Masse herausstechen, um schneller mehr Bewerber anzuziehen – dank erstklassiger Positionierung im Job-Feed der Absolventen und ansprechender visueller Optimierungen."

    @ViewChild("contactSection") contactSection: ElementRef
    @ViewChild("stepsSection") stepsSection: ElementRef


    carouselReady: boolean = false

    constructor(
        public mediaService: MediaService,
        private animationsService: AnimationsService,
        private webinarResource: PublicWebinarResource,
        private metaService: MetaService,
        private analyticsService: AnalyticsService,
        private demodeskService: DemodeskService,
        private publicLandingPageResource: PublicLandingPageResource,
        private changeDetectorRef: ChangeDetectorRef,
        private utmService: UtmService
    ) {
    }

    ngOnInit() {



        setTimeout(() => {
          this.carouselReady = true
        }, 100)
        this.handleScrollAnimations()
        this.loadWebinars()
        this.addMetaTags()
    }

    ngAfterViewInit() {
      this.fillFaqs()
      this.changeDetectorRef.detectChanges()
    }


  addMetaTags() {
        let tag: MetaDefinition = {
            name: 'description',
            content: 'Sie suchen junge Talente für Ihr Unternehmen? Finden Sie im Talentagent passende Top-Bewerber in der größten Absolventenplattform Deutschlands!',
        }
        this.metaService.setMetaTag(tag)
    }

    navToDemodesk(context: string) {
        this.demodeskService.navToDemodesk(context)
    }

    routeToCompanyOnboarding() {
      this.analyticsService.reportCompanyOnboarding("Process")
      window.open(environment.companyFrontendUrl +  '/onboarding' + this.utmService.getUtmParamsAsString())
    }

    reportCaseStudyClick() {
        this.analyticsService.reportCaseStudyClick('CompanyPage', 'Userlike')
    }

    loadWebinars() {
        this.webinarResource.getAllWebinars().then((result: WebinarData[]) => {
            this.webinarData = result
        })
    }


    handleScrollAnimations() {

        this.animationsService.handleOnScrollAnimation('.hidden', 'show', true)
        this.animationsService.handleOnScrollAnimation('.step-text', 'slideInBottom', true)
        this.animationsService.handleOnScrollAnimation('.usp', 'bounceIn', false)
        this.animationsService.handleOnScrollAnimation('.jobs', 'jobSlide', false)
        this.animationsService.handleOnScrollAnimation('.arrow', 'revealFromTop', false)
        this.animationsService.handleOnScrollAnimation('.highlight', 'highlight-show', false)
        this.animationsService.handleOnScrollAnimation('.partner-logo', 'slideInLeft', true)
    }

    fillFaqs() {
      this.faqItems = [
        {
          title: "Welche Talente bewerben sich auf Talentagent?",
          content: "Auf Talentagent registrieren sich jährlich über 50.000 Absolvent:innen. <br> <br>" +
            "Diese verteilen sich wie folgt auf die Studienrichtungen: <br>" +
            "49% Wirtschaftswissenschaften <br>" +
            "31% MINT <br>" +
            "13% Geistes & Sozialwissenschaften <br>" +
            "7% Andere <br> <br>" +
            "Wenn Du für eine bestimmte offene Stelle nähere Infos zum Kandidatenpotenzial auf Talentagent haben möchtest, vereinbare gern ein Gespräch mit unserem Team. "
        },
        {
          title: "Wie wird die Qualität der Bewerber garantiert?",
          content: "Talentagent ist eine geschlossene Plattform. Unsere Talente absolvieren vor der Nutzung der Plattform ein Onboarding, welches uns ermöglicht " +
            "Näheres über Studiengang, Wunschtätigkeit und Sprachkenntnisse zu erfahren. " +
            "Im Anschluss sorgt unser Matching dafür, dass deine Stellenanzeige der passenden Zielgruppe ausgespielt wird. " +
            "Du hast während deiner Ausschreibung jederzeit die Möglichkeit deine Suche mit Hilfe von unserem Frage-Feature oder Split-Tests zu optimieren."
        },
        {
          title: "Was geschieht mit ungenutzten Bewerbungs-Kontingenten?",
          content: "Bei Talentagent kannst du deine Stellenanzeige jederzeit tauschen oder von Vornherein Stellen parallel schalten. " +
            "Solltest du also mal auf eine deiner Stellen nicht so viele Bewerbungen erhalten, verfällt dein Kontingent nicht, " +
            "sondern du kannst es einfach für eine andere Stelle nutzen."
        },
        {
          title: "Welcher Recruitingerfolg ist mit den geprüften Juniorbewerbungen zu erwarten?",
          content: "Unsere Kunden generieren aus 15 Bewerbungen im Schnitt 1-2 Einstellungen."
        },
        {
          title: "Warum lohnt sich Talentagent gegenüber klassischen Stellenbörsen im Junior-Recruiting?",
          template: this.faqItem
        }
      ]
    }
}
