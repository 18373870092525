<div class="loading-container" *ngIf="!loadingList" [ngClass]="{'darker': darkerColor}">
  <div class="loading-content"></div>
</div>

<!--<div *ngIf="loadingList" class="width-100 padding-vertical">-->
<!--  <div *ngFor="let element of inputList" class="loading-list-container" >-->
<!--    <div class="loading-content"></div>-->
<!--  </div>-->
<!--</div>-->

