import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-use-regulations',
  templateUrl: './legal-use-regulations.component.html',
  styleUrl: './legal-use-regulations.component.scss'
})
export class LegalUseRegulationsComponent {

}
