import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, CreateConsultantAppointmentRequestData, TalentConsultantAvailabilitySlotData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentConsultantAppointmentRequestController
 */

@Injectable()
@ResourceParams({})
export class TalentConsultantAppointmentRequestResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/appointments/createRequest',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createConsultantAppointmentRequest: IResourceMethodObservableStrict<CreateConsultantAppointmentRequestData, null, null, JsonWrappedValue<boolean>> | undefined
    createConsultantAppointmentRequest(requestBody: CreateConsultantAppointmentRequestData): Promise<boolean> {
        if (!this._createConsultantAppointmentRequest) throw new Error("resource has not been properly initialized")
        return this._createConsultantAppointmentRequest(requestBody, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/appointments/slots',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSlots: IResourceMethodObservableStrict<null, {consultantId: number}, null, TalentConsultantAvailabilitySlotData> | undefined
    getSlots(requestParams: {consultantId: number}): Promise<TalentConsultantAvailabilitySlotData> {
        if (!this._getSlots) throw new Error("resource has not been properly initialized")
        return this._getSlots(null, requestParams, null).toPromise()
    }

}