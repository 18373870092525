<div class="flex column center gap-xl content-padding">
  <div class="content-width card-shadow">
    <h1>Allgemeine Geschäftsbedingungen der Internet-Plattform www.talentagent.de für Bewerber</h1>
    <span>Stand:: 01.06.2019</span>
    <br>
    <h1>§1 Allgemeines</h1>

    <h2>(1)	Geltungsbereich</h2>
    <br>1.1 Die nachstehenden allgemeinen Geschäftsbedingungen (nachfolgend auch „Nutzungsbestimmungen“) regeln die Bedingungen zur Nutzung der technischen Kontakt- und Kommunikationsplattformen www.talentagent.de (nachfolgend „Talentagent“) und www.arbeitgeber.talentagent.de (nachfolgend „Talentagent“, gemeinsam „Webseiten“) für Bewerber sowie die damit verbundenen unentgeltlichen Leistungen, welche die AdVertical GmbH & Co. KG auf bzw. über die Webseiten erbringt.

    <br>1.2 Betreiber und Anbieter der Dienste ist die Uniwunder GmbH, Bergstraße 29, 011069 Dresden (nachfolgend „Uniwunder“, „wir“ oder „uns“).

    <br>1.3 Beide Webseiten dienen der Vermittlung von Kontakten zwischen Arbeitgebern/Unternehmen (nachfolgend „Arbeitgeber“) und Bewerbern (nachfolgend auch „Nutzer“, „Sie“, „Ihr“). Die Webseiten sind als Einheit zu verstehen, wobei sich die Nutzer auf Talentpool und Arbeitgeber auf Talentagent registrieren. Ziel der Webseiten ist es, den Recruiting-Prozess sowohl für den Bewerber als auch für den Arbeitgeber zu vereinfachen, sowie effektiver und effizienter zu gestalten.

    <br>1.4 Talentpool und Talentagent sind Produkte der Uniwunder GmbH


    <h2>(2) Vertragsschluss</h2>
    <br>2.1 Mit dem Absenden Ihrer Registrierung über die Funktionen „Registrieren“ und „Los geht’s“ (Angebot) auf der Talentpool-Plattform erklären Sie sich als Nutzer damit einverstanden, dass Sie mit Uniwunder, nach erfolgter Freischaltung des Zugangs durch Innospire (Annahme), einen rechtsverbindlichen Vertrag über die Nutzung von Talentpool und Leistungen, welche Uniwunder auf bzw. über die Webseiten erbringt, abschließen.

    <br>2.2 Sie erklären mit Ihrer Registrierung, im Rahmen des Anmeldevorganges, diese Nutzungsbestimmungen sowie die Datenschutzerklärung zur Kenntnis genommen und sie als Nutzungsgrundlage akzeptiert zu haben.

    <br>2.3 Sollten Sie diesem Vertrag nicht zustimmen, dann bitten wir Sie nicht die Funktionen „Registrieren“ oder „Los geht’s“ zu nutzen und in keiner Weise die Dienste unserer Webseiten in Anspruch zu nehmen. Eine Nutzung der Webseiten ohne die Annahme der Nutzungsbestimmungen ist nicht möglich.


    <h2>(3) Registrierung</h2>
    <br>3.1 Mit der Registrierung wird für den Nutzer ein Profil (nachfolgend „Nutzerprofil“) bzw. ein Benutzerkonto (nachfolgend „Konto“), welches Ihre Identität darstellt und mit welchem Sie auf den Webseiten auftreten, eingerichtet. Ihr Nutzerkonto ist ohne ausdrückliche Zustimmung von Uniwunder nicht übertragbar.

    <br>3.2 Sie erklären sich mit Ihrer Registrierung damit einverstanden, dass Ihr Nutzerprofil auf Talentagent aufgeführt und für alle bei uns registrierten Arbeitgeber, im Falle einer durch Sie erteilten Freigabe, einsehbar ist. Weiterhin erteilen Sie uns durch Bestätigung der Nutzungsbestimmungen Ihre Zustimmung zur Übermittlung Ihrer Daten an potenzielle Arbeitgeber oder mit uns kooperierende Personaldienstleister zum Zweck einer möglichen Beschäftigung. Näheres dazu finden Sie unter Abschnitt § 3.

    <br>3.3 Sie haben innerhalb ihres Profils die Möglichkeit, neben ihren Kontaktinformationen auch Dokumente (z.B. Lebensläufe) bzw. andere Informationen, welche insbesondere Ihre berufliche Qualifikation widerspiegeln, zu speichern.


    <br>3.4 Die Nutzung von Talentpool sowie die von uns angebotenen und damit verbundenen Leistungen sind für Bewerber kostenfrei.

    <br><br>
    <h1>§ 2 Leistungsgegenstand / Leistungsvorbehalt</h1>

    <br><br>(1)	Talentpool und Talentagent sind technische Plattformen zur Vermittlung von Kontakten zwischen Bewerbern und potenziellen Arbeitgebern inklusive eines integrierten automatisierten Matching-Systems. Dieses System gleicht die von den Arbeitgebern in unserem System veröffentlichten Stellenanzeigen mit den Profilen der sich kostenlos registrierten Nutzer ab und zeigt den Arbeitgebern die jeweils passenden Profile in anonymisierter Form an. Die Nutzer haben bei diesem automatisierten Verfahren die Möglichkeit ihr Profil, welches Sie selbst mit Informationen und Qualifikationen anreichern können, einem anfragenden Arbeitgeber offenzulegen. Dieser erhält ab diesem Zeitpunkt Zugriff auf die Profilinformationen des Nutzers. Diesen Zugriff kann der Nutzer jederzeit widerrufen. Weiterhin wird durch Talentagent und Talentpool ein integriertes Kommunikationssystem bereitgestellt, welches den Kontakt zwischen Arbeitgeber und Bewerber ermöglicht.

    <br><br>(2)	Des Weiteren erfolgt eine Auswahl geeigneter Matches durch unsere Mitarbeiter. Bei diesem Verfahren findet eine Übermittlung von „empfehlungswürdigen“ Bewerbern an Arbeitgeber mit passenden veröffentlichten Stellenanzeigen statt. „Empfehlungswürdig“ sind die Bewerber, welche aus unserer Sicht die geforderten Qualifikationen der ausgeschriebenen Stellen erfüllen bzw. fachlich geeignet sind. Eine Übermittlung an Arbeitgeber, welche aus unserer Sicht keine Ihren Qualifikationen entsprechende Stellenausschreibung veröffentlicht haben, findet dabei nicht statt.

    <br><br>(3)	Unsere Leistungen bestehen in:
    <br>a)	der Bereitstellung der Kontakt- bzw. Kommunikationsplattformen Talentpool und Talentagent, jedoch unter Ausschluss einer ständigen Verfügbarkeit (siehe dazu Abschnitt § 5)
    <br>b)	dem automatisierten Abgleich (Matching-System) von Bewerbern mit den von Arbeitgebern ausgeschrieben Stellen
    <br>c)	oder dem Abgleich von Bewerbern mit den von Arbeitgebern ausgeschrieben Stellen durch einen Mitarbeiter
    <br>d)	dem Übermitteln/Vorstellen passender Bewerber an potenzielle Arbeitgeber mit entsprechenden ausgeschriebenen Stellen oder an mit uns kooperierende Personaldienstleister.

    <br><br> (4)	Die Verpflichtung zur Vermittlung eines Beschäftigungsverhältnisses ist kein Gegenstand der Leistung. Ein Erfolg ist von uns nicht geschuldet und kann nicht garantiert werden.

    <br> <br>(5)	Wir behalten uns vor, Veränderungen oder Ergänzungen am Nutzerprofil vorzunehmen, was beispielsweise dann geschieht, wenn Sie wichtige Angaben in Ihrem Lebenslauf vergessen oder wir Formfehler feststellen.

    <br><br> (6)	Wir behalten uns ausdrücklich das Recht vor, Leistungen und Funktionen von Talentagent sowie Talentpool ganz oder teilweise jederzeit zu ändern, zu entfernen oder zu beenden oder den Betrieb der Webseiten jederzeit zeitweise oder ganz einzustellen.

    <br>
    <h1>§ 3 Datenschutz</h1>

    <br><br>(1)	Der Schutz von personenbezogenen Daten hat für uns höchste Priorität. Die Erhebung, Nutzung und Freigabe Ihrer personenbezogenen Daten als Nutzer und Besucher von Talentpool unterliegt unserer Datenschutzerklärung und deren Aktualisierungen, welche unter dem folgendem Link  abrufbar sind. Sämtliche Daten werden von den Nutzern freiwillig, zur Ermöglichung der Leistungserbringung, an uns übermittelt.

    <br><br> (2)	Mit Bestätigung dieser Nutzungsbestimmungen im Rahmen Ihrer Registrierung erkennen Sie als Nutzer an und willigen ein, dass die freiwillig von Ihnen eingegebenen Daten durch uns und jedem zum Zwecke der Personalvermittlung kooperierenden Arbeitgeber oder Personaldienstleister eingesehen werden können, wenn Sie Ihr Profil nach eingegangener Anfrage durch die Freigabe-Funktion freischalten. Sie willigen dadurch weiterhin ein, dass wir zum Zweck unserer Personalvermittlung bzw. Leistungserbringung Ihre Daten an interessierte bzw.  potenzielle Arbeitgeber oder an mit uns kooperierenden Personaldienstleister in geeigneter Form (telefonisch, schriftlich, digital) weitergeben  oder diesen über einen Zugang auf Talentagent Zugriff bzw. Einsicht in Ihre Daten ermöglichen können. Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist demnach die Einwilligung des Betroffenen sowie die Erfüllung eines Vertrages mit Ihnen.

    <br><br>  (3)	Mit Übermittlung oder Zugänglichmachung der personenbezogenen Daten der Bewerber durch den Bewerber selbst oder uns wird der Arbeitgeber weiterer Verantwortlicher im Sinne des Datenschutzrechts.

    <br><br>(4)	Sie wissen und willigen ein, dass wir Ihre Profildaten durch Daten aus öffentlich zugänglichen Quellen ergänzen können, um somit das Profil gegenüber potenziellen Arbeitgebern interessanter und ausführlicher zu gestalten. Ebenso willigen Sie ein, dass wir Ihre Dokumente (wie z.B. den Lebenslauf) anpassen und korrigieren können, sollten wir wichtige fehlende Inhalte oder Formfehler feststellen. Nach einer solchen Änderung informieren wir Sie mit einer entsprechenden Email.


    <h1>§ 4 Pflichten der Nutzer</h1>


    <h2>(1)  Mindestalter</h2>

    Der Nutzer sichert zu, dass er über 18 Jahre alt ist. Die Nutzung von Talentpool ist für Personen unter 18 Jahre nicht vorgesehen. Auf Anfrage von uns hat der Nutzer die nach diesem Abschnitt § 4 zugesicherten Angaben nachzuweisen.


    <h2>(2) Kontoinformationen</h2>

    Sie erklären sich als Nutzer damit einverstanden, dass Sie für Ihr Konto ein sicheres und starkes Passwort auswählen und dieses Passwort stets geheim halten, vertraulich behandeln und keine Teile Ihres Kontos an Dritte zu übertragen. Es wird empfohlen, das Passwort aus Sicherheitsgründen regelmäßig zu ändern. Die Sicherung sowie die Aufbewahrung der Kontozugangsdaten fällt in den ausschließlichen Verantwortungsbereich des Nutzers. Sollten Sie von einer missbräuchlichen Nutzung Ihrer Zugangsdaten durch Dritte erfahren, bitten wir Sie uns unverzüglich darüber zu unterrichten.


    <h2>(3) Verpflichtungen</h2> der Nutzer

    Sie sind als Nutzer weiterhin insbesondere verpflichtet,

    <br>a)	ausnahmslos wahrheitsgemäße und nicht irreführende Angaben, insbesondere über Ihre Person, Ihre Qualifikationen, Ihre Berufserfahrung, oder vergleichbare Eigenschaften anzugeben;
    <br> b)	lediglich Ihren Klarnamen und keine Pseudonyme oder Künstlernamen zu verwenden;
    <br> c)	solche Bilder als Nutzerbild zu verwenden, auf denen Sie deutlich zu erkennen sind. Andere Abbildungen als Nutzerbild, wie z.B. Bilder Dritter, nicht existenter Personen oder anderer Fantasiewesen sind nicht gestattet. Ebenso wenig Logos oder andere Designs;
    <br> d)	sich an geltendes Recht, alle Rechte Dritter und diese Nutzungsbestimmungen zu halten


    <h2>(4) Verhaltensregeln</h2>

    Ihnen ist als Nutzer insbesondere untersagt,

    <br> a)	pornografische, gewaltverherrlichende, missbräuchliche, sittenwidrige oder Jugendschutzgesetze verletzende Inhalte zu verwenden;

    <br> b)	Dritte zu beleidigen oder verleumderische Inhalte über Dritte zu verbreiten;

    <br>c)	andere Nutzer unzumutbar, insbesondere durch Spam, zu belästigen (verwiesen wird in diesem Zusammenhang auf >§ 7 des Gesetzes gegen unlauteren Wettbewerb „UWG“);

    <br>d)	wettbewerbswidrige Handlungen vorzunehmen oder zu fördern;

    <br>e)	die geistigen Eigentumsrechte anderer zu verletzen, insbesondere durch Verletzung des Urheber-, Patent-, Marken-, Geschmacks- und Gebrauchsmusterrechts sowie durch Verletzung von Geschäftsgeheimnissen oder von anderen Eigentumsrechten (kopieren oder verteilen Sie daher keine Beiträge oder sonstige Inhalte ohne vorherige Genehmigung durch die Eigentümer);

    <br> f)	Mechanismen, Techniken oder Programme (wie z.B. Viren, Trojaner oder Spyware) oder sonstiges zu verwenden, was das Funktionieren der Webseiten beeinflussen, stören oder beschädigen könnte oder wodurch unberechtigt auf personenbezogene Daten, Dienste oder sonstige Informationen zugegriffen werden kann;

    <br>g)	durch automatisierte Methoden oder Bots auf unsere Webseiten zuzugreifen, Kontakte hinzuzufügen und Nachrichten zu senden oder umzuleiten;

    <br>h)	das Erscheinungsbild oder die Funktionsweise der Webseiten zu simulieren oder vergleichbares zu betreiben;

    Es wird darauf hingewiesen, dass die obige Aufzählung keine abschließende, sondern vielmehr eine beispielhafte Auflistung darstellt.

    <h2>(5) Aktualität des Nutzerprofils</h2>

    Wir bitten Sie weiterhin Ihr Profil stets auf dem aktuellsten Stand zu halten und Ihr Profil regelmäßig zu aktualisieren. Wir behalten uns das Recht vor, Ihr Nutzerprofil zu deaktivieren, wenn wir eine längere Inaktivität Ihres Kontos feststellen. Nach einer Deaktivierung bekommen Sie von uns eine E-Mail mit entsprechendem Hinweis auf Ihre Inaktivität. Arbeitgeber haben dann nicht mehr die Möglichkeit Sie zu finden und zu kontaktieren.


    <br> <h2>(6) Missbräuchliche Angaben</h2>

    Missbräuchliche Angaben werden ohne Vorankündigung deaktiviert oder gelöscht. Solche Angaben sind insbesondere bei falschen (wie bspw. solche aus Abschnitt <h1>§ 4 (3)) und/oder nicht aktuellen und/oder nicht vollständigen Daten gegeben.</h1>



    <h1>§ 5 Verfügbarkeit der Webseiten</h1>

    Eine ständige Verfügbarkeit der Webseiten kann weder gewährleistet noch garantiert werden. Wir werden jedoch im Rahmen der technischen und betrieblichen Möglichkeiten unter Berücksichtigung der Interessen der Nutzer sämtliche notwendige Maßnahmen ergreifen, um eine störungsfreie Funktionsfähigkeit zu ermöglichen. Wir übernehmen keine Haftung für Schäden, die aus einer Nichtverfügbarkeit entstehen.


    <br>
    <h1>§ 6 Laufzeit / Beendigung des Vertrages / Löschung</h1>

    <br>(1)	Das Vertragsverhältnis wird auf unbestimmte Zeit geschlossen und kann von beiden Seiten, soweit im Einzelfall keine besonderen Bestimmungen zur Laufzeit entgegenstehen, jederzeit beendet bzw. gekündigt werden. Die Kündigung kann per Post an AdVertical GmbH & Co. KG, Nürnberger Str. 39, <br>01187 Dresden oder per Email an info&#64;hochschulinitiative.de erfolgen. Sie können den Vertrag außerdem kündigen indem Sie Ihr Konto schließen und nicht mehr auf die Dienste der Webseiten zugreifen bzw. sie nicht mehr nutzen. Auf das Erfordernis einer gesonderten Benachrichtigung an uns wird in diesem Fall verzichtet.

    <br>(2)	Unberührt von der Beendigung des Vertrages bleiben die Verbote des Abschnittes§ 4 (4) dieser Nutzungsbestimmungen bestehen.

    <br>(3)	Wir sind nach Beendigung des Vertrages weiterhin berechtigt Ihr Feedback zu nutzen und offenzulegen.

    <br>(4)	Wir behalten uns das Recht vor Ihr Nutzerkonto zu schließen oder zeitweise zu sperren, Ihr Nutzerkonto sowie Ihre Daten zu löschen sowie Ihnen zu kündigen, sollten Sie gegen diese Nutzungsbestimmungen oder sonstiges geltendes Recht verstoßen haben.


    <h1>§ 7 Freistellung</h1>

    Sie erklären sich als Nutzer mit Bestätigung dieser Nutzungsbestimmungen bereit, uns von sämtlichen Schäden, Aufwendungen (wie z.B. Kosten für einen Rechtsanwalt) oder Ansprüchen Dritter freizustellen, die auf einem Verstoß Ihrerseits gegen diese Nutzungsbestimmungen beruhen, es sei denn, Sie haben die Rechtsverletzung nicht zu vertreten.


    <h1>§ 8 Nachrichten von Talentpool an die Nutzer</h1>


    Durch Akzeptieren der Nutzungsbestimmungen willigen Sie ein, dass wir Ihnen innerhalb unserer Webseite oder über die von Ihnen im Nutzerprofil bereitgestellten Informationen wie E-Mail-Adresse, Mobilfunknummer, etc., Mitteilungen und Nachrichten zum Zweck der Erbringung unserer Dienstleistung zukommen lassen können. Wir verweisen in diesem Zusammenhang auf die Datenschutzerklärung  von Talentpool.



    <h1>§ 9 Geistiges Eigentum</h1>

    Wir behalten uns sämtliche geistigen Eigentumsrechte, insbesondere an Talentagent und jeder mit der Webseite verbundenen Software, Datenbank, Nutzerschnittstelle sowie seinen einzelnen grafischen und textlichen Elementen vor und dürfen ohne vorherige Zustimmung des Anbieters nicht genutzt, verbreitet, vervielfältigt oder in irgendeiner sonstigen Weise verwertet werden.



    <h1>§ 10 Haftung / Haftungsausschluss</h1>

    Wir sowie unsere gesetzlichen Vertreter und Erfüllungsgehilfen haften nur für Vorsatz oder grobe Fahrlässigkeit, es sei denn, dass Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit betroffen sind. Soweit wesentliche Vertragspflichten (folglich solche Pflichten, deren Einhaltung für die Erreichung des Vertragszwecks von besonderer Bedeutung sind) betroffen sind, wird auch für leichte Fahrlässigkeit gehaftet. Dabei beschränkt sich die Haftung auf den vorhersehbaren, vertragstypischen, unmittelbaren Schaden. Auch im Falle eines grob fahrlässigen Verstoßes gegen nicht wesentliche Vertragspflichten haften wir nur in Höhe des vorhersehbaren, vertragstypischen, unmittelbaren Schadens. Von den vorstehenden Bestimmungen nicht umfasst ist eine Haftung nach den Bestimmungen des Produkthaftungsgesetzes.



    <h1>§ 11 Inhalte von Dritten</h1>

    Talentpool kann Links zu externen Webseiten enthalten. Für die Inhalte dieser externen Seiten sind allein deren Anbieter verantwortlich. Sollten uns Rechtsverstöße durch verlinkte Internetseiten bzw. durch deren Inhalte bekannt werden, wird die Verlinkung unverzüglich gelöscht.



    <h1>§ 12 Änderung der Nutzungsbestimmungen / Datenschutzerklärung</h1>

    (1)	Maßgeblich für die Regelungsinhalte der Nutzungsbestimmungen ist die jeweils zum Zeitpunkt des Vertragsabschlusses geltende Fassung der Nutzungsbestimmungen. Wir behalten uns das Recht vor diese Nutzungsbestimmungen sowie die Datenschutzerklärung jederzeit zu ändern und zu ergänzen, es sei denn diese Änderung ist für den Nutzer nicht zumutbar. Die Änderungen und Ergänzungen der Nutzungsbestimmungen bedürfen zu ihrer Wirksamkeit der Textform und werden von uns rechtzeitig durch eine entsprechende E-Mail bekannt gegeben. Zu Änderungen und Ergänzungen kann es insbesondere aufgrund einer geänderten Rechtslage oder höchstrichterlichen Rechtsprechung, Regelungslücken in den Nutzungsbestimmungen, Veränderungen der wirtschaftlichen Verhältnisse oder anderen vergleichbaren Gründen, welche die Nutzer nicht unangemessen benachteiligen, kommen.
    (2)	Widerspricht der Nutzer nicht innerhalb von sechs <h2>(6) Wochen</h2> nach Bekanntgabe der Neufassung, gelten die geänderten Nutzungsbestimmungen als vom Nutzer angenommen. Durch diese stillschweigende Zustimmung gilt die Neufassung ab diesem Zeitpunkt als rechtskräftig akzeptiert. Wir werden die Nutzer im Zuge dessen auf die Bedeutung seines Verhaltens besonders hinweisen und über sein Widerspruchsrecht informieren.
    (3)	Wir behalten uns das Recht vor, im Fall des fristgemäßen Widerspruchs des Nutzers die Möglichkeit zur Nutzung der Plattform sowie das Vertragsverhältnis zu dem Zeitpunkt zu beenden, zu welchem die neu gefassten Nutzungsbestimmungen in Kraft treten sollen.



    <h1>§ 13 Streitbeilegungsverfahren / Information gemäß § 36 VSBG</h1>

    Wir weisen darauf hin, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.



    <h1>§ 14 Schlussbestimmungen</h1>

    (1)	Die Unwirksamkeit einzelner Bestimmungen berührt die Gültigkeit der übrigen allgemeinen Nutzungsbestimmungen nicht. Dies gilt ebenso, wenn diese Nutzungsbestimmungen eine Vertragslücke enthalten, es sei denn, das Festhalten am Vertrag ist für eine Partei unzumutbar.
    (2)	Aus Gründen der Vereinfachung und besseren Verständlichkeit erfolgen die Personenbezeichnungen in diesen AGB nur in der männlichen Form. Gemeint sind mithin alle Geschlechter.



  </div>
</div>
