import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {AdminRoleData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyKeyAccountController
 */

@Injectable()
@ResourceParams({})
export class CompanyKeyAccountResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/support/keyAccounter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getKeyAccounter: IResourceMethodObservableStrict<null, null, null, AdminRoleData> | undefined
    getKeyAccounter(): Promise<AdminRoleData> {
        if (!this._getKeyAccounter) throw new Error("resource has not been properly initialized")
        return this._getKeyAccounter(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/support/keyaccounter/picture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getKeyAccounterPicture: IResourceMethodObservableStrict<null, null, null, Blob> | undefined
    getKeyAccounterPicture(): Promise<Blob> {
        if (!this._getKeyAccounterPicture) throw new Error("resource has not been properly initialized")
        return this._getKeyAccounterPicture(null, null, null).toPromise()
    }

}