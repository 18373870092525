import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyPageComponent} from "./company-page.component";
import {CareerGuideModule} from "../career-guide/career-guide.module";
import {UtilityModule} from "../../utility/utility.module";
import {WebinarModule} from "../webinar/webinar.module";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ContactFormularComponent} from "../contact-formular/contact-formular.component";


@NgModule({
  declarations: [
    CompanyPageComponent
  ],
    imports: [
        CommonModule,
        CareerGuideModule,
        UtilityModule,
        WebinarModule,
        RouterModule,
        ReactiveFormsModule,
        MatCheckboxModule,
    ]
})
export class CompanyPageModule { }
