export const environment = {
  production: true,
  apiHost: '$$API_HOST$$',
  apiUrl: 'https://$$API_HOST$$/main',
  apiUrlHidCms: 'https://$$HIDCMS_HOST$$',
  cronosApiUrl: 'https://$$CRONOS_HOST$$',
  talentAgentFrontendUrl: 'https://$$TALENTAGENT_FRONTEND_URL$$',
  companyFrontendUrl: 'https://$$TALENTAGENT_COMPANY_URL$$',
    piwikContainerUrl: '$$PIWIK_CONTAINER_URL$$',
    piwikContainerId: '$$PIWIK_CONTAINER_ID$$',
    gtmId: '$$GTM_ID$$'
};
