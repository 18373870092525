<div class="width-100 cap-width flex gap-lg" [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}">
  <div class="guide" [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}">
    <app-career-guide-card [careerGuide]="careerGuides[0]" [size]="'Large'"></app-career-guide-card>
  </div>

  <div class="flex column gap-lg" [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}">
    <div class="height-50 guide delay-0-5s">
      <app-career-guide-card [careerGuide]="careerGuides[1]" [size]="'Small'"></app-career-guide-card>
    </div>
    <div class="height-50 guide delay-1s">
      <app-career-guide-card [careerGuide]="careerGuides[2]" [size]="'Small'"></app-career-guide-card>
    </div>
  </div>
</div>
