import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {PositionQuestionData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminEmploymentPositionQuestionController
 */

@Injectable()
@ResourceParams({})
export class AdminEmploymentPositionQuestionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/positions/questions/{id}/state/change',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _changePositionQuestionState: IResourceMethodObservableStrict<null, {newState: boolean}, {id: number}, null> | undefined
    changePositionQuestionState(id: number, requestParams: {newState: boolean}): Promise<null> {
        if (!this._changePositionQuestionState) throw new Error("resource has not been properly initialized")
        return this._changePositionQuestionState(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/positions/{id}/questions/create',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createPositionQuestion: IResourceMethodObservableStrict<null, {positionQuestion: string}, {id: number}, PositionQuestionData> | undefined
    createPositionQuestion(id: number, requestParams: {positionQuestion: string}): Promise<PositionQuestionData> {
        if (!this._createPositionQuestion) throw new Error("resource has not been properly initialized")
        return this._createPositionQuestion(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/positions/{id}/questions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionQuestions: IResourceMethodObservableStrict<null, null, {id: number}, PositionQuestionData[]> | undefined
    getPositionQuestions(id: number): Promise<PositionQuestionData[]> {
        if (!this._getPositionQuestions) throw new Error("resource has not been properly initialized")
        return this._getPositionQuestions(null, null, {id: id}).toPromise()
    }

}