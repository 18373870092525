import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {WebinarData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminWebinarController
 */

@Injectable()
@ResourceParams({})
export class AdminWebinarResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/webinar',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateWebinar: IResourceMethodObservableStrict<WebinarData, null, null, WebinarData> | undefined
    createOrUpdateWebinar(requestBody: WebinarData): Promise<WebinarData> {
        if (!this._createOrUpdateWebinar) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateWebinar(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/webinar/excelDownload',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _downloadWebinarRegistrationExcel: IResourceMethodObservableStrict<null, {start: Date, end: Date}, null, Blob> | undefined
    downloadWebinarRegistrationExcel(requestParams: {start: Date, end: Date}): Promise<Blob> {
        if (!this._downloadWebinarRegistrationExcel) throw new Error("resource has not been properly initialized")
        return this._downloadWebinarRegistrationExcel(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/webinar',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllWebinars: IResourceMethodObservableStrict<null, {start: Date, end: Date}, null, WebinarData[]> | undefined
    getAllWebinars(requestParams: {start: Date, end: Date}): Promise<WebinarData[]> {
        if (!this._getAllWebinars) throw new Error("resource has not been properly initialized")
        return this._getAllWebinars(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/webinar/{id}/image',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getWebinarImage: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getWebinarImage(id: number): Promise<Blob> {
        if (!this._getWebinarImage) throw new Error("resource has not been properly initialized")
        return this._getWebinarImage(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/webinar/{id}/active',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateActiveStatusOfWebinar: IResourceMethodObservableStrict<null, {activeStatus: boolean}, {id: number}, null> | undefined
    updateActiveStatusOfWebinar(id: number, requestParams: {activeStatus: boolean}): Promise<null> {
        if (!this._updateActiveStatusOfWebinar) throw new Error("resource has not been properly initialized")
        return this._updateActiveStatusOfWebinar(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/webinar/{id}/image',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateWebinarImage: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, {id: number}, null> | undefined
    updateWebinarImage(requestBody: ArrayBuffer, id: number, requestParams: {fileName: string}): Promise<null> {
        if (!this._updateWebinarImage) throw new Error("resource has not been properly initialized")
        return this._updateWebinarImage(requestBody, requestParams, {id: id}).toPromise()
    }

}