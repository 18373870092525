import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SingleCaseStudyComponent} from "./single-case-study/single-case-study.component";
import {CaseStudiesPageComponent} from "./case-studies-page/case-studies-page.component";
import {UtilityModule} from "../../utility/utility.module";


@NgModule({
  declarations: [
    SingleCaseStudyComponent,
    CaseStudiesPageComponent
  ],
  imports: [
    CommonModule,
    UtilityModule
  ]
})
export class CaseStudiesModule {

}
