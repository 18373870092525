import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, RecruiterAccountInfoData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminController
 */

@Injectable()
@ResourceParams({})
export class AdminResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/nonresponsible',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllNonResponsibleRecruiterInformations: IResourceMethodObservableStrict<null, {companyId: number, responsibleRecruiterId: number}, null, RecruiterAccountInfoData[]> | undefined
    getAllNonResponsibleRecruiterInformations(requestParams: {companyId: number, responsibleRecruiterId: number}): Promise<RecruiterAccountInfoData[]> {
        if (!this._getAllNonResponsibleRecruiterInformations) throw new Error("resource has not been properly initialized")
        return this._getAllNonResponsibleRecruiterInformations(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/recruiters',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllRecruiterInformations: IResourceMethodObservableStrict<null, {recruiters: number[]}, null, RecruiterAccountInfoData[]> | undefined
    getAllRecruiterInformations(requestParams: {recruiters: number[]}): Promise<RecruiterAccountInfoData[]> {
        if (!this._getAllRecruiterInformations) throw new Error("resource has not been properly initialized")
        return this._getAllRecruiterInformations(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/utmparameters/campaigns',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAvailableUtmCampaings: IResourceMethodObservableStrict<null, null, null, string[]> | undefined
    getAvailableUtmCampaings(): Promise<string[]> {
        if (!this._getAvailableUtmCampaings) throw new Error("resource has not been properly initialized")
        return this._getAvailableUtmCampaings(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/utmparameters/contents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAvailableUtmContents: IResourceMethodObservableStrict<null, null, null, string[]> | undefined
    getAvailableUtmContents(): Promise<string[]> {
        if (!this._getAvailableUtmContents) throw new Error("resource has not been properly initialized")
        return this._getAvailableUtmContents(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/talent/{id}/note',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentNote: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<string>> | undefined
    getTalentNote(id: number): Promise<JsonWrappedValue<string>> {
        if (!this._getTalentNote) throw new Error("resource has not been properly initialized")
        return this._getTalentNote(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/talent/{id}/profile/dossier/status',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _setDossierStatus: IResourceMethodObservableStrict<JsonWrappedValue<boolean>, null, {id: number}, JsonWrappedValue<boolean>> | undefined
    setDossierStatus(requestBody: JsonWrappedValue<boolean>, id: number): Promise<JsonWrappedValue<boolean>> {
        if (!this._setDossierStatus) throw new Error("resource has not been properly initialized")
        return this._setDossierStatus(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/talent/{id}/note',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _setTalentNote: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, {id: number}, null> | undefined
    setTalentNote(requestBody: JsonWrappedValue<string>, id: number): Promise<null> {
        if (!this._setTalentNote) throw new Error("resource has not been properly initialized")
        return this._setTalentNote(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/company/{id}/banner',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateCompanyBanner: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, {id: number}, null> | undefined
    updateCompanyBanner(requestBody: ArrayBuffer, id: number, requestParams: {fileName: string}): Promise<null> {
        if (!this._updateCompanyBanner) throw new Error("resource has not been properly initialized")
        return this._updateCompanyBanner(requestBody, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/company/{id}/logo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateCompanyLogo: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, {id: number}, null> | undefined
    updateCompanyLogo(requestBody: ArrayBuffer, id: number, requestParams: {fileName: string}): Promise<null> {
        if (!this._updateCompanyLogo) throw new Error("resource has not been properly initialized")
        return this._updateCompanyLogo(requestBody, requestParams, {id: id}).toPromise()
    }

}