import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {TalentEvaluationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantCoachingController
 */

@Injectable()
@ResourceParams({})
export class ConsultantCoachingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/consultant/talents/{id}/evaluation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _approveTalentEvaluation: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    approveTalentEvaluation(id: number): Promise<null> {
        if (!this._approveTalentEvaluation) throw new Error("resource has not been properly initialized")
        return this._approveTalentEvaluation(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultant/talents/{id}/evaluation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createTalentEvaluation: IResourceMethodObservableStrict<null, null, {id: number}, TalentEvaluationData> | undefined
    createTalentEvaluation(id: number): Promise<TalentEvaluationData> {
        if (!this._createTalentEvaluation) throw new Error("resource has not been properly initialized")
        return this._createTalentEvaluation(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/evaluation/result',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _downloadTalentEvaluationResult: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    downloadTalentEvaluationResult(id: number): Promise<Blob> {
        if (!this._downloadTalentEvaluationResult) throw new Error("resource has not been properly initialized")
        return this._downloadTalentEvaluationResult(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/evaluation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentEvaluation: IResourceMethodObservableStrict<null, null, {id: number}, TalentEvaluationData> | undefined
    getTalentEvaluation(id: number): Promise<TalentEvaluationData> {
        if (!this._getTalentEvaluation) throw new Error("resource has not been properly initialized")
        return this._getTalentEvaluation(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultant/talents/applications/{applicationId}/reportApplicationOutcome',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _markTalentAsHired: IResourceMethodObservableStrict<null, null, {applicationId: number}, null> | undefined
    markTalentAsHired(applicationId: number): Promise<null> {
        if (!this._markTalentAsHired) throw new Error("resource has not been properly initialized")
        return this._markTalentAsHired(null, null, {applicationId: applicationId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/coachings/{id}/comment',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateCoachingComment: IResourceMethodObservableStrict<null, {comment: string}, {id: number}, null> | undefined
    updateCoachingComment(id: number, requestParams: {comment: string}): Promise<null> {
        if (!this._updateCoachingComment) throw new Error("resource has not been properly initialized")
        return this._updateCoachingComment(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/consultant/evaluationGroup',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateConsultantEvaluationGroup: IResourceMethodObservableStrict<null, null, null, null> | undefined
    updateConsultantEvaluationGroup(): Promise<null> {
        if (!this._updateConsultantEvaluationGroup) throw new Error("resource has not been properly initialized")
        return this._updateConsultantEvaluationGroup(null, null, null).toPromise()
    }

}