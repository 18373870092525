import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, CompanyPricingConfigData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminPricingController
 */

@Injectable()
@ResourceParams({})
export class AdminPricingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/company/pricing/createOrUpdate',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdatePricing: IResourceMethodObservableStrict<CompanyPricingConfigData, null, null, CompanyPricingConfigData> | undefined
    createOrUpdatePricing(requestBody: CompanyPricingConfigData): Promise<CompanyPricingConfigData> {
        if (!this._createOrUpdatePricing) throw new Error("resource has not been properly initialized")
        return this._createOrUpdatePricing(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/company/{id}/pricing',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPricingForCompany: IResourceMethodObservableStrict<null, null, {id: number}, CompanyPricingConfigData> | undefined
    getPricingForCompany(id: number): Promise<CompanyPricingConfigData> {
        if (!this._getPricingForCompany) throw new Error("resource has not been properly initialized")
        return this._getPricingForCompany(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/pricing/modes',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPricingModes: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<('HiringValue' | 'HiringPercentage' | 'Booking' | 'PrepaidBooking')[]>> | undefined
    getPricingModes(): Promise<JsonWrappedValue<('HiringValue' | 'HiringPercentage' | 'Booking' | 'PrepaidBooking')[]>> {
        if (!this._getPricingModes) throw new Error("resource has not been properly initialized")
        return this._getPricingModes(null, null, null).toPromise()
    }

}