import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.demo.DemoController
 */

@Injectable()
@ResourceParams({})
export class DemoResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/demo/resetAccount/{accountId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _resetDemoAccount: IResourceMethodObservableStrict<null, null, {accountId: string}, null> | undefined
    resetDemoAccount(accountId: string): Promise<null> {
        if (!this._resetDemoAccount) throw new Error("resource has not been properly initialized")
        return this._resetDemoAccount(null, null, {accountId: accountId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/demo/setUpAccount/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _setUpAccount: IResourceMethodObservableStrict<null, null, {id: string}, null> | undefined
    setUpAccount(id: string): Promise<null> {
        if (!this._setUpAccount) throw new Error("resource has not been properly initialized")
        return this._setUpAccount(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/demo/uploadFile',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _uploadFileToFilesystem: IResourceMethodObservableStrict<{file: File}, null, null, null> | undefined
    uploadFileToFilesystem(requestBody: {file: File}): Promise<null> {
        if (!this._uploadFileToFilesystem) throw new Error("resource has not been properly initialized")
        return this._uploadFileToFilesystem(requestBody, null, null).toPromise()
    }

}