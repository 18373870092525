import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminManualReportingController
 */

@Injectable()
@ResourceParams({})
export class AdminManualReportingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/manualReporting/forceReports',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _forceMatchingReportsForPosition: IResourceMethodObservableStrict<null, {positionId: number}, null, null> | undefined
    forceMatchingReportsForPosition(requestParams: {positionId: number}): Promise<null> {
        if (!this._forceMatchingReportsForPosition) throw new Error("resource has not been properly initialized")
        return this._forceMatchingReportsForPosition(null, requestParams, null).toPromise()
    }

}