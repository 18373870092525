import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, ApplicationFailedData, ApplicationOutcomeReportedByTalentData, TalentOffboardingData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentHiringConfirmationController
 */

@Injectable()
@ResourceParams({})
export class TalentHiringConfirmationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/application/failed/jobFound/confirmation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _confirmApplicationFailedReasonJobFoundAndOffboardIfNecessary: IResourceMethodObservableStrict<TalentOffboardingData, null, null, null> | undefined
    confirmApplicationFailedReasonJobFoundAndOffboardIfNecessary(requestBody: TalentOffboardingData): Promise<null> {
        if (!this._confirmApplicationFailedReasonJobFoundAndOffboardIfNecessary) throw new Error("resource has not been properly initialized")
        return this._confirmApplicationFailedReasonJobFoundAndOffboardIfNecessary(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/applications/{id}/failed',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationFailedLogEntry: IResourceMethodObservableStrict<null, null, {id: number}, ApplicationFailedData> | undefined
    getApplicationFailedLogEntry(id: number): Promise<ApplicationFailedData> {
        if (!this._getApplicationFailedLogEntry) throw new Error("resource has not been properly initialized")
        return this._getApplicationFailedLogEntry(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/applications/{id}/outcome/reported',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _hastTalentReportedAnOutcomeForApplication: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<boolean>> | undefined
    hastTalentReportedAnOutcomeForApplication(id: number): Promise<boolean> {
        if (!this._hastTalentReportedAnOutcomeForApplication) throw new Error("resource has not been properly initialized")
        return this._hastTalentReportedAnOutcomeForApplication(null, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/application/outcome',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reportApplicationOutcome: IResourceMethodObservableStrict<ApplicationOutcomeReportedByTalentData, null, null, null> | undefined
    reportApplicationOutcome(requestBody: ApplicationOutcomeReportedByTalentData): Promise<null> {
        if (!this._reportApplicationOutcome) throw new Error("resource has not been properly initialized")
        return this._reportApplicationOutcome(requestBody, null, null).toPromise()
    }

}