import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {BenefitData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.BenefitController
 */

@Injectable()
@ResourceParams({})
export class BenefitResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/recruiter/benefits',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllBenefitsForRecruiter: IResourceMethodObservableStrict<null, {searchString?: string, page: number, pageSize?: number}, null, PagedData<BenefitData>> | undefined
    getAllBenefitsForRecruiter(requestParams: {searchString?: string, page: number, pageSize?: number}): Promise<PagedData<BenefitData>> {
        if (!this._getAllBenefitsForRecruiter) throw new Error("resource has not been properly initialized")
        return this._getAllBenefitsForRecruiter(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/benefits/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getBenefits: IResourceMethodObservableStrict<null, {searchString?: string, page: number, pageSize?: number}, null, PagedData<BenefitData>> | undefined
    getBenefits(requestParams: {searchString?: string, page: number, pageSize?: number}): Promise<PagedData<BenefitData>> {
        if (!this._getBenefits) throw new Error("resource has not been properly initialized")
        return this._getBenefits(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/benefits/time',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTimeRelatedBenefits: IResourceMethodObservableStrict<null, null, null, BenefitData[]> | undefined
    getTimeRelatedBenefits(): Promise<BenefitData[]> {
        if (!this._getTimeRelatedBenefits) throw new Error("resource has not been properly initialized")
        return this._getTimeRelatedBenefits(null, null, null).toPromise()
    }

}