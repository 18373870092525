import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {TopJobSlotData, SlotContentData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyTopJobController
 */

@Injectable()
@ResourceParams({})
export class CompanyTopJobResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/employmentPosition/{positionId}/topJobSlotContent/contactImage',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _addContactImageToSlot: IResourceMethodObservableStrict<{id?: number, fileName: string, file: File}, null, {positionId: number}, null> | undefined
    addContactImageToSlot(requestBody: {id?: number, fileName: string, file: File}, positionId: number): Promise<null> {
        if (!this._addContactImageToSlot) throw new Error("resource has not been properly initialized")
        return this._addContactImageToSlot(requestBody, null, {positionId: positionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/employmentPosition/{positionId}/topJobSlotContent/headerImage',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _addHeaderImageToSlot: IResourceMethodObservableStrict<{id?: number, fileName: string, file: File}, null, {positionId: number}, null> | undefined
    addHeaderImageToSlot(requestBody: {id?: number, fileName: string, file: File}, positionId: number): Promise<null> {
        if (!this._addHeaderImageToSlot) throw new Error("resource has not been properly initialized")
        return this._addHeaderImageToSlot(requestBody, null, {positionId: positionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/topJobSlot/{id}/position/clear',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _clearSlotPosition: IResourceMethodObservableStrict<null, {positionId: number}, {id: number}, TopJobSlotData> | undefined
    clearSlotPosition(id: number, requestParams: {positionId: number}): Promise<TopJobSlotData> {
        if (!this._clearSlotPosition) throw new Error("resource has not been properly initialized")
        return this._clearSlotPosition(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/topJobSlots/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleTopJobSlot: IResourceMethodObservableStrict<null, null, {id: number}, TopJobSlotData> | undefined
    getSingleTopJobSlot(id: number): Promise<TopJobSlotData> {
        if (!this._getSingleTopJobSlot) throw new Error("resource has not been properly initialized")
        return this._getSingleTopJobSlot(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/employmentposition/{id}/topJobSlotContent',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTopJobSlotContentForPosition: IResourceMethodObservableStrict<null, null, {id: number}, SlotContentData> | undefined
    getTopJobSlotContentForPosition(id: number): Promise<SlotContentData> {
        if (!this._getTopJobSlotContentForPosition) throw new Error("resource has not been properly initialized")
        return this._getTopJobSlotContentForPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/topJobSlots/all',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTopJobSlots: IResourceMethodObservableStrict<null, null, null, TopJobSlotData[]> | undefined
    getTopJobSlots(): Promise<TopJobSlotData[]> {
        if (!this._getTopJobSlots) throw new Error("resource has not been properly initialized")
        return this._getTopJobSlots(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/topJobSlotContent/{id}/contactImage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeContactImageFromSlot: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    removeContactImageFromSlot(id: number): Promise<null> {
        if (!this._removeContactImageFromSlot) throw new Error("resource has not been properly initialized")
        return this._removeContactImageFromSlot(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/topJobSlotContent/{id}/headerImage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeHeaderImageFromSlot: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    removeHeaderImageFromSlot(id: number): Promise<null> {
        if (!this._removeHeaderImageFromSlot) throw new Error("resource has not been properly initialized")
        return this._removeHeaderImageFromSlot(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/topJobSlot/content/change',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateSlotContent: IResourceMethodObservableStrict<SlotContentData, null, null, SlotContentData> | undefined
    updateSlotContent(requestBody: SlotContentData): Promise<SlotContentData> {
        if (!this._updateSlotContent) throw new Error("resource has not been properly initialized")
        return this._updateSlotContent(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/topJobSlot/{id}/position/change',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateSlotPosition: IResourceMethodObservableStrict<null, {positionId: number}, {id: number}, TopJobSlotData> | undefined
    updateSlotPosition(id: number, requestParams: {positionId: number}): Promise<TopJobSlotData> {
        if (!this._updateSlotPosition) throw new Error("resource has not been properly initialized")
        return this._updateSlotPosition(null, requestParams, {id: id}).toPromise()
    }

}