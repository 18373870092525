import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {CompanyData, AddressData, RecruiterCompanyUpdateData, CompanyRejectionTemplateData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyCompanyController
 */

@Injectable()
@ResourceParams({})
export class CompanyCompanyResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/rejectionTemplates',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deleteRejectionTemplate: IResourceMethodObservableStrict<null, {templateId: number}, null, null> | undefined
    deleteRejectionTemplate(requestParams: {templateId: number}): Promise<null> {
        if (!this._deleteRejectionTemplate) throw new Error("resource has not been properly initialized")
        return this._deleteRejectionTemplate(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/rejectionTemplates',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllRejectionTemplates: IResourceMethodObservableStrict<null, null, null, CompanyRejectionTemplateData[]> | undefined
    getAllRejectionTemplates(): Promise<CompanyRejectionTemplateData[]> {
        if (!this._getAllRejectionTemplates) throw new Error("resource has not been properly initialized")
        return this._getAllRejectionTemplates(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/address',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyAddress: IResourceMethodObservableStrict<null, null, null, AddressData> | undefined
    getCompanyAddress(): Promise<AddressData> {
        if (!this._getCompanyAddress) throw new Error("resource has not been properly initialized")
        return this._getCompanyAddress(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/company',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOwnCompanyDetails: IResourceMethodObservableStrict<null, null, null, CompanyData> | undefined
    getOwnCompanyDetails(): Promise<CompanyData> {
        if (!this._getOwnCompanyDetails) throw new Error("resource has not been properly initialized")
        return this._getOwnCompanyDetails(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/company',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateOwnCompanyDetails: IResourceMethodObservableStrict<RecruiterCompanyUpdateData, null, null, null> | undefined
    updateOwnCompanyDetails(requestBody: RecruiterCompanyUpdateData): Promise<null> {
        if (!this._updateOwnCompanyDetails) throw new Error("resource has not been properly initialized")
        return this._updateOwnCompanyDetails(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/rejectionTemplates',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateRejectionTemplate: IResourceMethodObservableStrict<CompanyRejectionTemplateData, null, null, CompanyRejectionTemplateData> | undefined
    updateRejectionTemplate(requestBody: CompanyRejectionTemplateData): Promise<CompanyRejectionTemplateData> {
        if (!this._updateRejectionTemplate) throw new Error("resource has not been properly initialized")
        return this._updateRejectionTemplate(requestBody, null, null).toPromise()
    }

}