import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrl: './share-dialog.component.scss'
})
export class ShareDialogComponent implements OnInit {

  encodedShareLink: string = ""

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShareDialogComponent>,
    private snackbar: MatSnackBar
  ) {
    this.encodedShareLink = encodeURIComponent(data.shareLink)
  }

  ngOnInit() {
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.data.shareLink)
    this.snackbar.open("Link in Zwischenablage kopiert", null, {duration: 1500})
    this.dialogRef.close()
  }

  close() {
    this.dialogRef.close()
  }


}
