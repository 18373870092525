import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-imprint',
  templateUrl: './legal-imprint.component.html',
  styleUrl: './legal-imprint.component.scss'
})
export class LegalImprintComponent {

}
