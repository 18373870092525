import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantHidEveController
 */

@Injectable()
@ResourceParams({})
export class ConsultantHidEveResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultants/shortInviteLink',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOrCreateShortenUrl: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<string>> | undefined
    getOrCreateShortenUrl(): Promise<string> {
        if (!this._getOrCreateShortenUrl) throw new Error("resource has not been properly initialized")
        return this._getOrCreateShortenUrl(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultants/company/shortInviteLink',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getShortenedCompanyInviteUrl: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<string>> | undefined
    getShortenedCompanyInviteUrl(): Promise<string> {
        if (!this._getShortenedCompanyInviteUrl) throw new Error("resource has not been properly initialized")
        return this._getShortenedCompanyInviteUrl(null, null, null).toPromise().then((result) => result.value)
    }

}