import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() {
  }

  public getGDPRConsentFromCookies() {
    if (!this.cookieExists('initial')) return null

    return {
      initial: this.getCookie('initial'),
      consent_ga: this.getCookie('consent_ga'),
      consent_linkedIn: this.getCookie('consent_linkedIn'),
      consent_pinterest: this.getCookie('consent_pinterest'),
      consent_facebook: this.getCookie('consent_facebook'),
      consent_tiktok: this.getCookie('consent_tiktok'),
        consent_piwik: this.getCookie('consent_piwik'),
        consent_hotjar: this.getCookie('consent_hotjar'),
        consent_leadfeeder: this.getCookie('consent_leadfeeder'),
      consent_google_ads: this.getCookie('consent_google_ads')
    }
  }


  public getCookie(name: string) {
    const cookieArray = document.cookie.split(';')
    const cookie = cookieArray.find(e => e.indexOf(name) > -1)

    if (cookie) return JSON.parse(cookie.split('=')[1])
    else return null
  }

  public cookieExists(name: string): boolean {
    const cookieArray = document.cookie.split(';')
    return !!cookieArray.find(e => e.indexOf(name) > -1)
  }
}
