<div class="width-100 flex column center gap-xl content-padding">

  <section class="flex column center">
    <p class="color-accent bold-text">Karriereguide</p>
    <h1 class="page-header center-text">Finde deinen Traumjob:</h1>
    <h1 class="page-header center-text"><span class="highlight highlight-show">Karriereguide</span> für</h1>
    <h1 class="page-header center-text">Absolventen</h1>
  </section>

  <section class="flex column center gap-lg" [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}">
    <h2 class="big-section-header center-text">Oft geklickte Blogartikel
    </h2>
    <div *ngIf="spotlightGuides?.length">
      <app-career-guide-spotlight [careerGuides]="spotlightGuides"></app-career-guide-spotlight>
    </div>
  </section>

  <section class="flex column center gap-lg">
    <h2 class="big-section-header center-text"> Weitere Blogartikel
    </h2>
    <div class="flex row center cap-width guides gap-lg"
         [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}">

      <div class="padding" *ngFor="let guide of otherGuides; let i = index" [ngClass]="{'delay-0-2s': i == 1, 'delay-0-4s': i == 2}">
        <app-career-guide-card  [careerGuide]="guide"></app-career-guide-card>
      </div>

    </div>
    <button class="accent-button" (click)="routeToAllGuides()">Alle anzeigen</button>
  </section>

</div>
