import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {PublicEventData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.public.PublicEventController
 */

@Injectable()
@ResourceParams({})
export class PublicEventResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/event/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEvent: IResourceMethodObservableStrict<null, null, {id: number}, PublicEventData> | undefined
    getEvent(id: number): Promise<PublicEventData> {
        if (!this._getEvent) throw new Error("resource has not been properly initialized")
        return this._getEvent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/event/{id}/headerImage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEventHeader: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getEventHeader(id: number): Promise<Blob> {
        if (!this._getEventHeader) throw new Error("resource has not been properly initialized")
        return this._getEventHeader(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/event/{id}/image/{imageId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEventImage: IResourceMethodObservableStrict<null, null, {id: number, imageId: number}, Blob> | undefined
    getEventImage(pathVariables: {id: number, imageId: number}): Promise<Blob> {
        if (!this._getEventImage) throw new Error("resource has not been properly initialized")
        return this._getEventImage(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/event/{id}/partnerImages/{imageId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPartnerLogo: IResourceMethodObservableStrict<null, null, {id: number, imageId: number}, Blob> | undefined
    getPartnerLogo(pathVariables: {id: number, imageId: number}): Promise<Blob> {
        if (!this._getPartnerLogo) throw new Error("resource has not been properly initialized")
        return this._getPartnerLogo(null, null, pathVariables).toPromise()
    }

}