import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {EmploymentPositionRecommendationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminPrimePositionRecommendationController
 */

@Injectable()
@ResourceParams({})
export class AdminPrimePositionRecommendationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/recommendations',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _createEmploymentPositionRecommendations: IResourceMethodObservableStrict<EmploymentPositionRecommendationData[], null, null, null> | undefined
    createEmploymentPositionRecommendations(requestBody: EmploymentPositionRecommendationData[]): Promise<null> {
        if (!this._createEmploymentPositionRecommendations) throw new Error("resource has not been properly initialized")
        return this._createEmploymentPositionRecommendations(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/talents/{id}/notReached',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendNotReachedEmailForTalent: IResourceMethodObservableStrict<null, {positionIds: number[]}, {id: number}, null> | undefined
    sendNotReachedEmailForTalent(id: number, requestParams: {positionIds: number[]}): Promise<null> {
        if (!this._sendNotReachedEmailForTalent) throw new Error("resource has not been properly initialized")
        return this._sendNotReachedEmailForTalent(null, requestParams, {id: id}).toPromise()
    }

}