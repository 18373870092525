import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {AdminSubjectGroupToFrequencyData, AdminPositionFeedPerformanceData, AdminSubjectGroupFeedPerformanceData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminFeedControlController
 */

@Injectable()
@ResourceParams({})
export class AdminFeedControlResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/feedcontrol/pushesByPosition',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFeedpushesAndPerformancesByPosition: IResourceMethodObservableStrict<null, {startDate: Date, endDate: Date, subjectGroupId?: number}, null, AdminPositionFeedPerformanceData[]> | undefined
    getFeedpushesAndPerformancesByPosition(requestParams: {startDate: Date, endDate: Date, subjectGroupId?: number}): Promise<AdminPositionFeedPerformanceData[]> {
        if (!this._getFeedpushesAndPerformancesByPosition) throw new Error("resource has not been properly initialized")
        return this._getFeedpushesAndPerformancesByPosition(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/feedcontrol/positions/{id}/bySubjectGroup',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionPerformanceBySubjectGroup: IResourceMethodObservableStrict<null, null, {id: number}, AdminSubjectGroupFeedPerformanceData[]> | undefined
    getPositionPerformanceBySubjectGroup(id: number): Promise<AdminSubjectGroupFeedPerformanceData[]> {
        if (!this._getPositionPerformanceBySubjectGroup) throw new Error("resource has not been properly initialized")
        return this._getPositionPerformanceBySubjectGroup(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/feedcontrol/subjectGroupsToFrequency',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSubjectGroupsByAmountOfRecentlyCreatedTalent: IResourceMethodObservableStrict<null, null, null, AdminSubjectGroupToFrequencyData[]> | undefined
    getSubjectGroupsByAmountOfRecentlyCreatedTalent(): Promise<AdminSubjectGroupToFrequencyData[]> {
        if (!this._getSubjectGroupsByAmountOfRecentlyCreatedTalent) throw new Error("resource has not been properly initialized")
        return this._getSubjectGroupsByAmountOfRecentlyCreatedTalent(null, null, null).toPromise()
    }

}