import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, TalentDeactivationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentAccountController
 */

@Injectable()
@ResourceParams({})
export class TalentAccountResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/account/changeEmail',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _changeEmail: IResourceMethodObservableStrict<null, {newEmail: string}, null, null> | undefined
    changeEmail(requestParams: {newEmail: string}): Promise<null> {
        if (!this._changeEmail) throw new Error("resource has not been properly initialized")
        return this._changeEmail(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/account/sbPromoClicked',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createTalentClickedStaufenbielPromotionLogEntryIfNecessary: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<null>> | undefined
    createTalentClickedStaufenbielPromotionLogEntryIfNecessary(): Promise<JsonWrappedValue<null>> {
        if (!this._createTalentClickedStaufenbielPromotionLogEntryIfNecessary) throw new Error("resource has not been properly initialized")
        return this._createTalentClickedStaufenbielPromotionLogEntryIfNecessary(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/account/dashboardVisited',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createTalentFirstVisitedDashboardLogEntryIfNecessary: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<null>> | undefined
    createTalentFirstVisitedDashboardLogEntryIfNecessary(): Promise<JsonWrappedValue<null>> {
        if (!this._createTalentFirstVisitedDashboardLogEntryIfNecessary) throw new Error("resource has not been properly initialized")
        return this._createTalentFirstVisitedDashboardLogEntryIfNecessary(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/account/feedVisited',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createTalentFirstVisitedFeedLogEntryIfNecessary: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<null>> | undefined
    createTalentFirstVisitedFeedLogEntryIfNecessary(): Promise<JsonWrappedValue<null>> {
        if (!this._createTalentFirstVisitedFeedLogEntryIfNecessary) throw new Error("resource has not been properly initialized")
        return this._createTalentFirstVisitedFeedLogEntryIfNecessary(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/account/platformVisited',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createTalentFirstVisitedPlatformLogEntryIfNecessary: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    createTalentFirstVisitedPlatformLogEntryIfNecessary(): Promise<JsonWrappedValue<boolean>> {
        if (!this._createTalentFirstVisitedPlatformLogEntryIfNecessary) throw new Error("resource has not been properly initialized")
        return this._createTalentFirstVisitedPlatformLogEntryIfNecessary(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/deactivate',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deactivateAccount: IResourceMethodObservableStrict<TalentDeactivationData, null, null, null> | undefined
    deactivateAccount(requestBody: TalentDeactivationData): Promise<null> {
        if (!this._deactivateAccount) throw new Error("resource has not been properly initialized")
        return this._deactivateAccount(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/account/consultantAppointmentDialogSeen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reportConsultantAppointmentDialogSeen: IResourceMethodObservableStrict<null, {coachingId: number}, null, null> | undefined
    reportConsultantAppointmentDialogSeen(requestParams: {coachingId: number}): Promise<null> {
        if (!this._reportConsultantAppointmentDialogSeen) throw new Error("resource has not been properly initialized")
        return this._reportConsultantAppointmentDialogSeen(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/account/welcomeDialogSeen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reportWelcomeDialogSeen: IResourceMethodObservableStrict<null, null, null, null> | undefined
    reportWelcomeDialogSeen(): Promise<null> {
        if (!this._reportWelcomeDialogSeen) throw new Error("resource has not been properly initialized")
        return this._reportWelcomeDialogSeen(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/account/openConsultantAppointmentDialog',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _shouldOpenConsultantAppointmentDialog: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    shouldOpenConsultantAppointmentDialog(): Promise<boolean> {
        if (!this._shouldOpenConsultantAppointmentDialog) throw new Error("resource has not been properly initialized")
        return this._shouldOpenConsultantAppointmentDialog(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/account/validatePhoneNumber',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _validatePhoneNumber: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    validatePhoneNumber(): Promise<JsonWrappedValue<boolean>> {
        if (!this._validatePhoneNumber) throw new Error("resource has not been properly initialized")
        return this._validatePhoneNumber(null, null, null).toPromise()
    }

}