import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CareerGuideComponent} from "./career-guide.component";
import {CareerGuideSpotlightComponent} from "./career-guide-spotlight/career-guide-spotlight.component";
import {CareerGuideCardComponent} from "./career-guide-card/career-guide-card.component";
import {RouterModule} from "@angular/router";
import {CareerGuideDetailComponent} from "./career-guide-detail/career-guide-detail.component";
import {PublicCareerArticleResource} from "../../../generated/hid/resources";
import {UtilityModule} from "../../utility/utility.module";
import {
  CareerGuideOnboardingCatcherComponent
} from "./career-guide-onboarding-catcher/career-guide-onboarding-catcher.component";
import {CareerGuideAllComponent} from "./career-guide-all/career-guide-all.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@NgModule({
  declarations: [
    CareerGuideComponent,
    CareerGuideSpotlightComponent,
    CareerGuideCardComponent,
    CareerGuideDetailComponent,
    CareerGuideOnboardingCatcherComponent,
    CareerGuideAllComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UtilityModule,
    MatProgressBarModule
  ],
  exports: [
    CareerGuideCardComponent
  ],
  providers: [
    PublicCareerArticleResource
  ]
})
export class CareerGuideModule { }
