import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, EventFilterData, AdminTalentData, AdminEventData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminEventController
 */

@Injectable()
@ResourceParams({})
export class AdminEventResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/events/{id}/headerImage',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _addHeaderImageToEvent: IResourceMethodObservableStrict<{fileName: string, file: File}, null, {id: number}, JsonWrappedValue<number>> | undefined
    addHeaderImageToEvent(requestBody: {fileName: string, file: File}, id: number): Promise<number> {
        if (!this._addHeaderImageToEvent) throw new Error("resource has not been properly initialized")
        return this._addHeaderImageToEvent(requestBody, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/events/{id}/addImage',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _addImageToEvent: IResourceMethodObservableStrict<{fileName: string, file: File}, null, {id: number}, JsonWrappedValue<number>> | undefined
    addImageToEvent(requestBody: {fileName: string, file: File}, id: number): Promise<number> {
        if (!this._addImageToEvent) throw new Error("resource has not been properly initialized")
        return this._addImageToEvent(requestBody, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/events/{id}/addPartnerLogo',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _addPartnerImageToEvent: IResourceMethodObservableStrict<{fileName: string, file: File}, null, {id: number}, JsonWrappedValue<number>> | undefined
    addPartnerImageToEvent(requestBody: {fileName: string, file: File}, id: number): Promise<number> {
        if (!this._addPartnerImageToEvent) throw new Error("resource has not been properly initialized")
        return this._addPartnerImageToEvent(requestBody, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/event/{id}/filter/createCampaign',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createMarketingCampaignFromEventFilter: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    createMarketingCampaignFromEventFilter(id: number): Promise<null> {
        if (!this._createMarketingCampaignFromEventFilter) throw new Error("resource has not been properly initialized")
        return this._createMarketingCampaignFromEventFilter(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/events/createOrUpdateEvent',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateEvent: IResourceMethodObservableStrict<AdminEventData, null, null, null> | undefined
    createOrUpdateEvent(requestBody: AdminEventData): Promise<null> {
        if (!this._createOrUpdateEvent) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateEvent(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/events/{id}/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEventFilterForEvent: IResourceMethodObservableStrict<null, null, {id: number}, EventFilterData> | undefined
    getEventFilterForEvent(id: number): Promise<EventFilterData> {
        if (!this._getEventFilterForEvent) throw new Error("resource has not been properly initialized")
        return this._getEventFilterForEvent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/event/{id}/filter/campaign/lastTriggered',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLastTriggeredDateForEvent: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<Date>> | undefined
    getLastTriggeredDateForEvent(id: number): Promise<JsonWrappedValue<Date>> {
        if (!this._getLastTriggeredDateForEvent) throw new Error("resource has not been properly initialized")
        return this._getLastTriggeredDateForEvent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/events/normal',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getNormalEvents: IResourceMethodObservableStrict<null, null, null, AdminEventData[]> | undefined
    getNormalEvents(): Promise<AdminEventData[]> {
        if (!this._getNormalEvents) throw new Error("resource has not been properly initialized")
        return this._getNormalEvents(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/events/perfectMatch',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPerfectMatchEvents: IResourceMethodObservableStrict<null, null, null, AdminEventData[]> | undefined
    getPerfectMatchEvents(): Promise<AdminEventData[]> {
        if (!this._getPerfectMatchEvents) throw new Error("resource has not been properly initialized")
        return this._getPerfectMatchEvents(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/event/{id}/filter/talents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentsByEventFilter: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, {id: number}, PagedData<AdminTalentData>> | undefined
    getTalentsByEventFilter(id: number, requestParams: {page: number, pageSize: number}): Promise<PagedData<AdminTalentData>> {
        if (!this._getTalentsByEventFilter) throw new Error("resource has not been properly initialized")
        return this._getTalentsByEventFilter(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/event/{id}/filter/talents/excel',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentsByEventFilterExcel: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getTalentsByEventFilterExcel(id: number): Promise<Blob> {
        if (!this._getTalentsByEventFilterExcel) throw new Error("resource has not been properly initialized")
        return this._getTalentsByEventFilterExcel(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/event/{id}/registrations/excel',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentsByEventRegistrationExcel: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getTalentsByEventRegistrationExcel(id: number): Promise<Blob> {
        if (!this._getTalentsByEventRegistrationExcel) throw new Error("resource has not been properly initialized")
        return this._getTalentsByEventRegistrationExcel(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/events/{id}/headerImage/{fileId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeHeaderImageFromEvent: IResourceMethodObservableStrict<null, null, {id: number, fileId: number}, null> | undefined
    removeHeaderImageFromEvent(pathVariables: {id: number, fileId: number}): Promise<null> {
        if (!this._removeHeaderImageFromEvent) throw new Error("resource has not been properly initialized")
        return this._removeHeaderImageFromEvent(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/events/{id}/removeImage/{fileId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeImageFromEvent: IResourceMethodObservableStrict<null, null, {id: number, fileId: number}, null> | undefined
    removeImageFromEvent(pathVariables: {id: number, fileId: number}): Promise<null> {
        if (!this._removeImageFromEvent) throw new Error("resource has not been properly initialized")
        return this._removeImageFromEvent(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/events/{id}/removePartnerLogo/{fileId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removePartnerImageFromEvent: IResourceMethodObservableStrict<null, null, {id: number, fileId: number}, null> | undefined
    removePartnerImageFromEvent(pathVariables: {id: number, fileId: number}): Promise<null> {
        if (!this._removePartnerImageFromEvent) throw new Error("resource has not been properly initialized")
        return this._removePartnerImageFromEvent(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/events/{id}/updateFilter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateEventFilter: IResourceMethodObservableStrict<EventFilterData, null, {id: number}, null> | undefined
    updateEventFilter(requestBody: EventFilterData, id: number): Promise<null> {
        if (!this._updateEventFilter) throw new Error("resource has not been properly initialized")
        return this._updateEventFilter(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/events/perfectMatch/update',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updatePerfectMatchEvents: IResourceMethodObservableStrict<null, null, null, AdminEventData[]> | undefined
    updatePerfectMatchEvents(): Promise<AdminEventData[]> {
        if (!this._updatePerfectMatchEvents) throw new Error("resource has not been properly initialized")
        return this._updatePerfectMatchEvents(null, null, null).toPromise()
    }

}