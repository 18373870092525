import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, ChatMinimalData, ChatLatestChatsData, ChatMessageData, ChatData, PagedData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyChatController
 */

@Injectable()
@ResourceParams({})
export class CompanyChatResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/chat/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getChatForOverview: IResourceMethodObservableStrict<null, null, {id: number}, ChatData> | undefined
    getChatForOverview(id: number): Promise<ChatData> {
        if (!this._getChatForOverview) throw new Error("resource has not been properly initialized")
        return this._getChatForOverview(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/talents/{talentId}/chat',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getChatForTalent: IResourceMethodObservableStrict<null, null, {talentId: number}, ChatData> | undefined
    getChatForTalent(talentId: number): Promise<ChatData> {
        if (!this._getChatForTalent) throw new Error("resource has not been properly initialized")
        return this._getChatForTalent(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/chat/latest',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLatestChats: IResourceMethodObservableStrict<null, {pageSize: number}, null, PagedData<ChatLatestChatsData>> | undefined
    getLatestChats(requestParams: {pageSize: number}): Promise<PagedData<ChatLatestChatsData>> {
        if (!this._getLatestChats) throw new Error("resource has not been properly initialized")
        return this._getLatestChats(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/chat/{messageId}/status',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMessageStatus: IResourceMethodObservableStrict<null, null, {messageId: number}, JsonWrappedValue<boolean>> | undefined
    getMessageStatus(messageId: number): Promise<boolean> {
        if (!this._getMessageStatus) throw new Error("resource has not been properly initialized")
        return this._getMessageStatus(null, null, {messageId: messageId}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/chat',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMinimalChats: IResourceMethodObservableStrict<null, {chatFilter: ('OnlyMine' | 'All' | 'AllAssigned')}, null, ChatMinimalData[]> | undefined
    getMinimalChats(requestParams: {chatFilter: ('OnlyMine' | 'All' | 'AllAssigned')}): Promise<ChatMinimalData[]> {
        if (!this._getMinimalChats) throw new Error("resource has not been properly initialized")
        return this._getMinimalChats(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/talents/{talentId}/chat/recruiters',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRecruitersOfExistingChatsWithTalent: IResourceMethodObservableStrict<null, null, {talentId: number}, LabelData[]> | undefined
    getRecruitersOfExistingChatsWithTalent(talentId: number): Promise<LabelData[]> {
        if (!this._getRecruitersOfExistingChatsWithTalent) throw new Error("resource has not been properly initialized")
        return this._getRecruitersOfExistingChatsWithTalent(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/chat/unseen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUnseenMessageCount: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getUnseenMessageCount(): Promise<JsonWrappedValue<number>> {
        if (!this._getUnseenMessageCount) throw new Error("resource has not been properly initialized")
        return this._getUnseenMessageCount(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/chat/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendChatMessageOrCreateChat: IResourceMethodObservableStrict<null, {message: string, chatId: number}, {id: number}, ChatMessageData> | undefined
    sendChatMessageOrCreateChat(id: number, requestParams: {message: string, chatId: number}): Promise<ChatMessageData> {
        if (!this._sendChatMessageOrCreateChat) throw new Error("resource has not been properly initialized")
        return this._sendChatMessageOrCreateChat(null, requestParams, {id: id}).toPromise()
    }

}