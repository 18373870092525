import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PublicCareerArticleResource} from "../../../generated/hid/resources";
import {CareerArticleData} from "../../../generated/hid/data";
import {MediaService} from "../../utility/services/media.service";
import {AnimationsService} from "../../utility/services/animations.service";
import {Router} from "@angular/router";
import {AnalyticsService} from "../../utility/services/gdprconsent/analytics.service";

@Component({
  selector: 'app-career-guide',
  templateUrl: './career-guide.component.html',
  styleUrl: './career-guide.component.scss'
})
export class CareerGuideComponent implements OnInit{

  spotlightGuides: CareerArticleData[] = []
  otherGuides: CareerArticleData[] = []

  constructor(
    private guideResource: PublicCareerArticleResource,
    public mediaService: MediaService,
    private router: Router,
    private animationsService: AnimationsService,
    private analyticsService: AnalyticsService
  ) {
  }

  ngOnInit() {
    this.handleScrollAnimations()
    this.loadCareerGuides()
  }

  loadCareerGuides() {
    this.guideResource.getAllArticles({page: 0, pageSize: 6}).then(result => {
      this.spotlightGuides = result.content.slice(0, 3)
      this.otherGuides = result.content.slice(-3)
    })
  }

  routeToAllGuides() {
    this.router.navigateByUrl('/karriereguide/all')
  }

  handleScrollAnimations() {
    this.animationsService.handleOnScrollAnimation('.guides', 'jobSlide', false)
  }



}
