import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {ConsultantNotificationConfigurationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantNotificationConfigurationController
 */

@Injectable()
@ResourceParams({})
export class ConsultantNotificationConfigurationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/notifications/configuration',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getConsultantNotificationConfiguration: IResourceMethodObservableStrict<null, null, null, ConsultantNotificationConfigurationData> | undefined
    getConsultantNotificationConfiguration(): Promise<ConsultantNotificationConfigurationData> {
        if (!this._getConsultantNotificationConfiguration) throw new Error("resource has not been properly initialized")
        return this._getConsultantNotificationConfiguration(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/consultant/notifications/configuration',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateConsultantNotificationConfiguration: IResourceMethodObservableStrict<ConsultantNotificationConfigurationData, null, null, null> | undefined
    updateConsultantNotificationConfiguration(requestBody: ConsultantNotificationConfigurationData): Promise<null> {
        if (!this._updateConsultantNotificationConfiguration) throw new Error("resource has not been properly initialized")
        return this._updateConsultantNotificationConfiguration(requestBody, null, null).toPromise()
    }

}