<div class="content-padding flex column center gap-lg">

  <section class="flex column center gap-lg content-width width-100" [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile()}">
    <h1 class="page-header">{{ webinarData.title }}</h1>
    <img class="banner-image" [src]="webinarImage" alt="">
  </section>

  <section class="flex start gap-lg content-width width-100"
           [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}">

    <div class="flex column start gap-lg"
         [ngClass]="{'width-66': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}">
      <div class="flex column gap width-100">
        <h3>Event Details</h3>
        <div class="flex row gap-lg event-details">

          <div class="flex row start-center gap">
            <i class="mdi mdi-calendar color-accent large-text"></i>
            <div class="bold-text">{{ webinarData.dateTime | date: 'dd.MM.yyyy'}}</div>
          </div>
          <div class="flex row start-center gap">
            <i class="mdi mdi-clock color-accent large-text"></i>
            <div class="bold-text">{{ webinarData.dateTime | date: 'hh:mm'}}</div>
          </div>

        </div>
      </div>

      <div class="flex column gap-lg">
        <h3>Über das Event</h3>
        <div class="about-text">
          {{webinarData.description}}
        </div>
      </div>

      <div class="flex column gap-lg">
        <h3>Speakers <span>({{webinarData?.speakers?.length}})</span></h3>
        <div class="flex row start gap-lg">

          <div class="flex row wrap start-center gap-lg speakers">
            <div *ngFor="let speaker of webinarData.speakers" class="flex row start-center gap-sm">
              <div class="speaker-image">
                {{speaker.name[0]}}
              </div>

              <div>
                {{ speaker.name }}
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="register-container flex column center gap-lg"
         [ngClass]="{'width-33': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}">
      <h3>Melde dich hier direkt zum Webinar an</h3>
      <button class="accent-button" (click)="registerForWebinar()">Jetzt anmelden</button>
    </div>


  </section>
</div>
