import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {TalentNotificationConfigurationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentNotificationConfigurationController
 */

@Injectable()
@ResourceParams({})
export class TalentNotificationConfigurationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/notifications/configuration',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUserNotificationConfiguration: IResourceMethodObservableStrict<null, null, null, TalentNotificationConfigurationData> | undefined
    getUserNotificationConfiguration(): Promise<TalentNotificationConfigurationData> {
        if (!this._getUserNotificationConfiguration) throw new Error("resource has not been properly initialized")
        return this._getUserNotificationConfiguration(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/notifications/configuration',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateUserNotificationConfiguration: IResourceMethodObservableStrict<TalentNotificationConfigurationData, null, null, null> | undefined
    updateUserNotificationConfiguration(requestBody: TalentNotificationConfigurationData): Promise<null> {
        if (!this._updateUserNotificationConfiguration) throw new Error("resource has not been properly initialized")
        return this._updateUserNotificationConfiguration(requestBody, null, null).toPromise()
    }

}