import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {CompanyRecallData} from "../../../generated/data";
import {DemodeskService} from "../demo-booking/demodesk.service";
import {AnalyticsService} from "../../utility/services/gdprconsent/analytics.service";
import {PublicLandingPageResource} from "../../../generated/resources";
import {MediaService} from "../../utility/services/media.service";
import {ActivatedRoute, Params} from "@angular/router";

@Component({
  selector: 'contact-formular',
  templateUrl: './contact-formular.component.html',
  styleUrl: './contact-formular.component.scss'
})
export class ContactFormularComponent implements OnInit{

    environment = environment
    referringConsultantToken : string | null = null

    constructor(
        private demodeskService: DemodeskService,
        private analyticsService: AnalyticsService,
        public mediaService: MediaService,
        private publicLandingPageResource: PublicLandingPageResource,
        private route: ActivatedRoute
    ) {
    }
    ngOnInit(): void {
        this.initContactForm()
      this.route.queryParams.subscribe((params: Params) => {
        this.referringConsultantToken = params['agent'];
      })
    }

    showRecallRequestSend: boolean = false

    refererOptions: string[] = [
        "Talentagent wurde mir empfohlen",
        "Events / Messen",
        "Soziale Medien (z.B. Instagram, Facebook, LinkedIn)",
        "Browsersuche",
        "Ich habe die Plattform schon mal genutzt",
        "Sonstiges"
    ]

    submitted: boolean = false
    contactGroup: FormGroup

    getUseRegulationUrl():string {
        return environment.companyFrontendUrl + '/agb'
    }

    getPrivacyUrl():string {
        return environment.companyFrontendUrl + '/privacy'
    }

    initContactForm() {
        this.contactGroup = new FormGroup({
            firstName: new FormControl('', [Validators.required, Validators.minLength(2)]),
            lastName: new FormControl('', [Validators.required, Validators.minLength(2)]),
            email: new FormControl('', [Validators.required, Validators.email]),
            companyName: new FormControl('', []),
            optIn: new FormControl(false, [Validators.required, Validators.requiredTrue]),
            talentCount: new FormControl('', [Validators.min(0)]),
            referer: new FormControl(''),
            phone: new FormControl('', [Validators.required, Validators.minLength(6),
                Validators.pattern('[^a-zA-Z]*')]
            )
        });
    }

    requestDemoCall() {
        this.submitted = true
        this.contactGroup.markAllAsTouched()
        this.contactGroup.updateValueAndValidity()
        if (this.contactGroup.invalid) return

        if (this.demodeskService.context === "") this.demodeskService.context = "CompanyOrganicCta"

        this.showRecallRequestSend = true
        this.analyticsService.reportRequestDemoCta(this.demodeskService.context, this.contactGroup.controls['email'].value)

        let data = <CompanyRecallData>{
            companyName: this.contactGroup.controls['companyName'].value,
            emailAddress: this.contactGroup.controls['email'].value,
            firstName: this.contactGroup.controls['firstName'].value,
            lastName: this.contactGroup.controls['lastName'].value,
            message: "Wie bist du auf uns aufmerksam geworden?: " + this.contactGroup.controls['referer'].value,
            phoneNumber: this.contactGroup.controls['phone'].value,
            product: this.demodeskService.context,
            referringConsultantToken: this.referringConsultantToken,
            talentCount: this.contactGroup.controls['talentCount'].value
        }
        this.publicLandingPageResource.sendCompanyRecallRequest(data)
    }

}
