import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, AdminRoleData, AdminData, AdminDetailedData, AdminRoleDisplayData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminAdminController
 */

@Injectable()
@ResourceParams({})
export class AdminAdminResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admins',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createAdmin: IResourceMethodObservableStrict<AdminDetailedData, null, null, AdminData> | undefined
    createAdmin(requestBody: AdminDetailedData): Promise<AdminData> {
        if (!this._createAdmin) throw new Error("resource has not been properly initialized")
        return this._createAdmin(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admins/{adminId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deleteAdmin: IResourceMethodObservableStrict<null, null, {adminId: number}, null> | undefined
    deleteAdmin(adminId: number): Promise<null> {
        if (!this._deleteAdmin) throw new Error("resource has not been properly initialized")
        return this._deleteAdmin(null, null, {adminId: adminId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admins/{id}/role',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deleteAdminRole: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, {id: number}, JsonWrappedValue<boolean>> | undefined
    deleteAdminRole(requestBody: JsonWrappedValue<string>, id: number): Promise<JsonWrappedValue<boolean>> {
        if (!this._deleteAdminRole) throw new Error("resource has not been properly initialized")
        return this._deleteAdminRole(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admins/{adminId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAdmin: IResourceMethodObservableStrict<null, null, {adminId: number}, AdminDetailedData> | undefined
    getAdmin(adminId: number): Promise<AdminDetailedData> {
        if (!this._getAdmin) throw new Error("resource has not been properly initialized")
        return this._getAdmin(null, null, {adminId: adminId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admins/{adminId}/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAdminPicture: IResourceMethodObservableStrict<null, null, {adminId: number}, Blob> | undefined
    getAdminPicture(adminId: number): Promise<Blob> {
        if (!this._getAdminPicture) throw new Error("resource has not been properly initialized")
        return this._getAdminPicture(null, null, {adminId: adminId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admins/{id}/roles',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAdminRoles: IResourceMethodObservableStrict<null, null, {id: number}, AdminRoleData[]> | undefined
    getAdminRoles(id: number): Promise<AdminRoleData[]> {
        if (!this._getAdminRoles) throw new Error("resource has not been properly initialized")
        return this._getAdminRoles(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admins',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllAdmins: IResourceMethodObservableStrict<null, null, null, AdminData[]> | undefined
    getAllAdmins(): Promise<AdminData[]> {
        if (!this._getAllAdmins) throw new Error("resource has not been properly initialized")
        return this._getAllAdmins(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admins/roles/keyaccounter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllKeyAccounter: IResourceMethodObservableStrict<null, null, null, AdminRoleDisplayData[]> | undefined
    getAllKeyAccounter(): Promise<AdminRoleDisplayData[]> {
        if (!this._getAllKeyAccounter) throw new Error("resource has not been properly initialized")
        return this._getAllKeyAccounter(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admins/{adminId}/keyAccounter/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getKeyAccounterProfilePicture: IResourceMethodObservableStrict<null, null, {adminId: number}, Blob> | undefined
    getKeyAccounterProfilePicture(adminId: number): Promise<Blob> {
        if (!this._getKeyAccounterProfilePicture) throw new Error("resource has not been properly initialized")
        return this._getKeyAccounterProfilePicture(null, null, {adminId: adminId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admins/{id}/role',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _setAdminRole: IResourceMethodObservableStrict<JsonWrappedValue<string>, null, {id: number}, null> | undefined
    setAdminRole(requestBody: JsonWrappedValue<string>, id: number): Promise<null> {
        if (!this._setAdminRole) throw new Error("resource has not been properly initialized")
        return this._setAdminRole(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admins/{id}/accountInfo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateAdminAccountInfo: IResourceMethodObservableStrict<AdminDetailedData, null, {id: number}, null> | undefined
    updateAdminAccountInfo(requestBody: AdminDetailedData, id: number): Promise<null> {
        if (!this._updateAdminAccountInfo) throw new Error("resource has not been properly initialized")
        return this._updateAdminAccountInfo(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admins/{id}/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateAdminProfilePicture: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, {id: number}, null> | undefined
    updateAdminProfilePicture(requestBody: ArrayBuffer, id: number, requestParams: {fileName: string}): Promise<null> {
        if (!this._updateAdminProfilePicture) throw new Error("resource has not been properly initialized")
        return this._updateAdminProfilePicture(requestBody, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admins/{id}/role',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateAdminRole: IResourceMethodObservableStrict<AdminRoleData, null, {id: number}, null> | undefined
    updateAdminRole(requestBody: AdminRoleData, id: number): Promise<null> {
        if (!this._updateAdminRole) throw new Error("resource has not been properly initialized")
        return this._updateAdminRole(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admins/{id}/keyAccounter/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateKeyAccounterProfilePicture: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, {id: number}, null> | undefined
    updateKeyAccounterProfilePicture(requestBody: ArrayBuffer, id: number, requestParams: {fileName: string}): Promise<null> {
        if (!this._updateKeyAccounterProfilePicture) throw new Error("resource has not been properly initialized")
        return this._updateKeyAccounterProfilePicture(requestBody, requestParams, {id: id}).toPromise()
    }

}