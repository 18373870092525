import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {ConsultantPositionRecommendationInfoData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantPrimeRecommendationController
 */

@Injectable()
@ResourceParams({})
export class ConsultantPrimeRecommendationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/positions/mlp',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentMlpPositionRecommendationInfo: IResourceMethodObservableStrict<null, null, {id: number}, ConsultantPositionRecommendationInfoData> | undefined
    getTalentMlpPositionRecommendationInfo(id: number): Promise<ConsultantPositionRecommendationInfoData> {
        if (!this._getTalentMlpPositionRecommendationInfo) throw new Error("resource has not been properly initialized")
        return this._getTalentMlpPositionRecommendationInfo(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultant/recommendation/position/{positionId}/talent/{talentId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _recommendPositionForTalent: IResourceMethodObservableStrict<string, null, {positionId: number, talentId: number}, null> | undefined
    recommendPositionForTalent(requestBody: string, pathVariables: {positionId: number, talentId: number}): Promise<null> {
        if (!this._recommendPositionForTalent) throw new Error("resource has not been properly initialized")
        return this._recommendPositionForTalent(requestBody, null, pathVariables).toPromise()
    }

}