import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {CompleteTalentProfileData, AccountInfoData, LanguageStateData, CVInfoData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminTalentProfileController
 */

@Injectable()
@ResourceParams({})
export class AdminTalentProfileResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/talents/{talentId}/profile/languages',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateLanguageState: IResourceMethodObservableStrict<LanguageStateData, null, {talentId: number}, LanguageStateData> | undefined
    createOrUpdateLanguageState(requestBody: LanguageStateData, talentId: number): Promise<LanguageStateData> {
        if (!this._createOrUpdateLanguageState) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateLanguageState(requestBody, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talentProfile/CurriculumVitae/{talentId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCVForAdmin: IResourceMethodObservableStrict<null, null, {talentId: number}, Blob> | undefined
    getCVForAdmin(talentId: number): Promise<Blob> {
        if (!this._getCVForAdmin) throw new Error("resource has not been properly initialized")
        return this._getCVForAdmin(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/talents/profile/CurriculumVitae/info/{talentId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCVInfo: IResourceMethodObservableStrict<null, null, {talentId: number}, CVInfoData> | undefined
    getCVInfo(talentId: number): Promise<CVInfoData> {
        if (!this._getCVInfo) throw new Error("resource has not been properly initialized")
        return this._getCVInfo(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talents/{talentId}/profileinfo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentAccountInfo: IResourceMethodObservableStrict<null, null, {talentId: number}, AccountInfoData> | undefined
    getTalentAccountInfo(talentId: number): Promise<AccountInfoData> {
        if (!this._getTalentAccountInfo) throw new Error("resource has not been properly initialized")
        return this._getTalentAccountInfo(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/applications/{id}/dossier',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentApplicationDossier: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getTalentApplicationDossier(id: number): Promise<Blob> {
        if (!this._getTalentApplicationDossier) throw new Error("resource has not been properly initialized")
        return this._getTalentApplicationDossier(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/talents/{id}/dossier',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentDossier: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getTalentDossier(id: number): Promise<Blob> {
        if (!this._getTalentDossier) throw new Error("resource has not been properly initialized")
        return this._getTalentDossier(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/talents/{talentId}/profile/languages/{languageId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeLanguageState: IResourceMethodObservableStrict<null, null, {talentId: number, languageId: number}, null> | undefined
    removeLanguageState(pathVariables: {talentId: number, languageId: number}): Promise<null> {
        if (!this._removeLanguageState) throw new Error("resource has not been properly initialized")
        return this._removeLanguageState(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/talents/profiles/{profileId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateTalentProfile: IResourceMethodObservableStrict<CompleteTalentProfileData, null, {profileId: number}, null> | undefined
    updateTalentProfile(requestBody: CompleteTalentProfileData, profileId: number): Promise<null> {
        if (!this._updateTalentProfile) throw new Error("resource has not been properly initialized")
        return this._updateTalentProfile(requestBody, null, {profileId: profileId}).toPromise()
    }

}