import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {CareerEventData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminCareerEventController
 */

@Injectable()
@ResourceParams({})
export class AdminCareerEventResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/talents/{id}/careerEvents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentCareerEvents: IResourceMethodObservableStrict<null, null, {id: number}, CareerEventData[]> | undefined
    getTalentCareerEvents(id: number): Promise<CareerEventData[]> {
        if (!this._getTalentCareerEvents) throw new Error("resource has not been properly initialized")
        return this._getTalentCareerEvents(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/talents/{talentId}/profile/careerEvents/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeCareerEvent: IResourceMethodObservableStrict<null, null, {talentId: number, id: number}, null> | undefined
    removeCareerEvent(pathVariables: {talentId: number, id: number}): Promise<null> {
        if (!this._removeCareerEvent) throw new Error("resource has not been properly initialized")
        return this._removeCareerEvent(null, null, pathVariables).toPromise()
    }

}