import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {UniversityData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.UniversityController
 */

@Injectable()
@ResourceParams({})
export class UniversityResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/studyInfo/universities',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createUniversity: IResourceMethodObservableStrict<UniversityData, null, null, UniversityData> | undefined
    createUniversity(requestBody: UniversityData): Promise<UniversityData> {
        if (!this._createUniversity) throw new Error("resource has not been properly initialized")
        return this._createUniversity(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/api/universities/{cronosId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _editUniversityFromCronos: IResourceMethodObservableStrict<UniversityData, null, {cronosId: number}, null> | undefined
    editUniversityFromCronos(requestBody: UniversityData, cronosId: number): Promise<null> {
        if (!this._editUniversityFromCronos) throw new Error("resource has not been properly initialized")
        return this._editUniversityFromCronos(requestBody, null, {cronosId: cronosId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/studyInfo/universities',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUniversitiesByQuery: IResourceMethodObservableStrict<null, {q?: string, cityId?: number, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<UniversityData>> | undefined
    getUniversitiesByQuery(requestParams?: {q?: string, cityId?: number, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<UniversityData>> {
        if (!this._getUniversitiesByQuery) throw new Error("resource has not been properly initialized")
        return this._getUniversitiesByQuery(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/studyInfo/university/{universityId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUniversity: IResourceMethodObservableStrict<null, null, {universityId: number}, UniversityData> | undefined
    getUniversity(universityId: number): Promise<UniversityData> {
        if (!this._getUniversity) throw new Error("resource has not been properly initialized")
        return this._getUniversity(null, null, {universityId: universityId}).toPromise()
    }

}