import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {PositionContentSplitData, UpdatePositionContentSplitData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminPositionContentSplitController
 */

@Injectable()
@ResourceParams({})
export class AdminPositionContentSplitResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/contentSplits/{id}/picture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addPositionSplitPicture: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, {id: number}, LabelData> | undefined
    addPositionSplitPicture(requestBody: ArrayBuffer, id: number, requestParams: {fileName: string}): Promise<LabelData> {
        if (!this._addPositionSplitPicture) throw new Error("resource has not been properly initialized")
        return this._addPositionSplitPicture(requestBody, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/contentSplits/{id}/changeState',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _changePositionContentSplitState: IResourceMethodObservableStrict<null, {newState: boolean}, {id: number}, null> | undefined
    changePositionContentSplitState(id: number, requestParams: {newState: boolean}): Promise<null> {
        if (!this._changePositionContentSplitState) throw new Error("resource has not been properly initialized")
        return this._changePositionContentSplitState(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/contentSplits/{id}/copy',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _copyContentSplit: IResourceMethodObservableStrict<null, null, {id: number}, PositionContentSplitData> | undefined
    copyContentSplit(id: number): Promise<PositionContentSplitData> {
        if (!this._copyContentSplit) throw new Error("resource has not been properly initialized")
        return this._copyContentSplit(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/contentSplits/create',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createNewContentSplit: IResourceMethodObservableStrict<null, {positionId: number}, null, PositionContentSplitData> | undefined
    createNewContentSplit(requestParams: {positionId: number}): Promise<PositionContentSplitData> {
        if (!this._createNewContentSplit) throw new Error("resource has not been properly initialized")
        return this._createNewContentSplit(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/contentSplits/{id}/delete',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deletePositionContentSplit: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    deletePositionContentSplit(id: number): Promise<null> {
        if (!this._deletePositionContentSplit) throw new Error("resource has not been properly initialized")
        return this._deletePositionContentSplit(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/contentSplits/{id}/pictures/{fileId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deletePositionSplitPicture: IResourceMethodObservableStrict<null, null, {id: number, fileId: number}, null> | undefined
    deletePositionSplitPicture(pathVariables: {id: number, fileId: number}): Promise<null> {
        if (!this._deletePositionSplitPicture) throw new Error("resource has not been properly initialized")
        return this._deletePositionSplitPicture(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/contentSplits/{id}/picture/{pictureId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionSplitPicture: IResourceMethodObservableStrict<null, null, {id: number, pictureId: number}, Blob> | undefined
    getPositionSplitPicture(pathVariables: {id: number, pictureId: number}): Promise<Blob> {
        if (!this._getPositionSplitPicture) throw new Error("resource has not been properly initialized")
        return this._getPositionSplitPicture(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/contentSplits/{id}/update',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updatePositionSplit: IResourceMethodObservableStrict<UpdatePositionContentSplitData, null, {id: number}, null> | undefined
    updatePositionSplit(requestBody: UpdatePositionContentSplitData, id: number): Promise<null> {
        if (!this._updatePositionSplit) throw new Error("resource has not been properly initialized")
        return this._updatePositionSplit(requestBody, null, {id: id}).toPromise()
    }

}