import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantAccountController
 */

@Injectable()
@ResourceParams({})
export class ConsultantAccountResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultant/privacy-agreement',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _acceptPrivacyAgreement: IResourceMethodObservableStrict<null, null, null, null> | undefined
    acceptPrivacyAgreement(): Promise<null> {
        if (!this._acceptPrivacyAgreement) throw new Error("resource has not been properly initialized")
        return this._acceptPrivacyAgreement(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultant/onboardingCompleted',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _completedOnboarding: IResourceMethodObservableStrict<null, null, null, null> | undefined
    completedOnboarding(): Promise<null> {
        if (!this._completedOnboarding) throw new Error("resource has not been properly initialized")
        return this._completedOnboarding(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/privacy-agreement',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _hasConsultantAcceptedPrivacyAgreement: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    hasConsultantAcceptedPrivacyAgreement(): Promise<JsonWrappedValue<boolean>> {
        if (!this._hasConsultantAcceptedPrivacyAgreement) throw new Error("resource has not been properly initialized")
        return this._hasConsultantAcceptedPrivacyAgreement(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/onboardingCompleted',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _hasConsultantOnboardingCompleted: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    hasConsultantOnboardingCompleted(): Promise<JsonWrappedValue<boolean>> {
        if (!this._hasConsultantOnboardingCompleted) throw new Error("resource has not been properly initialized")
        return this._hasConsultantOnboardingCompleted(null, null, null).toPromise()
    }

}