import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicGlobalConfigController
 */

@Injectable()
@ResourceParams({})
export class PublicGlobalConfigResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/config/global/css',
        endpoint: 'net.innospire.hidcms'
    })
    private _getGlobalCss: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<string>> | undefined
    getGlobalCss(): Promise<JsonWrappedValue<string>> {
        if (!this._getGlobalCss) throw new Error("resource has not been properly initialized")
        return this._getGlobalCss(null, null, null).toPromise()
    }

}