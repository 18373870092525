import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {TopJobSlotData, SlotContentData, SlotSettingsData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminTopJobController
 */

@Injectable()
@ResourceParams({})
export class AdminTopJobResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/companies/{id}/topJobSlot/new',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createNewSlotForCompany: IResourceMethodObservableStrict<null, null, {id: number}, TopJobSlotData> | undefined
    createNewSlotForCompany(id: number): Promise<TopJobSlotData> {
        if (!this._createNewSlotForCompany) throw new Error("resource has not been properly initialized")
        return this._createNewSlotForCompany(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/company/topJobSlot/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deleteSlot: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    deleteSlot(id: number): Promise<null> {
        if (!this._deleteSlot) throw new Error("resource has not been properly initialized")
        return this._deleteSlot(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/companies/topJobSlots/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleTopJobSlot: IResourceMethodObservableStrict<null, null, {id: number}, TopJobSlotData> | undefined
    getSingleTopJobSlot(id: number): Promise<TopJobSlotData> {
        if (!this._getSingleTopJobSlot) throw new Error("resource has not been properly initialized")
        return this._getSingleTopJobSlot(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/companies/{id}/topJobSlots/all',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTopJobSlotsForCompany: IResourceMethodObservableStrict<null, null, {id: number}, TopJobSlotData[]> | undefined
    getTopJobSlotsForCompany(id: number): Promise<TopJobSlotData[]> {
        if (!this._getTopJobSlotsForCompany) throw new Error("resource has not been properly initialized")
        return this._getTopJobSlotsForCompany(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/company/topJobSlot/content/change',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateSlotContent: IResourceMethodObservableStrict<SlotContentData, null, null, SlotContentData> | undefined
    updateSlotContent(requestBody: SlotContentData): Promise<SlotContentData> {
        if (!this._updateSlotContent) throw new Error("resource has not been properly initialized")
        return this._updateSlotContent(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/company/topJobSlot/{id}/position/change',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateSlotPosition: IResourceMethodObservableStrict<null, {positionId: number}, {id: number}, TopJobSlotData> | undefined
    updateSlotPosition(id: number, requestParams: {positionId: number}): Promise<TopJobSlotData> {
        if (!this._updateSlotPosition) throw new Error("resource has not been properly initialized")
        return this._updateSlotPosition(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/company/topJobSlot/settings/change',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateSlotSettings: IResourceMethodObservableStrict<SlotSettingsData, null, null, TopJobSlotData> | undefined
    updateSlotSettings(requestBody: SlotSettingsData): Promise<TopJobSlotData> {
        if (!this._updateSlotSettings) throw new Error("resource has not been properly initialized")
        return this._updateSlotSettings(requestBody, null, null).toPromise()
    }

}