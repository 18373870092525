import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {EmploymentPositionApplicationStatisticsData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyControllingController
 */

@Injectable()
@ResourceParams({})
export class CompanyControllingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/controlling/threeWeekData/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getControllingDataForLastThreeMonths: IResourceMethodObservableStrict<null, null, {id: number}, EmploymentPositionApplicationStatisticsData[]> | undefined
    getControllingDataForLastThreeMonths(id: number): Promise<EmploymentPositionApplicationStatisticsData[]> {
        if (!this._getControllingDataForLastThreeMonths) throw new Error("resource has not been properly initialized")
        return this._getControllingDataForLastThreeMonths(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/controlling/complete/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getControllingSummaryForPosition: IResourceMethodObservableStrict<null, null, {id: number}, EmploymentPositionApplicationStatisticsData> | undefined
    getControllingSummaryForPosition(id: number): Promise<EmploymentPositionApplicationStatisticsData> {
        if (!this._getControllingSummaryForPosition) throw new Error("resource has not been properly initialized")
        return this._getControllingSummaryForPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/controlling/applications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionApplicationStatistics: IResourceMethodObservableStrict<null, {page: number, pageSize: number, states: ('Draft' | 'Inactive' | 'SearchableOnly' | 'Active')[], dateFrom: Date, dateTo: Date}, null, PagedData<EmploymentPositionApplicationStatisticsData>> | undefined
    getEmploymentPositionApplicationStatistics(requestParams: {page: number, pageSize: number, states: ('Draft' | 'Inactive' | 'SearchableOnly' | 'Active')[], dateFrom: Date, dateTo: Date}): Promise<PagedData<EmploymentPositionApplicationStatisticsData>> {
        if (!this._getEmploymentPositionApplicationStatistics) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionApplicationStatistics(null, requestParams, null).toPromise()
    }

}