import {Component, OnInit} from '@angular/core';
import {MediaService} from "../../../utility/services/media.service";
import {PublicWebinarResource} from "../../../../generated/resources";
import {WebinarData} from "../../../../generated/data";
import {FileService} from "../../../utility/services/file.service";
import {ActivatedRoute} from "@angular/router";
import {AnimationsService} from "../../../utility/services/animations.service";

@Component({
  selector: 'app-webinar-detail',
  templateUrl: './webinar-detail.component.html',
  styleUrl: './webinar-detail.component.scss'
})
export class WebinarDetailComponent implements OnInit{

  webinarId: number
  webinarData: WebinarData = <WebinarData>{}
  webinarImage: any = null

  constructor(
    public mediaService: MediaService,
    private webinarResource: PublicWebinarResource,
    private fileService: FileService,
    private route: ActivatedRoute,
    private animationsService: AnimationsService
  ) {
    this.webinarId = +this.route.snapshot.paramMap.get('routingUrl')
  }

  ngOnInit() {
    this.loadWebinar()
    this.loadWebinarImage()
    this.handleAnimations()
  }

  loadWebinar() {
    this.webinarResource.getWebinar(this.webinarId).then(result => {
      this.webinarData = result
    })
  }

  loadWebinarImage() {
    let sub = this.fileService.getWebinarImage(this.webinarId).subscribe(result => {
      this.webinarImage = URL.createObjectURL(result)
      sub.unsubscribe()
    })
  }

  handleAnimations() {
    this.animationsService.handleOnScrollAnimation(".register-container", "bounceIn", false)
    this.animationsService.handleOnScrollAnimation(".banner-image", "show", false)
    this.animationsService.handleOnScrollAnimation(".event-details", "show", false)
    this.animationsService.handleOnScrollAnimation(".speakers", "show", false)
  }

  registerForWebinar() {
    window.open(this.webinarData.joinLink, '_blank')
  }

}
