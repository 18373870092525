import {Component, OnInit} from '@angular/core';
import {PublicCareerArticleResource} from "../../../../generated/hid/resources";
import {CareerArticleData} from "../../../../generated/hid/data";
import {MediaService} from "../../../utility/services/media.service";

@Component({
  selector: 'app-career-guide-all',
  templateUrl: './career-guide-all.component.html',
  styleUrl: './career-guide-all.component.scss'
})
export class CareerGuideAllComponent implements OnInit {

  page: number = 0
  pageSize: number = 12
  totalElements: number = 0
  totalPages: number = 0
  loading: boolean = true

  skeletonList: number[] = Array(this.pageSize).fill(1).map(i => i)

  careerGuides: CareerArticleData[] = []

  constructor(
    private guideResource: PublicCareerArticleResource,
    public mediaService: MediaService
  ) {
  }

  ngOnInit() {
    this.loadCareerGuides()
  }

  loadCareerGuides() {
    this.loading = true
    this.guideResource.getAllArticles({page: this.page, pageSize: this.pageSize}).then(result => {
      this.loading = false
      this.careerGuides = result.content
      this.totalElements = result.totalElements
      this.totalPages = result.totalPages
    })
  }

  nextPage() {
    if((this.page + 1) >= this.totalPages) return
    this.loading = true
    this.page += 1
    this.loadCareerGuides()
  }

  previousPage() {
    if (this.page < 1) return
    this.loading = true
    this.page -= 1
    this.loadCareerGuides()
  }

  getProgressBarValue(): number {
    return ((this.page + 1) / this.totalPages) * 100
  }

}
