import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {AdminHiringStatisticData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminHiringController
 */

@Injectable()
@ResourceParams({})
export class AdminHiringResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/hiring/billing',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getHirings: IResourceMethodObservableStrict<null, {startDate: Date, endDate: Date, companyId: number, talentAgentId: number, keyAccounterId: number, page: number, pageSize: number}, null, PagedData<AdminHiringStatisticData>> | undefined
    getHirings(requestParams: {startDate: Date, endDate: Date, companyId: number, talentAgentId: number, keyAccounterId: number, page: number, pageSize: number}): Promise<PagedData<AdminHiringStatisticData>> {
        if (!this._getHirings) throw new Error("resource has not been properly initialized")
        return this._getHirings(null, requestParams, null).toPromise()
    }

}