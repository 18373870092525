<div class="case-study-wrapper flex center width-100 content-padding">

  <div
    [ngClass]="{'cap-width': mediaService.getScreenWidth() > 1080, 'mobile-wrapper': mediaService.getScreenWidth() <= 1080}">
    <!-- Title Section -->
    <section class="margin-bottom-lg">
      <div class="flex center width-100">
        <img class="case-study-logo" src="assets/images/partner-logos/userlike-logo.png" alt="">
      </div>
      <h1 class="medium-section-header center-text">
        {{caseStudyData.studyTitle}}
      </h1>
    </section>

    <!-- Video Section -->
    <section class="width-100">
      <div class="flex center">
        <div  class="display-video">

          <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe src="https://player.vimeo.com/video/937795637?h=d40eceacd0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Userlike Testimonial"></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
          <!--
         <iframe *ngIf="mediaService.getScreenWidth() <= 1080" onload="this.width=screen.width;" src="https://syntacton.sharepoint.com/sites/Marketing/_layouts/15/embed.aspx?UniqueId=2a887986-76b9-44b0-8b4b-b7a14340d32f&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create" height="300" frameborder="0" scrolling="no" allowfullscreen title="240313_testimonial-kristina_V2.mp4"></iframe>
          <iframe *ngIf="mediaService.getScreenWidth() > 1080" src="https://syntacton.sharepoint.com/sites/Marketing/_layouts/15/embed.aspx?UniqueId=2a887986-76b9-44b0-8b4b-b7a14340d32f&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create" width="860" height="484" frameborder="0" scrolling="no" allowfullscreen title="240313_testimonial-kristina_V2.mp4"></iframe>
        -->
        </div>
      </div>

      <p class="color-marine width-100 center-text padding-lg">{{caseStudyData.videoTagline}}</p>

      <div class="flex center">
        <a (click)="navToContact('CaseStudyTopCta')">
          <button  class="accent-button margin">Jetzt Democall vereinbaren</button>
        </a>
      </div>
    </section>

    <!-- USP Section -->
    <section class="padding-vertical-lg">
      <div
        [ngClass]="{'column': mediaService.getScreenWidth() <= 768, 'row': mediaService.getScreenWidth() > 768}"
        class="flex center width-100">
        <div
          [ngClass]="{
          'row': mediaService.getScreenWidth() <= 768,
          'start': mediaService.getScreenWidth() <= 768,
          'column': mediaService.getScreenWidth() > 768,
          'center-start': mediaService.getScreenWidth() > 768
          }"
          class="usp-container first-usp flex padding-lg">
          <div class="usp-icon-wrapper flex center">
            <i class="usp-icon mdi mdi-account-heart"></i>
          </div>
          <div class="flex column start">
            <p
              [ngClass]="{'quote-author': mediaService.getScreenWidth() <= 768}"
              class="bold-text color-regular margin-top">60%</p>
            <p class="margin-vertical-sm">Interessante Talente</p>
          </div>
        </div>

        <div
          [ngClass]="{
          'row': mediaService.getScreenWidth() <= 768,
          'start': mediaService.getScreenWidth() <= 768,
          'column': mediaService.getScreenWidth() > 768,
          'center-start': mediaService.getScreenWidth() > 768
          }"
          class="usp-container second-usp flex padding-lg">
          <div class="usp-icon-wrapper flex center delay-0-2s">
            <i class="usp-icon mdi mdi-clock-star-four-points-outline"></i>
          </div>
          <div class="flex column start">
            <p
              [ngClass]="{'quote-author': mediaService.getScreenWidth() <= 768}"
              class="bold-text color-regular margin-top">2 Monate</p>
            <p class="margin-vertical-sm">Bis zum ersten Hiring</p>
          </div>
        </div>

        <div
          [ngClass]="{
          'row': mediaService.getScreenWidth() <= 768,
          'start': mediaService.getScreenWidth() <= 768,
          'column': mediaService.getScreenWidth() > 768,
          'center-start': mediaService.getScreenWidth() > 768
          }"
          class="usp-container third-usp flex padding-lg">
          <div class="usp-icon-wrapper flex center delay-0-4s">
            <i class="usp-icon mdi mdi-text-box-check-outline"></i>
          </div>
          <div class="flex column start">
            <p
              [ngClass]="{'quote-author': mediaService.getScreenWidth() <= 768}"
              class="bold-text color-regular margin-top">20%</p>
            <p class="margin-vertical-sm">Konvertierung der Anzeige</p>
          </div>
        </div>

      </div>
    </section>

    <section class="margin-bottom-lg">
      <div
        [ngClass]="{'column': mediaService.getScreenWidth() <= 768, 'row': mediaService.getScreenWidth() > 768}"
        class="flex flex-space-between margin-bottom">
        <div
          [ngClass]="{'width-66': mediaService.getScreenWidth() > 768, 'padding-right-xl': mediaService.getScreenWidth() > 768}"
          class=" flex column long-text " [innerHTML]="caseStudyData.studyText | safeHtml">
        </div>
        <div
          [ngClass]="{'width-33': mediaService.getScreenWidth() > 768, 'margin-top': mediaService.getScreenWidth() <= 768}"
          class="flex column start">
          <br *ngIf="mediaService.getScreenWidth() <= 768">
          <p class="quote-text">{{caseStudyData.quoteText}}</p>
          <br>
          <p class="quote-author">{{caseStudyData.quoteAuthor}}</p>
          <p class="quote-author-position">{{caseStudyData.quoteAuthorTitle}}</p>
          <i class="quote-icon mdi mdi-format-quote-close"></i>
          <br *ngIf="mediaService.getScreenWidth() <= 768">
        </div>
      </div>
      <div class="flex center margin-top">
        <a (click)="navToContact('CaseStudyBottomCta')">
          <button  class="accent-button">Jetzt Democall vereinbaren</button>
        </a>
      </div>
    </section>

      <contact-formular></contact-formular>

  </div>
</div>
