import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {AdminTalentFeedPositionRelationData, OptimizeCustomerPositionsUnsuitableToTrainingData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminPositionProposalController
 */

@Injectable()
@ResourceParams({})
export class AdminPositionProposalResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/positionProposals/customerPositionsToOptimize',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCustomerPositionsToOptimize: IResourceMethodObservableStrict<null, null, null, OptimizeCustomerPositionsUnsuitableToTrainingData> | undefined
    getCustomerPositionsToOptimize(): Promise<OptimizeCustomerPositionsUnsuitableToTrainingData> {
        if (!this._getCustomerPositionsToOptimize) throw new Error("resource has not been properly initialized")
        return this._getCustomerPositionsToOptimize(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/positionProposals/rejected',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRejectedPositionProposals: IResourceMethodObservableStrict<null, {rejectedReasons: ('UnsuitableLocation' | 'UnsuitableCompany' | 'UnsuitableActivity' | 'UnsuitableToMyTraining' | 'DistanceTooBig' | 'UnsuitableCity' | 'OtherReason')[], page: number, pageSize: number}, null, PagedData<AdminTalentFeedPositionRelationData>> | undefined
    getRejectedPositionProposals(requestParams: {rejectedReasons: ('UnsuitableLocation' | 'UnsuitableCompany' | 'UnsuitableActivity' | 'UnsuitableToMyTraining' | 'DistanceTooBig' | 'UnsuitableCity' | 'OtherReason')[], page: number, pageSize: number}): Promise<PagedData<AdminTalentFeedPositionRelationData>> {
        if (!this._getRejectedPositionProposals) throw new Error("resource has not been properly initialized")
        return this._getRejectedPositionProposals(null, requestParams, null).toPromise()
    }

}