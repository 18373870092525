import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {CompanyTalentMlpInformationData, ApplicationData, AnonymousProfileData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyTalentController
 */

@Injectable()
@ResourceParams({})
export class CompanyTalentResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/talents/{talentId}/applications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllApplicationsForTalent: IResourceMethodObservableStrict<null, null, {talentId: number}, ApplicationData[]> | undefined
    getAllApplicationsForTalent(talentId: number): Promise<ApplicationData[]> {
        if (!this._getAllApplicationsForTalent) throw new Error("resource has not been properly initialized")
        return this._getAllApplicationsForTalent(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/talents/{talentId}/ownApplications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllApplicationsForTalentOfRecruiterCompany: IResourceMethodObservableStrict<null, null, {talentId: number}, ApplicationData[]> | undefined
    getAllApplicationsForTalentOfRecruiterCompany(talentId: number): Promise<ApplicationData[]> {
        if (!this._getAllApplicationsForTalentOfRecruiterCompany) throw new Error("resource has not been properly initialized")
        return this._getAllApplicationsForTalentOfRecruiterCompany(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/applications/{id}/dossier',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationDossier: IResourceMethodObservableStrict<null, {matchingScore?: number}, {id: number}, Blob> | undefined
    getApplicationDossier(id: number, requestParams?: {matchingScore?: number}): Promise<Blob> {
        if (!this._getApplicationDossier) throw new Error("resource has not been properly initialized")
        return this._getApplicationDossier(null, requestParams || {}, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/talents/{talentId}/badges',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getBadgesForTalent: IResourceMethodObservableStrict<null, null, {talentId: number}, string[]> | undefined
    getBadgesForTalent(talentId: number): Promise<string[]> {
        if (!this._getBadgesForTalent) throw new Error("resource has not been properly initialized")
        return this._getBadgesForTalent(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/talents/{talentId}/curiculumVitae',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCV: IResourceMethodObservableStrict<null, null, {talentId: number}, Blob> | undefined
    getCV(talentId: number): Promise<Blob> {
        if (!this._getCV) throw new Error("resource has not been properly initialized")
        return this._getCV(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/talents/{talentId}/talent_info',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getDetailedTalentById: IResourceMethodObservableStrict<null, null, {talentId: number}, AnonymousProfileData> | undefined
    getDetailedTalentById(talentId: number): Promise<AnonymousProfileData> {
        if (!this._getDetailedTalentById) throw new Error("resource has not been properly initialized")
        return this._getDetailedTalentById(null, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/talents/{id}/matching',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getDetailedTalentFromMatching: IResourceMethodObservableStrict<null, {positionId: number}, {id: number}, AnonymousProfileData> | undefined
    getDetailedTalentFromMatching(id: number, requestParams: {positionId: number}): Promise<AnonymousProfileData> {
        if (!this._getDetailedTalentFromMatching) throw new Error("resource has not been properly initialized")
        return this._getDetailedTalentFromMatching(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/talents/{talentId}/dossier',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentDossier: IResourceMethodObservableStrict<null, {matchingScore?: number}, {talentId: number}, Blob> | undefined
    getTalentDossier(talentId: number, requestParams?: {matchingScore?: number}): Promise<Blob> {
        if (!this._getTalentDossier) throw new Error("resource has not been properly initialized")
        return this._getTalentDossier(null, requestParams || {}, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/talents/{id}/mlpInformation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentMlpInformation: IResourceMethodObservableStrict<null, null, {id: number}, CompanyTalentMlpInformationData> | undefined
    getTalentMlpInformation(id: number): Promise<CompanyTalentMlpInformationData> {
        if (!this._getTalentMlpInformation) throw new Error("resource has not been properly initialized")
        return this._getTalentMlpInformation(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/talents/contact-view/{talentId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reportContactInfoView: IResourceMethodObservableStrict<null, null, {talentId: number}, null> | undefined
    reportContactInfoView(talentId: number): Promise<null> {
        if (!this._reportContactInfoView) throw new Error("resource has not been properly initialized")
        return this._reportContactInfoView(null, null, {talentId: talentId}).toPromise()
    }

}