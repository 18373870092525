import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, BookingData, BookingFilterData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyBookingController
 */

@Injectable()
@ResourceParams({})
export class CompanyBookingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/bookings/active',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _checkForActiveBookings: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    checkForActiveBookings(): Promise<boolean> {
        if (!this._checkForActiveBookings) throw new Error("resource has not been properly initialized")
        return this._checkForActiveBookings(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/bookings/create',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateBooking: IResourceMethodObservableStrict<BookingData, {dateFrom: Date}, null, BookingData> | undefined
    createOrUpdateBooking(requestBody: BookingData, requestParams: {dateFrom: Date}): Promise<BookingData> {
        if (!this._createOrUpdateBooking) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateBooking(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/bookings',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredBookings: IResourceMethodObservableStrict<BookingFilterData, null, null, PagedData<BookingData>> | undefined
    getFilteredBookings(requestBody: BookingFilterData): Promise<PagedData<BookingData>> {
        if (!this._getFilteredBookings) throw new Error("resource has not been properly initialized")
        return this._getFilteredBookings(requestBody, null, null).toPromise()
    }

}