import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, ChatMinimalData, ChatMessageData, ChatData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantChatController
 */

@Injectable()
@ResourceParams({})
export class ConsultantChatResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/chat-overview/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getChatForOverview: IResourceMethodObservableStrict<null, null, {id: number}, ChatData> | undefined
    getChatForOverview(id: number): Promise<ChatData> {
        if (!this._getChatForOverview) throw new Error("resource has not been properly initialized")
        return this._getChatForOverview(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/chat',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getChatForTalent: IResourceMethodObservableStrict<null, null, {id: number}, ChatData> | undefined
    getChatForTalent(id: number): Promise<ChatData> {
        if (!this._getChatForTalent) throw new Error("resource has not been properly initialized")
        return this._getChatForTalent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/chat-overview',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getChatMinimalDataForOverview: IResourceMethodObservableStrict<null, null, null, ChatMinimalData[]> | undefined
    getChatMinimalDataForOverview(): Promise<ChatMinimalData[]> {
        if (!this._getChatMinimalDataForOverview) throw new Error("resource has not been properly initialized")
        return this._getChatMinimalDataForOverview(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/unseen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUnseenChatCount: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getUnseenChatCount(): Promise<JsonWrappedValue<number>> {
        if (!this._getUnseenChatCount) throw new Error("resource has not been properly initialized")
        return this._getUnseenChatCount(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/consultant/chat-overview/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendChatMessageOrCreateChat: IResourceMethodObservableStrict<null, {message: string, chatId: number}, {id: number}, ChatMessageData> | undefined
    sendChatMessageOrCreateChat(id: number, requestParams: {message: string, chatId: number}): Promise<ChatMessageData> {
        if (!this._sendChatMessageOrCreateChat) throw new Error("resource has not been properly initialized")
        return this._sendChatMessageOrCreateChat(null, requestParams, {id: id}).toPromise()
    }

}