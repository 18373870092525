import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {CompanyData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.CompanyController
 */

@Injectable()
@ResourceParams({})
export class CompanyResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/companies',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompaniesByQuery: IResourceMethodObservableStrict<null, {q?: string, cityId?: number, page?: number, pageSize?: number, forcedIDs?: number[]}, null, PagedData<CompanyData>> | undefined
    getCompaniesByQuery(requestParams?: {q?: string, cityId?: number, page?: number, pageSize?: number, forcedIDs?: number[]}): Promise<PagedData<CompanyData>> {
        if (!this._getCompaniesByQuery) throw new Error("resource has not been properly initialized")
        return this._getCompaniesByQuery(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/companies/{id}/banner',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyBanner: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getCompanyBanner(id: number): Promise<Blob> {
        if (!this._getCompanyBanner) throw new Error("resource has not been properly initialized")
        return this._getCompanyBanner(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/companies/{id}/logo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyLogo: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getCompanyLogo(id: number): Promise<Blob> {
        if (!this._getCompanyLogo) throw new Error("resource has not been properly initialized")
        return this._getCompanyLogo(null, null, {id: id}).toPromise()
    }

}