import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {TalentEventOnboardingCreationData, JsonWrappedValue, TalentCoachRequestDistributionData, UnisignAccountRegistrationSuccessData, PublicEventData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.onboarding.TalentEventOnboardingController
 */

@Injectable()
@ResourceParams({})
export class TalentEventOnboardingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/event/{id}/account',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createTalentAccountAndLogin: IResourceMethodObservableStrict<TalentEventOnboardingCreationData, null, {id: number}, UnisignAccountRegistrationSuccessData> | undefined
    createTalentAccountAndLogin(requestBody: TalentEventOnboardingCreationData, id: number): Promise<UnisignAccountRegistrationSuccessData> {
        if (!this._createTalentAccountAndLogin) throw new Error("resource has not been properly initialized")
        return this._createTalentAccountAndLogin(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/event/finalize',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _finalizeTalentOnboardingForTalent: IResourceMethodObservableStrict<null, null, null, null> | undefined
    finalizeTalentOnboardingForTalent(): Promise<null> {
        if (!this._finalizeTalentOnboardingForTalent) throw new Error("resource has not been properly initialized")
        return this._finalizeTalentOnboardingForTalent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/onboarding/event/{id}/details',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEventDetails: IResourceMethodObservableStrict<null, null, {id: number}, PublicEventData> | undefined
    getEventDetails(id: number): Promise<PublicEventData> {
        if (!this._getEventDetails) throw new Error("resource has not been properly initialized")
        return this._getEventDetails(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/onboarding/event/id',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEventIdForUser: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getEventIdForUser(): Promise<JsonWrappedValue<number>> {
        if (!this._getEventIdForUser) throw new Error("resource has not been properly initialized")
        return this._getEventIdForUser(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/onboarding/event/coachRequest',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _requestCoach: IResourceMethodObservableStrict<number[], null, null, null> | undefined
    requestCoach(requestBody: number[]): Promise<null> {
        if (!this._requestCoach) throw new Error("resource has not been properly initialized")
        return this._requestCoach(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/onboarding/event/Coachdistribution',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateDistributionCriteriaForCoachRequest: IResourceMethodObservableStrict<TalentCoachRequestDistributionData, null, null, null> | undefined
    updateDistributionCriteriaForCoachRequest(requestBody: TalentCoachRequestDistributionData): Promise<null> {
        if (!this._updateDistributionCriteriaForCoachRequest) throw new Error("resource has not been properly initialized")
        return this._updateDistributionCriteriaForCoachRequest(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/event/study',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateStudyAndPreferencesForTalent: IResourceMethodObservableStrict<TalentEventOnboardingCreationData, null, null, null> | undefined
    updateStudyAndPreferencesForTalent(requestBody: TalentEventOnboardingCreationData): Promise<null> {
        if (!this._updateStudyAndPreferencesForTalent) throw new Error("resource has not been properly initialized")
        return this._updateStudyAndPreferencesForTalent(requestBody, null, null).toPromise()
    }

}