import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, ConsultantPerformanceData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantControllingController
 */

@Injectable()
@ResourceParams({})
export class ConsultantControllingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/performance',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getConsultantPerformance: IResourceMethodObservableStrict<null, null, null, ConsultantPerformanceData> | undefined
    getConsultantPerformance(): Promise<ConsultantPerformanceData> {
        if (!this._getConsultantPerformance) throw new Error("resource has not been properly initialized")
        return this._getConsultantPerformance(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/slotCreatedInfo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSlotCreatedInfo: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    getSlotCreatedInfo(): Promise<boolean> {
        if (!this._getSlotCreatedInfo) throw new Error("resource has not been properly initialized")
        return this._getSlotCreatedInfo(null, null, null).toPromise().then((result) => result.value)
    }

}