import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantTalentProfilePictureController
 */

@Injectable()
@ResourceParams({})
export class ConsultantTalentProfilePictureResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/profilePicture/{hash}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfileImage: IResourceMethodObservableStrict<null, null, {id: number, hash: string}, Blob> | undefined
    getProfileImage(pathVariables: {id: number, hash: string}): Promise<Blob> {
        if (!this._getProfileImage) throw new Error("resource has not been properly initialized")
        return this._getProfileImage(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talents/{id}/profilePictureHash',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfileImageHash: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<string>> | undefined
    getProfileImageHash(id: number): Promise<string> {
        if (!this._getProfileImageHash) throw new Error("resource has not been properly initialized")
        return this._getProfileImageHash(null, null, {id: id}).toPromise().then((result) => result.value)
    }

}