import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-privacy',
  templateUrl: './legal-privacy.component.html',
  styleUrl: './legal-privacy.component.scss'
})
export class LegalPrivacyComponent {

}
