import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {BlogCategoryData, PublicOfferPagePreviewData, PublicMetaFormatPageCheckoutOptionData, PublicBlogPostData, PublicFormatPageOneClickSignUpData, PublicBlogPostPreviewData, PagedData, PublicFormatPageData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicOfferPageController
 */

@Injectable()
@ResourceParams({})
export class PublicOfferPageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/blog/categories',
        endpoint: 'net.innospire.hidcms'
    })
    private _getBlogCategories: IResourceMethodObservableStrict<null, null, null, BlogCategoryData[]> | undefined
    getBlogCategories(): Promise<BlogCategoryData[]> {
        if (!this._getBlogCategories) throw new Error("resource has not been properly initialized")
        return this._getBlogCategories(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/blogposts/{routingUrl}',
        endpoint: 'net.innospire.hidcms'
    })
    private _getBlogPage: IResourceMethodObservableStrict<null, {categoryId?: number}, {routingUrl: string}, PublicBlogPostData> | undefined
    getBlogPage(routingUrl: string, requestParams?: {categoryId?: number}): Promise<PublicBlogPostData> {
        if (!this._getBlogPage) throw new Error("resource has not been properly initialized")
        return this._getBlogPage(null, requestParams || {}, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/blogposts/{id}/offers',
        endpoint: 'net.innospire.hidcms'
    })
    private _getBlogPageOffers: IResourceMethodObservableStrict<null, {categoryId?: number}, {id: number}, PublicOfferPagePreviewData[]> | undefined
    getBlogPageOffers(id: number, requestParams?: {categoryId?: number}): Promise<PublicOfferPagePreviewData[]> {
        if (!this._getBlogPageOffers) throw new Error("resource has not been properly initialized")
        return this._getBlogPageOffers(null, requestParams || {}, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/blogposts',
        endpoint: 'net.innospire.hidcms'
    })
    private _getBlogPostsPreview: IResourceMethodObservableStrict<null, {q: string, categoryIds?: number[], page: number, pageSize: number}, null, PagedData<PublicBlogPostPreviewData>> | undefined
    getBlogPostsPreview(requestParams: {q: string, categoryIds?: number[], page: number, pageSize: number}): Promise<PagedData<PublicBlogPostPreviewData>> {
        if (!this._getBlogPostsPreview) throw new Error("resource has not been properly initialized")
        return this._getBlogPostsPreview(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/formats/{routingUrl}',
        endpoint: 'net.innospire.hidcms'
    })
    private _getFormatOfferPage: IResourceMethodObservableStrict<null, {forcedSplitId?: number, promId?: number}, {routingUrl: string}, PublicFormatPageData> | undefined
    getFormatOfferPage(routingUrl: string, requestParams?: {forcedSplitId?: number, promId?: number}): Promise<PublicFormatPageData> {
        if (!this._getFormatOfferPage) throw new Error("resource has not been properly initialized")
        return this._getFormatOfferPage(null, requestParams || {}, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/formats/byCronosId/{cronosFormatId}',
        endpoint: 'net.innospire.hidcms'
    })
    private _getFormatOfferPageByCronosId: IResourceMethodObservableStrict<null, {forcedSplitId?: number}, {cronosFormatId: number}, PublicFormatPageData> | undefined
    getFormatOfferPageByCronosId(cronosFormatId: number, requestParams?: {forcedSplitId?: number}): Promise<PublicFormatPageData> {
        if (!this._getFormatOfferPageByCronosId) throw new Error("resource has not been properly initialized")
        return this._getFormatOfferPageByCronosId(null, requestParams || {}, {cronosFormatId: cronosFormatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/metaPages/{routingUrl}/options',
        endpoint: 'net.innospire.hidcms'
    })
    private _getFormatOptionsForMetaPage: IResourceMethodObservableStrict<null, null, {routingUrl: string}, PublicMetaFormatPageCheckoutOptionData[]> | undefined
    getFormatOptionsForMetaPage(routingUrl: string): Promise<PublicMetaFormatPageCheckoutOptionData[]> {
        if (!this._getFormatOptionsForMetaPage) throw new Error("resource has not been properly initialized")
        return this._getFormatOptionsForMetaPage(null, null, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/pulic/offerpages/formatPages/descriptions/bySyncedFormatId',
        endpoint: 'net.innospire.hidcms'
    })
    private _getFormatPageDescriptionsBySyncedFormatId: IResourceMethodObservableStrict<null, {syncedFormatId: number}, null, PublicFormatPageOneClickSignUpData> | undefined
    getFormatPageDescriptionsBySyncedFormatId(requestParams: {syncedFormatId: number}): Promise<PublicFormatPageOneClickSignUpData> {
        if (!this._getFormatPageDescriptionsBySyncedFormatId) throw new Error("resource has not been properly initialized")
        return this._getFormatPageDescriptionsBySyncedFormatId(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/formatPages/bySyncedFormatId',
        endpoint: 'net.innospire.hidcms'
    })
    private _getFormatPageForSyncedFormat: IResourceMethodObservableStrict<null, {syncedFormatId: number}, null, PublicOfferPagePreviewData> | undefined
    getFormatPageForSyncedFormat(requestParams: {syncedFormatId: number}): Promise<PublicOfferPagePreviewData> {
        if (!this._getFormatPageForSyncedFormat) throw new Error("resource has not been properly initialized")
        return this._getFormatPageForSyncedFormat(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/formatPages/{cronosFormatId}/preview',
        endpoint: 'net.innospire.hidcms'
    })
    private _getFormatPagePreviewDataBySyncedFormatId: IResourceMethodObservableStrict<null, null, {cronosFormatId: number}, PublicOfferPagePreviewData> | undefined
    getFormatPagePreviewDataBySyncedFormatId(cronosFormatId: number): Promise<PublicOfferPagePreviewData> {
        if (!this._getFormatPagePreviewDataBySyncedFormatId) throw new Error("resource has not been properly initialized")
        return this._getFormatPagePreviewDataBySyncedFormatId(null, null, {cronosFormatId: cronosFormatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/formats/preview/{id}',
        endpoint: 'net.innospire.hidcms'
    })
    private _getFormatSplitPreview: IResourceMethodObservableStrict<null, null, {id: string}, PublicFormatPageData> | undefined
    getFormatSplitPreview(id: string): Promise<PublicFormatPageData> {
        if (!this._getFormatSplitPreview) throw new Error("resource has not been properly initialized")
        return this._getFormatSplitPreview(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/{pid}/banner/inline',
        endpoint: 'net.innospire.hidcms'
    })
    private _getInlinePageBanner: IResourceMethodObservableStrict<null, {splitId?: number}, {pid: number}, Blob> | undefined
    getInlinePageBanner(pid: number, requestParams?: {splitId?: number}): Promise<Blob> {
        if (!this._getInlinePageBanner) throw new Error("resource has not been properly initialized")
        return this._getInlinePageBanner(null, requestParams || {}, {pid: pid}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/{id}/preview',
        endpoint: 'net.innospire.hidcms'
    })
    private _getOfferPagePreviewData: IResourceMethodObservableStrict<null, null, {id: number}, PublicOfferPagePreviewData> | undefined
    getOfferPagePreviewData(id: number): Promise<PublicOfferPagePreviewData> {
        if (!this._getOfferPagePreviewData) throw new Error("resource has not been properly initialized")
        return this._getOfferPagePreviewData(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/{pid}/upsell',
        endpoint: 'net.innospire.hidcms'
    })
    private _getOfferPageUpsellRecommendations: IResourceMethodObservableStrict<null, null, {pid: number}, number[]> | undefined
    getOfferPageUpsellRecommendations(pid: number): Promise<number[]> {
        if (!this._getOfferPageUpsellRecommendations) throw new Error("resource has not been properly initialized")
        return this._getOfferPageUpsellRecommendations(null, null, {pid: pid}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/byCronosFormatId{fid}/upsell',
        endpoint: 'net.innospire.hidcms'
    })
    private _getOfferPageUpsellRecommendationsBySyncedFormatId: IResourceMethodObservableStrict<null, null, {fid: number}, number[]> | undefined
    getOfferPageUpsellRecommendationsBySyncedFormatId(fid: number): Promise<number[]> {
        if (!this._getOfferPageUpsellRecommendationsBySyncedFormatId) throw new Error("resource has not been properly initialized")
        return this._getOfferPageUpsellRecommendationsBySyncedFormatId(null, null, {fid: fid}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/{pid}/banner/side',
        endpoint: 'net.innospire.hidcms'
    })
    private _getSidePageBanner: IResourceMethodObservableStrict<null, {splitId?: number}, {pid: number}, Blob> | undefined
    getSidePageBanner(pid: number, requestParams?: {splitId?: number}): Promise<Blob> {
        if (!this._getSidePageBanner) throw new Error("resource has not been properly initialized")
        return this._getSidePageBanner(null, requestParams || {}, {pid: pid}).toPromise()
    }

}