import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-basic-loading-skeleton',
  templateUrl: './basic-loading-skeleton.component.html',
  styleUrls: ['./basic-loading-skeleton.component.scss'],
})
export class BasicLoadingSkeletonComponent  implements OnInit {

  @Input() loadingList: boolean = false
  @Input() listItemCount: number = 7
  @Input() darkerColor: boolean = false
  inputList: number[] = []

  constructor(
  ) { }

  ngOnInit() {
    this.inputList = Array(this.listItemCount).fill(1).map((x,i)=>i);
  }

}
