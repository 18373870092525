import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {TalentEvaluationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentEvaluationController
 */

@Injectable()
@ResourceParams({})
export class TalentEvaluationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/evaluation/result',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _downloadTalentEvaluationResult: IResourceMethodObservableStrict<null, null, null, Blob> | undefined
    downloadTalentEvaluationResult(): Promise<Blob> {
        if (!this._downloadTalentEvaluationResult) throw new Error("resource has not been properly initialized")
        return this._downloadTalentEvaluationResult(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/evaluation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentEvaluation: IResourceMethodObservableStrict<null, null, null, TalentEvaluationData> | undefined
    getTalentEvaluation(): Promise<TalentEvaluationData> {
        if (!this._getTalentEvaluation) throw new Error("resource has not been properly initialized")
        return this._getTalentEvaluation(null, null, null).toPromise()
    }

}