import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {CompanyLatestEmploymentPositionData, CompanyPositionFilterData, ApplicationData, MinimalEmploymentPositionData, ChatLatestChatsData, PagedData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyDashboardController
 */

@Injectable()
@ResourceParams({})
export class CompanyDashboardResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/dashboard/weeklyPerformance',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _existsBadPerformingPosition: IResourceMethodObservableStrict<null, null, null, LabelData[]> | undefined
    existsBadPerformingPosition(): Promise<LabelData[]> {
        if (!this._existsBadPerformingPosition) throw new Error("resource has not been properly initialized")
        return this._existsBadPerformingPosition(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/dashboard/positions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositions: IResourceMethodObservableStrict<CompanyPositionFilterData, {page?: number, pageSize?: number, sortAsc?: boolean}, null, PagedData<CompanyLatestEmploymentPositionData>> | undefined
    getEmploymentPositions(requestBody: CompanyPositionFilterData, requestParams?: {page?: number, pageSize?: number, sortAsc?: boolean}): Promise<PagedData<CompanyLatestEmploymentPositionData>> {
        if (!this._getEmploymentPositions) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositions(requestBody, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/dashboard/chat',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLatestChats: IResourceMethodObservableStrict<null, null, null, PagedData<ChatLatestChatsData>> | undefined
    getLatestChats(): Promise<PagedData<ChatLatestChatsData>> {
        if (!this._getLatestChats) throw new Error("resource has not been properly initialized")
        return this._getLatestChats(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/dashboard/drafts',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionDrafts: IResourceMethodObservableStrict<null, null, null, PagedData<MinimalEmploymentPositionData>> | undefined
    getPositionDrafts(): Promise<PagedData<MinimalEmploymentPositionData>> {
        if (!this._getPositionDrafts) throw new Error("resource has not been properly initialized")
        return this._getPositionDrafts(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/dashboard/applications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRecentApplications: IResourceMethodObservableStrict<null, {showAssigned: boolean}, null, ApplicationData[]> | undefined
    getRecentApplications(requestParams: {showAssigned: boolean}): Promise<ApplicationData[]> {
        if (!this._getRecentApplications) throw new Error("resource has not been properly initialized")
        return this._getRecentApplications(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/dashboard/onboarding',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateOnboardingStep: IResourceMethodObservableStrict<null, {step: ('CreatePosition' | 'PublishPosition' | 'EditProfile' | 'AddRecruiter' | 'Complete')}, null, null> | undefined
    updateOnboardingStep(requestParams: {step: ('CreatePosition' | 'PublishPosition' | 'EditProfile' | 'AddRecruiter' | 'Complete')}): Promise<null> {
        if (!this._updateOnboardingStep) throw new Error("resource has not been properly initialized")
        return this._updateOnboardingStep(null, requestParams, null).toPromise()
    }

}