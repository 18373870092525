import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {AdminPositionMatchingConfigDisplayData, PositionProfessionFieldRelationData, PositionMatchingConfigData, AdminPositionMatchingConfigData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminMatchingController
 */

@Injectable()
@ResourceParams({})
export class AdminMatchingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/{id}/positions/matchingState',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deactivateAllPositionsForCompany: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    deactivateAllPositionsForCompany(id: number): Promise<null> {
        if (!this._deactivateAllPositionsForCompany) throw new Error("resource has not been properly initialized")
        return this._deactivateAllPositionsForCompany(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/positions/{id}/matchingState',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deactivateMatching: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    deactivateMatching(id: number): Promise<null> {
        if (!this._deactivateMatching) throw new Error("resource has not been properly initialized")
        return this._deactivateMatching(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/positionsWithConfig',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getActivePositionsWithMatchingConfigurations: IResourceMethodObservableStrict<null, {onlyCustomerPositions: boolean, pageNum: number, pageSize: number}, null, PagedData<PositionMatchingConfigData>> | undefined
    getActivePositionsWithMatchingConfigurations(requestParams: {onlyCustomerPositions: boolean, pageNum: number, pageSize: number}): Promise<PagedData<PositionMatchingConfigData>> {
        if (!this._getActivePositionsWithMatchingConfigurations) throw new Error("resource has not been properly initialized")
        return this._getActivePositionsWithMatchingConfigurations(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/{id}/matchingConfig',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionMatchingConfig: IResourceMethodObservableStrict<null, null, {id: number}, AdminPositionMatchingConfigDisplayData> | undefined
    getPositionMatchingConfig(id: number): Promise<AdminPositionMatchingConfigDisplayData> {
        if (!this._getPositionMatchingConfig) throw new Error("resource has not been properly initialized")
        return this._getPositionMatchingConfig(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/{id}/professionFieldRelations',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionFieldRelations: IResourceMethodObservableStrict<null, null, {id: number}, PositionProfessionFieldRelationData[]> | undefined
    getProfessionFieldRelations(id: number): Promise<PositionProfessionFieldRelationData[]> {
        if (!this._getProfessionFieldRelations) throw new Error("resource has not been properly initialized")
        return this._getProfessionFieldRelations(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/positions/{id}/matchingConfig',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updatePositionMatchingConfigAndInitiateMatching: IResourceMethodObservableStrict<AdminPositionMatchingConfigData, null, {id: number}, null> | undefined
    updatePositionMatchingConfigAndInitiateMatching(requestBody: AdminPositionMatchingConfigData, id: number): Promise<null> {
        if (!this._updatePositionMatchingConfigAndInitiateMatching) throw new Error("resource has not been properly initialized")
        return this._updatePositionMatchingConfigAndInitiateMatching(requestBody, null, {id: id}).toPromise()
    }

}