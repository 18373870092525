import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AnalyticsService} from '../../services/gdprconsent/analytics.service';
import {GDPRConsent, LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'app-gdprconsent',
  templateUrl: './gdprconsent.component.html',
  styleUrls: ['./gdprconsent.component.scss']
})
export class GDPRConsentComponent implements OnInit {


  @Output() close: EventEmitter<any> = new EventEmitter<any>()

  consentState: GDPRConsent;

  @Input() detailedView = false;

  initialConsent = false;

  form: FormGroup;

  constructor(
    private localStorageService: LocalStorageService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.form = fb.group({
      consent_ga: [true],
      consent_linkedIn: [true],
      consent_facebook: [true],
      consent_pinterest: [true],
      consent_tiktok: [true],
      consent_piwik: [true],
      consent_google_ads: [true],
      consent_hotjar: [true],
      consent_leadfeeder: [true]
    });
  }



  ngOnInit() {

    let consentState = this.localStorageService.getGDPRConsent();
    this.initialConsent = consentState.initial;

    this.form.patchValue({
        consent_ga: consentState.consent_ga,
        consent_linkedIn: consentState.consent_linkedIn,
        consent_facebook: consentState.consent_facebook,
        consent_pinterest: consentState.consent_pinterest,
        consent_tiktok: consentState.consent_tiktok,
        consent_piwik: consentState.consent_piwik,
        consent_hotjar: consentState.consent_hotjar,
        consent_leadfeeder: consentState.consent_leadfeeder,
        consent_google_ads: consentState.consent_google_ads
      }
    )
  }

  selectAll() {
    this.form.patchValue({
        consent_ga: true,
        consent_linkedIn: true,
        consent_facebook: true,
        consent_pinterest: true,
        consent_tiktok: true,
        consent_piwik: true,
        consent_google_ads: true,
        consent_hotjar: true,
        consent_leadfeeder: true
      }
    )
  }

  submitAll() {
    this.selectAll();
    this.submit();
  }

  submit() {
    this.localStorageService.updateGDPRConsent({
      initial: false,
      consent_ga: this.form.controls['consent_ga'].value,
      consent_linkedIn: this.form.controls['consent_linkedIn'].value,
      consent_facebook: this.form.controls['consent_facebook'].value,
      consent_pinterest: this.form.controls['consent_pinterest'].value,
      consent_tiktok: this.form.controls['consent_tiktok'].value,
      consent_piwik: this.form.controls['consent_piwik'].value,
      consent_google_ads: this.form.controls['consent_google_ads'].value,
      consent_hotjar: this.form.controls['consent_hotjar'].value,
      consent_leadfeeder: this.form.controls['consent_leadfeeder'].value
    });

    /** Set Cookies for Talentagent Domain which will enable all Subdomains to access the GDPR Settings */
    document.cookie = `initial=${this.localStorageService.getGDPRConsent().initial};domain=talentagent.de`
    document.cookie = `consent_ga=${this.form.controls['consent_ga'].value};domain=talentagent.de`
    document.cookie = `consent_linkedIn=${this.form.controls['consent_linkedIn'].value};domain=talentagent.de`
    document.cookie = `consent_facebook=${this.form.controls['consent_facebook'].value};domain=talentagent.de`
    document.cookie = `consent_pinterest=${this.form.controls['consent_pinterest'].value};domain=talentagent.de`
    document.cookie = `consent_google_ads=${this.form.controls['consent_google_ads'].value};domain=talentagent.de`
    document.cookie = `consent_piwik=${this.form.controls['consent_piwik'].value};domain=talentagent.de`
    document.cookie = `consent_tiktok=${this.form.controls['consent_tiktok'].value};domain=talentagent.de`
    document.cookie = `consent_hotjar=${this.form.controls['consent_hotjar'].value};domain=talentagent.de`
    document.cookie = `consent_leadfeeder=${this.form.controls['consent_leadfeeder'].value};domain=talentagent.de`

    this.finish()
  }


  finish() {
    this.close.next(null)
    if (this.initialConsent) {
      this.analyticsService.reportPageView(false)
    }
  }

  routePrivacy() {
    this.router.navigateByUrl("/datenschutz")
    this.close.next(null);
  }

}
