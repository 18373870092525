import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {CompanyCreationData, CompanyData, UnisignAccountRegistrationSuccessData, CompanyOnboardingData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyOnboardingController
 */

@Injectable()
@ResourceParams({})
export class CompanyOnboardingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/company',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createCompany: IResourceMethodObservableStrict<CompanyCreationData, null, null, CompanyData> | undefined
    createCompany(requestBody: CompanyCreationData): Promise<CompanyData> {
        if (!this._createCompany) throw new Error("resource has not been properly initialized")
        return this._createCompany(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/registration',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createCompanyAndRecruiter: IResourceMethodObservableStrict<CompanyOnboardingData, null, null, UnisignAccountRegistrationSuccessData> | undefined
    createCompanyAndRecruiter(requestBody: CompanyOnboardingData): Promise<UnisignAccountRegistrationSuccessData> {
        if (!this._createCompanyAndRecruiter) throw new Error("resource has not been properly initialized")
        return this._createCompanyAndRecruiter(requestBody, null, null).toPromise()
    }

}