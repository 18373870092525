import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantProfessionFieldsController
 */

@Injectable()
@ResourceParams({})
export class ConsultantProfessionFieldsResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/professionFields',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredProfessionFields: IResourceMethodObservableStrict<null, {q?: string, forceIds: number[]}, null, LabelData[]> | undefined
    getFilteredProfessionFields(requestParams: {q?: string, forceIds: number[]}): Promise<LabelData[]> {
        if (!this._getFilteredProfessionFields) throw new Error("resource has not been properly initialized")
        return this._getFilteredProfessionFields(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/professionGroups/ids',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionFieldsByIds: IResourceMethodObservableStrict<null, {ids: number[]}, null, LabelData[]> | undefined
    getProfessionFieldsByIds(requestParams: {ids: number[]}): Promise<LabelData[]> {
        if (!this._getProfessionFieldsByIds) throw new Error("resource has not been properly initialized")
        return this._getProfessionFieldsByIds(null, requestParams, null).toPromise()
    }

}