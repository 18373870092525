import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.common.controllers.StatusController
 */

@Injectable()
@ResourceParams({})
export class StatusResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/status/live',
        endpoint: 'com.uniwunder.talentagent.common'
    })
    private _live: IResourceMethodObservableStrict<null, null, null, any> | undefined
    live(): Promise<any> {
        if (!this._live) throw new Error("resource has not been properly initialized")
        return this._live(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/status/ready',
        endpoint: 'com.uniwunder.talentagent.common'
    })
    private _ready: IResourceMethodObservableStrict<null, null, null, any> | undefined
    ready(): Promise<any> {
        if (!this._ready) throw new Error("resource has not been properly initialized")
        return this._ready(null, null, null).toPromise()
    }

}