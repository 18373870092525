import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, CreateConsultantAvailabilityData, ConsultantAppointmentRequestData, ConsultantAvailabilityData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantAppointmentController
 */

@Injectable()
@ResourceParams({})
export class ConsultantAppointmentResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultant/appointments/accept',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _acceptConsultantAppointmentRequest: IResourceMethodObservableStrict<null, {requestId: number}, null, null> | undefined
    acceptConsultantAppointmentRequest(requestParams: {requestId: number}): Promise<null> {
        if (!this._acceptConsultantAppointmentRequest) throw new Error("resource has not been properly initialized")
        return this._acceptConsultantAppointmentRequest(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultant/availability/create',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createConsultantAvailability: IResourceMethodObservableStrict<CreateConsultantAvailabilityData, null, null, JsonWrappedValue<boolean>> | undefined
    createConsultantAvailability(requestBody: CreateConsultantAvailabilityData): Promise<JsonWrappedValue<boolean>> {
        if (!this._createConsultantAvailability) throw new Error("resource has not been properly initialized")
        return this._createConsultantAvailability(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultant/availability/delete',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deleteConsultantAvailability: IResourceMethodObservableStrict<null, {availabilityId: number}, null, null> | undefined
    deleteConsultantAvailability(requestParams: {availabilityId: number}): Promise<null> {
        if (!this._deleteConsultantAvailability) throw new Error("resource has not been properly initialized")
        return this._deleteConsultantAvailability(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultant/appointments/deny',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _denyConsultantAppointmentRequest: IResourceMethodObservableStrict<null, {requestId: number}, null, null> | undefined
    denyConsultantAppointmentRequest(requestParams: {requestId: number}): Promise<null> {
        if (!this._denyConsultantAppointmentRequest) throw new Error("resource has not been properly initialized")
        return this._denyConsultantAppointmentRequest(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/availabilities',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getConsultantAvailabilities: IResourceMethodObservableStrict<null, null, null, ConsultantAvailabilityData[]> | undefined
    getConsultantAvailabilities(): Promise<ConsultantAvailabilityData[]> {
        if (!this._getConsultantAvailabilities) throw new Error("resource has not been properly initialized")
        return this._getConsultantAvailabilities(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/appointments/requests',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOpenConsultantAppointmentRequests: IResourceMethodObservableStrict<null, null, null, ConsultantAppointmentRequestData[]> | undefined
    getOpenConsultantAppointmentRequests(): Promise<ConsultantAppointmentRequestData[]> {
        if (!this._getOpenConsultantAppointmentRequests) throw new Error("resource has not been properly initialized")
        return this._getOpenConsultantAppointmentRequests(null, null, null).toPromise()
    }

}