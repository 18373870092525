import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {SupportMessageData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.SupportController
 */

@Injectable()
@ResourceParams({})
export class SupportResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/support/message',
        endpoint: 'net.innospire.hidcms'
    })
    private _sendSupportMessage: IResourceMethodObservableStrict<SupportMessageData, null, null, null> | undefined
    sendSupportMessage(requestBody: SupportMessageData): Promise<null> {
        if (!this._sendSupportMessage) throw new Error("resource has not been properly initialized")
        return this._sendSupportMessage(requestBody, null, null).toPromise()
    }

}