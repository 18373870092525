<section #contactSection id="contactSection"
         class="flex column center cap-width padding contact-section">
    <h2 class="big-section-header padding-vertical-lg">Lerne Talentagent kennen</h2>

    <div *ngIf="!showRecallRequestSend" class="flex row width-100 start contact-content">
        <div class="bullet-points">
            <h3 class="remove-margin">Vereinbare einen Rückruf und erlebe, wie Talentagent dein
                Absolventenrecruiting vereinfacht</h3>

            <ul>
                <li>Erfahre, wie du über Talentagent die richtigen Nachwuchskräfte findest</li>
                <li>Erhalte detaillierte Einblicke in die Plattform</li>
                <li>Lerne, wie deine Stellenanzeige junge Talente begeistert</li>
            </ul>
        </div>
        <form [formGroup]="contactGroup" *ngIf="contactGroup" class="flex column gap">

            <div class="input-wrapper flex row gap">
                <div class="input-wrapper">
                    <div class="input-headline">Vorname*</div>
                    <input
                            [ngClass]="{
          'large-input': mediaService.isMobile(),
          'invalid-input': contactGroup.controls['firstName'].invalid && submitted}"
                            type="text"
                            #email
                            placeholder="Vorname*"
                            name="firstName"
                            formControlName="firstName"
                    />
                    <div *ngIf="contactGroup.controls['firstName'].errors && submitted" class="invalid-text">Bitte geben
                        Sie ihren Vornamen ein
                    </div>
                </div>

                <div class="input-wrapper">
                    <div class="input-headline">Nachname*</div>
                    <input
                            [ngClass]="{
          'large-input': mediaService.isMobile(),
          'invalid-input': contactGroup.controls['lastName'].invalid && submitted}"
                            type="text"
                            #email
                            placeholder="Nachname*"
                            name="lastName"
                            formControlName="lastName"
                    />
                    <div *ngIf="contactGroup.controls['lastName'].errors && submitted" class="invalid-text">Bitte geben
                        Sie ihren Nachnamen ein
                    </div>
                </div>
            </div>

            <div class="flex row gap input-wrapper">
                <div class="input-wrapper">
                    <div class="input-headline">E-Mail*</div>
                    <input
                            [ngClass]="{
          'large-input': mediaService.isMobile(),
          'invalid-input': contactGroup.controls['email'].invalid && submitted}"
                            type="email"
                            #email
                            placeholder="E-Mail*"
                            name="email"
                            formControlName="email"
                    />
                    <div *ngIf="contactGroup.controls['email'].errors && submitted" class="invalid-text">Bitte geben Sie
                        eine gültige E-Mail ein.
                    </div>
                </div>

                <div class="input-wrapper">
                    <div class="input-headline">Telefonnummer*</div>
                    <input
                            [ngClass]="{
          'large-input': mediaService.isMobile(),
          'invalid-input': contactGroup.controls['phone'].invalid && submitted}"
                            type="tel"
                            #email
                            placeholder="Telefonnummer*"
                            name="phone"
                            formControlName="phone"
                    />
                    <div *ngIf="contactGroup.controls['phone'].errors && submitted" class="invalid-text">Diese
                        Telefonnummer hat ein ungültiges Format
                    </div>
                </div>
            </div>

            <div class="input-wrapper">
                <div class="input-headline">Unternehmen (optional)</div>
                <input
                        [ngClass]="{
          'large-input': mediaService.isMobile(),
          'invalid-input': contactGroup.controls['companyName'].invalid && submitted}"
                        type="text"
                        #email
                        placeholder="Unternehmen (optional)"
                        name="companyName"
                        formControlName="companyName"
                />
            </div>

            <div class="input-wrapper">
                <div class="input-headline">Gewünschte Absolvent:innen pro Jahr (optional)</div>
                <input
                        [ngClass]="{
          'large-input': mediaService.isMobile(),
          'invalid-input': contactGroup.controls['talentCount'].invalid && submitted}"
                        type="number"
                        #email
                        placeholder="Gewünschte Absolvent:innen pro Jahr (optional)"
                        name="talentCount"
                        formControlName="talentCount"
                />
                <div *ngIf="contactGroup.controls['talentCount'].errors && submitted" class="invalid-text">Bitte
                    geben Sie eine gültige Anzahl gewünschter Einstellungen pro Jahr ein
                </div>
            </div>


            <div class="input-wrapper">
                <div class="input-headline">Wie bist du auf uns aufmerksam geworden? (optional)</div>
                <select id="referer" formControlName="referer">
                    <option value="">Bitte auswählen</option>
                    <option *ngFor="let option of refererOptions" [value]="option">{{option}}</option>
                </select>
                <div *ngIf="contactGroup.controls['talentCount'].errors && submitted" class="invalid-text">Bitte
                    geben Sie eine gültige Anzahl gewünschter Einstellungen pro Jahr ein
                </div>
            </div>



            <mat-checkbox formControlName="optIn">
                <div class="small-text">
                    Ich akzeptiere die
                    <a class="small-text" [href]="getUseRegulationUrl()" target="_blank">Nutzerbestimmungen</a> und
                    die Bestimmungen zum
                    <a class="small-text" [href]="getPrivacyUrl()" target="_blank">Datenschutz</a> und bin mit den
                    dort erklärten Einwilligungen einverstanden *
                </div>
            </mat-checkbox>


            <div class="invalid-text" *ngIf="contactGroup.controls['optIn'].errors && submitted">
                Bitte akzeptiere unsere Nutzungsbestimmungen und unsere Bestimmungen zum Datenschutz
            </div>

            <button (click)="requestDemoCall()" class="accent-button">Jetzt Rückruf vereinbaren</button>
        </form>
    </div>

    <div *ngIf="showRecallRequestSend">
        <h3 class="contact-sub-headline">Wir melden uns nach Erhalt deiner Anfrage bei dir</h3>
        <div class="wrapper">
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
        </div>
    </div>
</section>
