import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicImageController
 */

@Injectable()
@ResourceParams({})
export class PublicImageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/images/containers/{id}',
        endpoint: 'net.innospire.hidcms'
    })
    private _getContainerImage: IResourceMethodObservableStrict<null, {width?: number, height?: number}, {id: number}, Blob> | undefined
    getContainerImage(id: number, requestParams?: {width?: number, height?: number}): Promise<Blob> {
        if (!this._getContainerImage) throw new Error("resource has not been properly initialized")
        return this._getContainerImage(null, requestParams || {}, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/upload_image/{id}',
        endpoint: 'net.innospire.hidcms'
    })
    private _getInlineImage: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getInlineImage(id: number): Promise<Blob> {
        if (!this._getInlineImage) throw new Error("resource has not been properly initialized")
        return this._getInlineImage(null, null, {id: id}).toPromise()
    }

}