import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, CompanyProfileData, CompanyProfileAttachmentData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyProfileController
 */

@Injectable()
@ResourceParams({})
export class CompanyProfileResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/company-profile/bannerImage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addBannerImage: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, null, JsonWrappedValue<number>> | undefined
    addBannerImage(requestBody: ArrayBuffer, requestParams: {fileName: string}): Promise<JsonWrappedValue<number>> {
        if (!this._addBannerImage) throw new Error("resource has not been properly initialized")
        return this._addBannerImage(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/company-profile/contactImage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addContactImage: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, null, JsonWrappedValue<number>> | undefined
    addContactImage(requestBody: ArrayBuffer, requestParams: {fileName: string}): Promise<JsonWrappedValue<number>> {
        if (!this._addContactImage) throw new Error("resource has not been properly initialized")
        return this._addContactImage(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/company-profile/bannerImage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getBannerImage: IResourceMethodObservableStrict<null, null, null, Blob> | undefined
    getBannerImage(): Promise<Blob> {
        if (!this._getBannerImage) throw new Error("resource has not been properly initialized")
        return this._getBannerImage(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/company-profile/logo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyLogo: IResourceMethodObservableStrict<null, null, null, Blob> | undefined
    getCompanyLogo(): Promise<Blob> {
        if (!this._getCompanyLogo) throw new Error("resource has not been properly initialized")
        return this._getCompanyLogo(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/company-profile',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyProfileData: IResourceMethodObservableStrict<null, null, null, CompanyProfileData> | undefined
    getCompanyProfileData(): Promise<CompanyProfileData> {
        if (!this._getCompanyProfileData) throw new Error("resource has not been properly initialized")
        return this._getCompanyProfileData(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/company-profile/contactImage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getContactImage: IResourceMethodObservableStrict<null, null, null, Blob> | undefined
    getContactImage(): Promise<Blob> {
        if (!this._getContactImage) throw new Error("resource has not been properly initialized")
        return this._getContactImage(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/company-profile/bannerImage/delete',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeBannerImage: IResourceMethodObservableStrict<null, null, null, null> | undefined
    removeBannerImage(): Promise<null> {
        if (!this._removeBannerImage) throw new Error("resource has not been properly initialized")
        return this._removeBannerImage(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/company-profile/contactImage/delete',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeContactImage: IResourceMethodObservableStrict<null, null, null, null> | undefined
    removeContactImage(): Promise<null> {
        if (!this._removeContactImage) throw new Error("resource has not been properly initialized")
        return this._removeContactImage(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/company-profile/galleryImages/delete/{fileId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeImageFromGallery: IResourceMethodObservableStrict<null, null, {fileId: number}, null> | undefined
    removeImageFromGallery(fileId: number): Promise<null> {
        if (!this._removeImageFromGallery) throw new Error("resource has not been properly initialized")
        return this._removeImageFromGallery(null, null, {fileId: fileId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/company-profile/logo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateCompanyLogo: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, null, null> | undefined
    updateCompanyLogo(requestBody: ArrayBuffer, requestParams: {fileName: string}): Promise<null> {
        if (!this._updateCompanyLogo) throw new Error("resource has not been properly initialized")
        return this._updateCompanyLogo(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/company-profile',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateCompanyProfile: IResourceMethodObservableStrict<CompanyProfileData, null, null, null> | undefined
    updateCompanyProfile(requestBody: CompanyProfileData): Promise<null> {
        if (!this._updateCompanyProfile) throw new Error("resource has not been properly initialized")
        return this._updateCompanyProfile(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/company-profile/galleryImages/add',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _uploadImageToGallery: IResourceMethodObservableStrict<ArrayBuffer, {customFileName: string, originalFileName: string}, null, CompanyProfileAttachmentData> | undefined
    uploadImageToGallery(requestBody: ArrayBuffer, requestParams: {customFileName: string, originalFileName: string}): Promise<CompanyProfileAttachmentData> {
        if (!this._uploadImageToGallery) throw new Error("resource has not been properly initialized")
        return this._uploadImageToGallery(requestBody, requestParams, null).toPromise()
    }

}