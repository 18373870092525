import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {PublicCompanyProfileData, TalentCompanyDisplayData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentCompanyProfileController
 */

@Injectable()
@ResourceParams({})
export class TalentCompanyProfileResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/companyProfiles/companies/{id}/profile',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyProfileDataForCompany: IResourceMethodObservableStrict<null, null, {id: number}, PublicCompanyProfileData> | undefined
    getCompanyProfileDataForCompany(id: number): Promise<PublicCompanyProfileData> {
        if (!this._getCompanyProfileDataForCompany) throw new Error("resource has not been properly initialized")
        return this._getCompanyProfileDataForCompany(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/companyProfiles/spotlightBundle',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSpotlightBundle: IResourceMethodObservableStrict<null, null, null, TalentCompanyDisplayData[]> | undefined
    getSpotlightBundle(): Promise<TalentCompanyDisplayData[]> {
        if (!this._getSpotlightBundle) throw new Error("resource has not been properly initialized")
        return this._getSpotlightBundle(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/companyProfiles/search',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _searchCompanyProfiles: IResourceMethodObservableStrict<null, {pageNum?: number, pageSize?: number, query: string}, null, PagedData<TalentCompanyDisplayData>> | undefined
    searchCompanyProfiles(requestParams: {pageNum?: number, pageSize?: number, query: string}): Promise<PagedData<TalentCompanyDisplayData>> {
        if (!this._searchCompanyProfiles) throw new Error("resource has not been properly initialized")
        return this._searchCompanyProfiles(null, requestParams, null).toPromise()
    }

}