import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {SupportData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentSupportController
 */

@Injectable()
@ResourceParams({})
export class TalentSupportResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/support/sendMail',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendMail: IResourceMethodObservableStrict<SupportData, null, null, null> | undefined
    sendMail(requestBody: SupportData): Promise<null> {
        if (!this._sendMail) throw new Error("resource has not been properly initialized")
        return this._sendMail(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/support/sendPrimeUserProblemMail',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendPrimeUserProblemMail: IResourceMethodObservableStrict<SupportData, null, null, null> | undefined
    sendPrimeUserProblemMail(requestBody: SupportData): Promise<null> {
        if (!this._sendPrimeUserProblemMail) throw new Error("resource has not been properly initialized")
        return this._sendPrimeUserProblemMail(requestBody, null, null).toPromise()
    }

}