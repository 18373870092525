import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TalentPageComponent} from "./talent-page.component";
import {CareerGuideModule} from "../career-guide/career-guide.module";
import {UtilityModule} from "../../utility/utility.module";
import {MlpInformationDialogComponent} from "./mlp-information-dialog/mlp-information-dialog.component";
import {MatDialogActions} from "@angular/material/dialog";
import {RouterLink} from "@angular/router";


@NgModule({
  declarations: [
    TalentPageComponent,
    MlpInformationDialogComponent
  ],
    imports: [
        CommonModule,
        CareerGuideModule,
        UtilityModule,
        MatDialogActions,
        RouterLink
    ]
})
export class TalentPageModule { }
