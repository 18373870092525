import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DemoBookingComponent} from "./demo-booking.component";
import {RouterModule} from "@angular/router";



@NgModule({
    declarations: [
        DemoBookingComponent
    ],
    exports: [
        DemoBookingComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class DemoBookingModule { }
