import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {PublicOfferBundleWithPreviewsData, OnlineWorkshopDropdownItemData, PublicOfferBundleData, PublicOfferBundleMinimalData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicBundleController
 */

@Injectable()
@ResourceParams({})
export class PublicBundleResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundles/id/{bundleId}',
        endpoint: 'net.innospire.hidcms'
    })
    private _getBundleById: IResourceMethodObservableStrict<null, null, {bundleId: number}, PublicOfferBundleData> | undefined
    getBundleById(bundleId: number): Promise<PublicOfferBundleData> {
        if (!this._getBundleById) throw new Error("resource has not been properly initialized")
        return this._getBundleById(null, null, {bundleId: bundleId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundles/{routingUrl}',
        endpoint: 'net.innospire.hidcms'
    })
    private _getBundleByRoutingUrl: IResourceMethodObservableStrict<null, null, {routingUrl: string}, PublicOfferBundleData> | undefined
    getBundleByRoutingUrl(routingUrl: string): Promise<PublicOfferBundleData> {
        if (!this._getBundleByRoutingUrl) throw new Error("resource has not been properly initialized")
        return this._getBundleByRoutingUrl(null, null, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundles/{routingUrl}/withPreviews',
        endpoint: 'net.innospire.hidcms'
    })
    private _getBundleWithPreviews: IResourceMethodObservableStrict<null, null, {routingUrl: string}, PublicOfferBundleWithPreviewsData> | undefined
    getBundleWithPreviews(routingUrl: string): Promise<PublicOfferBundleWithPreviewsData> {
        if (!this._getBundleWithPreviews) throw new Error("resource has not been properly initialized")
        return this._getBundleWithPreviews(null, null, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundles',
        endpoint: 'net.innospire.hidcms'
    })
    private _getBundles: IResourceMethodObservableStrict<null, null, null, PublicOfferBundleData[]> | undefined
    getBundles(): Promise<PublicOfferBundleData[]> {
        if (!this._getBundles) throw new Error("resource has not been properly initialized")
        return this._getBundles(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundlesOffersDropdown',
        endpoint: 'net.innospire.hidcms'
    })
    private _getBundlesForOffersDropdown: IResourceMethodObservableStrict<null, null, null, PublicOfferBundleMinimalData[]> | undefined
    getBundlesForOffersDropdown(): Promise<PublicOfferBundleMinimalData[]> {
        if (!this._getBundlesForOffersDropdown) throw new Error("resource has not been properly initialized")
        return this._getBundlesForOffersDropdown(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundles/onlineWorkshopOffersDropdown',
        endpoint: 'net.innospire.hidcms'
    })
    private _getOffersForOnlineWorkshopDropdown: IResourceMethodObservableStrict<null, null, null, OnlineWorkshopDropdownItemData[]> | undefined
    getOffersForOnlineWorkshopDropdown(): Promise<OnlineWorkshopDropdownItemData[]> {
        if (!this._getOffersForOnlineWorkshopDropdown) throw new Error("resource has not been properly initialized")
        return this._getOffersForOnlineWorkshopDropdown(null, null, null).toPromise()
    }

}