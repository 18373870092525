import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LegalPrivacyComponent} from "./legal-privacy/legal-privacy.component";
import {LegalImprintComponent} from "./legal-imprint/legal-imprint.component";
import {LegalUseRegulationsComponent} from "./legal-use-regulations/legal-use-regulations.component";



@NgModule({
  declarations: [
    LegalPrivacyComponent,
    LegalImprintComponent,
    LegalUseRegulationsComponent
  ],
  imports: [
    CommonModule
  ]
})
export class LegalModule { }
