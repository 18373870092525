import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {TalentCoachRequestDistributionData, TalentCochingOnboardingCreationData, UnisignAccountRegistrationSuccessData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.onboarding.TalentCoachingOnboardingController
 */

@Injectable()
@ResourceParams({})
export class TalentCoachingOnboardingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/coach/account',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createTalentAccountAndLogin: IResourceMethodObservableStrict<TalentCochingOnboardingCreationData, null, null, UnisignAccountRegistrationSuccessData> | undefined
    createTalentAccountAndLogin(requestBody: TalentCochingOnboardingCreationData): Promise<UnisignAccountRegistrationSuccessData> {
        if (!this._createTalentAccountAndLogin) throw new Error("resource has not been properly initialized")
        return this._createTalentAccountAndLogin(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/onboarding/coach/distribution',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateDistributionCriteriaForCoachRequest: IResourceMethodObservableStrict<TalentCoachRequestDistributionData, null, null, null> | undefined
    updateDistributionCriteriaForCoachRequest(requestBody: TalentCoachRequestDistributionData): Promise<null> {
        if (!this._updateDistributionCriteriaForCoachRequest) throw new Error("resource has not been properly initialized")
        return this._updateDistributionCriteriaForCoachRequest(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/coach/study',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateStudyAndPreferencesForTalent: IResourceMethodObservableStrict<TalentCochingOnboardingCreationData, null, null, null> | undefined
    updateStudyAndPreferencesForTalent(requestBody: TalentCochingOnboardingCreationData): Promise<null> {
        if (!this._updateStudyAndPreferencesForTalent) throw new Error("resource has not been properly initialized")
        return this._updateStudyAndPreferencesForTalent(requestBody, null, null).toPromise()
    }

}