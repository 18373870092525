import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, RecruiterNotificationConfigurationData, PagedData, NotificationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyNotificationController
 */

@Injectable()
@ResourceParams({})
export class CompanyNotificationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/user/recruiter/notifications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllNotificationsForCompany: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, null, PagedData<NotificationData>> | undefined
    getAllNotificationsForCompany(requestParams: {page: number, pageSize: number}): Promise<PagedData<NotificationData>> {
        if (!this._getAllNotificationsForCompany) throw new Error("resource has not been properly initialized")
        return this._getAllNotificationsForCompany(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/notifications/unseen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCountOfUnseenNotificationsForCompany: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getCountOfUnseenNotificationsForCompany(): Promise<number> {
        if (!this._getCountOfUnseenNotificationsForCompany) throw new Error("resource has not been properly initialized")
        return this._getCountOfUnseenNotificationsForCompany(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/user/recruiter/notifications/order',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getNotificationsForCompany: IResourceMethodObservableStrict<null, null, null, NotificationData[]> | undefined
    getNotificationsForCompany(): Promise<NotificationData[]> {
        if (!this._getNotificationsForCompany) throw new Error("resource has not been properly initialized")
        return this._getNotificationsForCompany(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/user/recruiter/notifications/top',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTopThreeNotificationsForCompany: IResourceMethodObservableStrict<null, null, null, NotificationData[]> | undefined
    getTopThreeNotificationsForCompany(): Promise<NotificationData[]> {
        if (!this._getTopThreeNotificationsForCompany) throw new Error("resource has not been properly initialized")
        return this._getTopThreeNotificationsForCompany(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/recruiter/notifications/configuration',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUserNotificationConfiguration: IResourceMethodObservableStrict<null, null, null, RecruiterNotificationConfigurationData> | undefined
    getUserNotificationConfiguration(): Promise<RecruiterNotificationConfigurationData> {
        if (!this._getUserNotificationConfiguration) throw new Error("resource has not been properly initialized")
        return this._getUserNotificationConfiguration(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/notifications/seen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _setUserNotificationsToSeenForCompany: IResourceMethodObservableStrict<null, null, null, null> | undefined
    setUserNotificationsToSeenForCompany(): Promise<null> {
        if (!this._setUserNotificationsToSeenForCompany) throw new Error("resource has not been properly initialized")
        return this._setUserNotificationsToSeenForCompany(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/recruiter/notifications/configuration',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateUserNotificationConfiguration: IResourceMethodObservableStrict<RecruiterNotificationConfigurationData, null, null, null> | undefined
    updateUserNotificationConfiguration(requestBody: RecruiterNotificationConfigurationData): Promise<null> {
        if (!this._updateUserNotificationConfiguration) throw new Error("resource has not been properly initialized")
        return this._updateUserNotificationConfiguration(requestBody, null, null).toPromise()
    }

}