import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {ProfessionSelectionData, ProfessionTypeData, ProfessionAreaData, ProfessionWithMappingInfoData, ProfessionSubGroupData, ProfessionData, ProfessionAssigmentCountData, FieldOfActivityRelationData, ProfessionGroupData, ProfessionMainGroupData, PagedData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminProfessionController
 */

@Injectable()
@ResourceParams({})
export class AdminProfessionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professions/suggestion',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getBestMatchingProfessionForPositionTitle: IResourceMethodObservableStrict<null, {title: string}, null, ProfessionData[]> | undefined
    getBestMatchingProfessionForPositionTitle(requestParams: {title: string}): Promise<ProfessionData[]> {
        if (!this._getBestMatchingProfessionForPositionTitle) throw new Error("resource has not been properly initialized")
        return this._getBestMatchingProfessionForPositionTitle(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professions/{id}/fields',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFieldsForProfession: IResourceMethodObservableStrict<null, null, {id: number}, FieldOfActivityRelationData[]> | undefined
    getFieldsForProfession(id: number): Promise<FieldOfActivityRelationData[]> {
        if (!this._getFieldsForProfession) throw new Error("resource has not been properly initialized")
        return this._getFieldsForProfession(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professions/filtered',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredProfessions: IResourceMethodObservableStrict<null, {withoutSgMapping: boolean, query?: string, professionTypeIds?: number[], professionSubGroupIds?: number[], professionGroupIds?: number[], professionMainGroupIds?: number[], professionAreaIds?: number[], page?: number, pageSize?: number}, null, PagedData<ProfessionData>> | undefined
    getFilteredProfessions(requestParams: {withoutSgMapping: boolean, query?: string, professionTypeIds?: number[], professionSubGroupIds?: number[], professionGroupIds?: number[], professionMainGroupIds?: number[], professionAreaIds?: number[], page?: number, pageSize?: number}): Promise<PagedData<ProfessionData>> {
        if (!this._getFilteredProfessions) throw new Error("resource has not been properly initialized")
        return this._getFilteredProfessions(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professions/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfession: IResourceMethodObservableStrict<null, null, {id: number}, ProfessionData> | undefined
    getProfession(id: number): Promise<ProfessionData> {
        if (!this._getProfession) throw new Error("resource has not been properly initialized")
        return this._getProfession(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionAreas/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionArea: IResourceMethodObservableStrict<null, null, {id: number}, ProfessionAreaData> | undefined
    getProfessionArea(id: number): Promise<ProfessionAreaData> {
        if (!this._getProfessionArea) throw new Error("resource has not been properly initialized")
        return this._getProfessionArea(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionAreas',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionAreas: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<LabelData>> | undefined
    getProfessionAreas(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<LabelData>> {
        if (!this._getProfessionAreas) throw new Error("resource has not been properly initialized")
        return this._getProfessionAreas(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/professionAreas',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _getProfessionAreasByIds: IResourceMethodObservableStrict<number[], null, null, ProfessionAreaData[]> | undefined
    getProfessionAreasByIds(requestBody: number[]): Promise<ProfessionAreaData[]> {
        if (!this._getProfessionAreasByIds) throw new Error("resource has not been properly initialized")
        return this._getProfessionAreasByIds(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionFields',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionFields: IResourceMethodObservableStrict<null, {searchTerm: string}, null, LabelData[]> | undefined
    getProfessionFields(requestParams: {searchTerm: string}): Promise<LabelData[]> {
        if (!this._getProfessionFields) throw new Error("resource has not been properly initialized")
        return this._getProfessionFields(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionGroups/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionGroup: IResourceMethodObservableStrict<null, null, {id: number}, ProfessionGroupData> | undefined
    getProfessionGroup(id: number): Promise<ProfessionGroupData> {
        if (!this._getProfessionGroup) throw new Error("resource has not been properly initialized")
        return this._getProfessionGroup(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionGroups',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionGroups: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<LabelData>> | undefined
    getProfessionGroups(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<LabelData>> {
        if (!this._getProfessionGroups) throw new Error("resource has not been properly initialized")
        return this._getProfessionGroups(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/professionGroups',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _getProfessionGroupsByIds: IResourceMethodObservableStrict<number[], null, null, ProfessionGroupData[]> | undefined
    getProfessionGroupsByIds(requestBody: number[]): Promise<ProfessionGroupData[]> {
        if (!this._getProfessionGroupsByIds) throw new Error("resource has not been properly initialized")
        return this._getProfessionGroupsByIds(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionMainGroups/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionMainGroup: IResourceMethodObservableStrict<null, null, {id: number}, ProfessionMainGroupData> | undefined
    getProfessionMainGroup(id: number): Promise<ProfessionMainGroupData> {
        if (!this._getProfessionMainGroup) throw new Error("resource has not been properly initialized")
        return this._getProfessionMainGroup(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionMainGroups',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionMainGroups: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<LabelData>> | undefined
    getProfessionMainGroups(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<LabelData>> {
        if (!this._getProfessionMainGroups) throw new Error("resource has not been properly initialized")
        return this._getProfessionMainGroups(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/professionMainGroups',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _getProfessionMainGroupsByIds: IResourceMethodObservableStrict<number[], null, null, ProfessionMainGroupData[]> | undefined
    getProfessionMainGroupsByIds(requestBody: number[]): Promise<ProfessionMainGroupData[]> {
        if (!this._getProfessionMainGroupsByIds) throw new Error("resource has not been properly initialized")
        return this._getProfessionMainGroupsByIds(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionSubGroups/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionSubGroup: IResourceMethodObservableStrict<null, null, {id: number}, ProfessionSubGroupData> | undefined
    getProfessionSubGroup(id: number): Promise<ProfessionSubGroupData> {
        if (!this._getProfessionSubGroup) throw new Error("resource has not been properly initialized")
        return this._getProfessionSubGroup(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionSubGroups',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionSubGroups: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<LabelData>> | undefined
    getProfessionSubGroups(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<LabelData>> {
        if (!this._getProfessionSubGroups) throw new Error("resource has not been properly initialized")
        return this._getProfessionSubGroups(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/professionSubGroups',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _getProfessionSubGroupsByIds: IResourceMethodObservableStrict<number[], null, null, ProfessionSubGroupData[]> | undefined
    getProfessionSubGroupsByIds(requestBody: number[]): Promise<ProfessionSubGroupData[]> {
        if (!this._getProfessionSubGroupsByIds) throw new Error("resource has not been properly initialized")
        return this._getProfessionSubGroupsByIds(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionTypes/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionType: IResourceMethodObservableStrict<null, null, {id: number}, ProfessionTypeData> | undefined
    getProfessionType(id: number): Promise<ProfessionTypeData> {
        if (!this._getProfessionType) throw new Error("resource has not been properly initialized")
        return this._getProfessionType(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionsTypes',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionTypes: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<LabelData>> | undefined
    getProfessionTypes(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<LabelData>> {
        if (!this._getProfessionTypes) throw new Error("resource has not been properly initialized")
        return this._getProfessionTypes(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/professionTypes',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _getProfessionTypesByIds: IResourceMethodObservableStrict<number[], null, null, ProfessionTypeData[]> | undefined
    getProfessionTypesByIds(requestBody: number[]): Promise<ProfessionTypeData[]> {
        if (!this._getProfessionTypesByIds) throw new Error("resource has not been properly initialized")
        return this._getProfessionTypesByIds(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professions/{id}/withMappingInfo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionWithMappingInfo: IResourceMethodObservableStrict<null, null, {id: number}, ProfessionWithMappingInfoData> | undefined
    getProfessionWithMappingInfo(id: number): Promise<ProfessionWithMappingInfoData> {
        if (!this._getProfessionWithMappingInfo) throw new Error("resource has not been properly initialized")
        return this._getProfessionWithMappingInfo(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professions/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessions: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<ProfessionData>> | undefined
    getProfessions(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<ProfessionData>> {
        if (!this._getProfessions) throw new Error("resource has not been properly initialized")
        return this._getProfessions(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professionLabels/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionsAsLabelData: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<LabelData>> | undefined
    getProfessionsAsLabelData(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<LabelData>> {
        if (!this._getProfessionsAsLabelData) throw new Error("resource has not been properly initialized")
        return this._getProfessionsAsLabelData(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/professions',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _getProfessionsByIds: IResourceMethodObservableStrict<number[], null, null, ProfessionData[]> | undefined
    getProfessionsByIds(requestBody: number[]): Promise<ProfessionData[]> {
        if (!this._getProfessionsByIds) throw new Error("resource has not been properly initialized")
        return this._getProfessionsByIds(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professions/fieldOptimazation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionsForFieldOptimazation: IResourceMethodObservableStrict<null, {q: string, page?: number, pageSize?: number}, null, PagedData<ProfessionAssigmentCountData>> | undefined
    getProfessionsForFieldOptimazation(requestParams: {q: string, page?: number, pageSize?: number}): Promise<PagedData<ProfessionAssigmentCountData>> {
        if (!this._getProfessionsForFieldOptimazation) throw new Error("resource has not been properly initialized")
        return this._getProfessionsForFieldOptimazation(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professions/selectiondata',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionsSelectionData: IResourceMethodObservableStrict<null, {query?: string, page: number, pageSize: number}, null, PagedData<ProfessionSelectionData>> | undefined
    getProfessionsSelectionData(requestParams: {query?: string, page: number, pageSize: number}): Promise<PagedData<ProfessionSelectionData>> {
        if (!this._getProfessionsSelectionData) throw new Error("resource has not been properly initialized")
        return this._getProfessionsSelectionData(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/professions/{id}/professionFields',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRelevantFieldsForProfession: IResourceMethodObservableStrict<null, null, {id: number}, LabelData[]> | undefined
    getRelevantFieldsForProfession(id: number): Promise<LabelData[]> {
        if (!this._getRelevantFieldsForProfession) throw new Error("resource has not been properly initialized")
        return this._getRelevantFieldsForProfession(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/professions/updateFieldQualification',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _updateProfessionFieldQualification: IResourceMethodObservableStrict<FieldOfActivityRelationData[], {professionId: number}, null, null> | undefined
    updateProfessionFieldQualification(requestBody: FieldOfActivityRelationData[], requestParams: {professionId: number}): Promise<null> {
        if (!this._updateProfessionFieldQualification) throw new Error("resource has not been properly initialized")
        return this._updateProfessionFieldQualification(requestBody, requestParams, null).toPromise()
    }

}