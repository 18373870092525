import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {ProfileAttachmentData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentProfileAttachmentController
 */

@Injectable()
@ResourceParams({})
export class TalentProfileAttachmentResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talentProfile/profileAttachments/file',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _createProfileAttachment: IResourceMethodObservableStrict<{description: string, file: File, fileName: string}, null, null, null> | undefined
    createProfileAttachment(requestBody: {description: string, file: File, fileName: string}): Promise<null> {
        if (!this._createProfileAttachment) throw new Error("resource has not been properly initialized")
        return this._createProfileAttachment(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talentProfile/profileAttachment/{id}/file',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOwnProfileAttachmentFile: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getOwnProfileAttachmentFile(id: number): Promise<Blob> {
        if (!this._getOwnProfileAttachmentFile) throw new Error("resource has not been properly initialized")
        return this._getOwnProfileAttachmentFile(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talentProfile/profileAttachments/attachments',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOwnProfileAttachmentFiles: IResourceMethodObservableStrict<null, null, null, ProfileAttachmentData[]> | undefined
    getOwnProfileAttachmentFiles(): Promise<ProfileAttachmentData[]> {
        if (!this._getOwnProfileAttachmentFiles) throw new Error("resource has not been properly initialized")
        return this._getOwnProfileAttachmentFiles(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talentProfile/profileAttachments',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOwnProfileAttachments: IResourceMethodObservableStrict<null, null, null, ProfileAttachmentData[]> | undefined
    getOwnProfileAttachments(): Promise<ProfileAttachmentData[]> {
        if (!this._getOwnProfileAttachments) throw new Error("resource has not been properly initialized")
        return this._getOwnProfileAttachments(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/talentProfile/profileAttachments/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeProfileAttachment: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    removeProfileAttachment(id: number): Promise<null> {
        if (!this._removeProfileAttachment) throw new Error("resource has not been properly initialized")
        return this._removeProfileAttachment(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talentProfile/profileAttachment/update',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateProfileAttachment: IResourceMethodObservableStrict<ProfileAttachmentData, null, null, null> | undefined
    updateProfileAttachment(requestBody: ProfileAttachmentData): Promise<null> {
        if (!this._updateProfileAttachment) throw new Error("resource has not been properly initialized")
        return this._updateProfileAttachment(requestBody, null, null).toPromise()
    }

}