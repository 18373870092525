import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, TalentApplicationData, CompanyData, TalentApplicationMinimalData, TalentWithdrawApplicationData, PagedData, PositionQuestionData, TalentPositionQuestionAnswerData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentApplicationController
 */

@Injectable()
@ResourceParams({})
export class TalentApplicationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/application/{id}/attachment',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _addAttachmentsToApplication: IResourceMethodObservableStrict<{files: File[]}, null, {id: number}, null> | undefined
    addAttachmentsToApplication(requestBody: {files: File[]}, id: number): Promise<null> {
        if (!this._addAttachmentsToApplication) throw new Error("resource has not been properly initialized")
        return this._addAttachmentsToApplication(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/application/{id}/cv',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _addCvToApplication: IResourceMethodObservableStrict<{cvFile: File}, null, {id: number}, null> | undefined
    addCvToApplication(requestBody: {cvFile: File}, id: number): Promise<null> {
        if (!this._addCvToApplication) throw new Error("resource has not been properly initialized")
        return this._addCvToApplication(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/positions/{id}/applications/external',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createExternalApplication: IResourceMethodObservableStrict<null, {origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive')}, {id: number}, JsonWrappedValue<number>> | undefined
    createExternalApplication(id: number, requestParams: {origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive')}): Promise<JsonWrappedValue<number>> {
        if (!this._createExternalApplication) throw new Error("resource has not been properly initialized")
        return this._createExternalApplication(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/positions/{id}/application/platform',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _createPlatformApplication: IResourceMethodObservableStrict<{origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive'), applicationMessage: string, cvFile: File, attachments: File[]}, null, {id: number}, JsonWrappedValue<number>> | undefined
    createPlatformApplication(requestBody: {origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive'), applicationMessage: string, cvFile: File, attachments: File[]}, id: number): Promise<JsonWrappedValue<number>> {
        if (!this._createPlatformApplication) throw new Error("resource has not been properly initialized")
        return this._createPlatformApplication(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/applications/{applicationId}/answer/create',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _createPositionAnswersForApplication: IResourceMethodObservableStrict<TalentPositionQuestionAnswerData[], null, {applicationId: number}, null> | undefined
    createPositionAnswersForApplication(requestBody: TalentPositionQuestionAnswerData[], applicationId: number): Promise<null> {
        if (!this._createPositionAnswersForApplication) throw new Error("resource has not been properly initialized")
        return this._createPositionAnswersForApplication(requestBody, null, {applicationId: applicationId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/applications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getActiveApplications: IResourceMethodObservableStrict<null, {pageNum?: number, pageSize?: number}, null, PagedData<TalentApplicationData>> | undefined
    getActiveApplications(requestParams?: {pageNum?: number, pageSize?: number}): Promise<PagedData<TalentApplicationData>> {
        if (!this._getActiveApplications) throw new Error("resource has not been properly initialized")
        return this._getActiveApplications(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/position/{positionId}/questions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getActivePositionQuestions: IResourceMethodObservableStrict<null, null, {positionId: number}, PositionQuestionData[]> | undefined
    getActivePositionQuestions(positionId: number): Promise<PositionQuestionData[]> {
        if (!this._getActivePositionQuestions) throw new Error("resource has not been properly initialized")
        return this._getActivePositionQuestions(null, null, {positionId: positionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/application/{id}/additionalFileRequirements',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAdditionalFileRequirements: IResourceMethodObservableStrict<null, null, {id: number}, string[]> | undefined
    getAdditionalFileRequirements(id: number): Promise<string[]> {
        if (!this._getAdditionalFileRequirements) throw new Error("resource has not been properly initialized")
        return this._getAdditionalFileRequirements(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/applications/count',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationCountByTalent: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getApplicationCountByTalent(): Promise<JsonWrappedValue<number>> {
        if (!this._getApplicationCountByTalent) throw new Error("resource has not been properly initialized")
        return this._getApplicationCountByTalent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/applications/states',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationsByStates: IResourceMethodObservableStrict<null, {states: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired')[]}, null, TalentApplicationData[]> | undefined
    getApplicationsByStates(requestParams: {states: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired')[]}): Promise<TalentApplicationData[]> {
        if (!this._getApplicationsByStates) throw new Error("resource has not been properly initialized")
        return this._getApplicationsByStates(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/applications/archived',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getArchivedApplications: IResourceMethodObservableStrict<null, {pageNum?: number, pageSize?: number}, null, PagedData<TalentApplicationData>> | undefined
    getArchivedApplications(requestParams?: {pageNum?: number, pageSize?: number}): Promise<PagedData<TalentApplicationData>> {
        if (!this._getArchivedApplications) throw new Error("resource has not been properly initialized")
        return this._getArchivedApplications(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/applications/companies',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getDistinctCustomerCompaniesFromApplications: IResourceMethodObservableStrict<null, null, null, CompanyData[]> | undefined
    getDistinctCustomerCompaniesFromApplications(): Promise<CompanyData[]> {
        if (!this._getDistinctCustomerCompaniesFromApplications) throw new Error("resource has not been properly initialized")
        return this._getDistinctCustomerCompaniesFromApplications(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/hiredInfo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getHiredInfoByTalent: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    getHiredInfoByTalent(): Promise<JsonWrappedValue<boolean>> {
        if (!this._getHiredInfoByTalent) throw new Error("resource has not been properly initialized")
        return this._getHiredInfoByTalent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/applications/open',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOwnActiveApplicationIdsWithPositionName: IResourceMethodObservableStrict<null, null, null, LabelData[]> | undefined
    getOwnActiveApplicationIdsWithPositionName(): Promise<LabelData[]> {
        if (!this._getOwnActiveApplicationIdsWithPositionName) throw new Error("resource has not been properly initialized")
        return this._getOwnActiveApplicationIdsWithPositionName(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/instantapplication',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOwnInstantApplication: IResourceMethodObservableStrict<null, null, null, TalentApplicationMinimalData> | undefined
    getOwnInstantApplication(): Promise<TalentApplicationMinimalData> {
        if (!this._getOwnInstantApplication) throw new Error("resource has not been properly initialized")
        return this._getOwnInstantApplication(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/applications/position/{id}/exists',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _hasTalentAppliedForPosition: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<boolean>> | undefined
    hasTalentAppliedForPosition(id: number): Promise<JsonWrappedValue<boolean>> {
        if (!this._hasTalentAppliedForPosition) throw new Error("resource has not been properly initialized")
        return this._hasTalentAppliedForPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/applications/{id}/withdraw',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _withdrawApplication: IResourceMethodObservableStrict<TalentWithdrawApplicationData, null, {id: number}, null> | undefined
    withdrawApplication(requestBody: TalentWithdrawApplicationData, id: number): Promise<null> {
        if (!this._withdrawApplication) throw new Error("resource has not been properly initialized")
        return this._withdrawApplication(requestBody, null, {id: id}).toPromise()
    }

}