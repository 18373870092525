import {Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import 'iframe-resizer';
import {NavbarService} from "../../utility/services/navbar.service";
import {MediaService} from "../../utility/services/media.service";
import {UtmService} from "../../utility/services/utm.service";
import {AnalyticsService} from "../../utility/services/gdprconsent/analytics.service";

@Component({
  selector: 'app-demo-booking',
  templateUrl: './demo-booking.component.html',
  styleUrl: './demo-booking.component.scss'
})
export class DemoBookingComponent implements OnInit, OnDestroy {

  // listens to the following demodesk events:
  // demodesk.routingFormSubmitted: The user filled out the routing form and clicked submit. Only applies to routing links, not normal meeting types.
  // demodesk.timeSlotSelected: The user selected a time and is forwarded to the booking form
  // demodesk.meetingScheduled: A demo was successfully booked
    @HostListener('window:message', ['$event'])
    listenToDemodeskMessage(e: any) {
            if (e?.data?.event && e.data.event.indexOf('demodesk') === 0) {
              this.analyticsService.reportDemodeskEvent(e)
            }
    }

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private navBarService: NavbarService,
    public mediaService: MediaService,
    private utmService: UtmService,
    private analyticsService: AnalyticsService
  ) {
  }

  ngOnInit() {
    this.navBarService.setNavBarStatus(false)
    this.initIframe()
  }

  ngOnDestroy() {
    this.navBarService.setNavBarStatus(true)
  }


  initIframe() {
    const r = "https://demodesk.com";
    const t = /([.*+?\^${}()|\[\]\/\\])/;
    const n = (e: string) => e.replace(t, "\\$1");
    const e = (e: string) => {
      const t = document.cookie.match(RegExp("(?:^|;\\s*)" + n(e) + "=([^;]*)"));
      return t && t[1];
    }
    const a = (e: string) => {
        let t = r + "/book/uniwunder/talentagent-demo" + this.utmService.getUtmParamsAsString();
        this.utmService.getUtmParamsAsString() != ''? t = t + '&iframe=true' : t = t + '?iframe=true'
        return e ? t = t + "&hutk=" + e : t;
    }

    const i = this.renderer.createElement('iframe');
    this.renderer.setStyle(i, 'width', '100%');
    this.renderer.setStyle(i, 'min-height', '230px');
    this.renderer.setStyle(i, 'border', 'none');
    this.renderer.addClass(i, 'demodesk-demo-templates-page');
    i.src = a(e('hubspotutk'));

    const m = this.renderer.createElement('script');
    m.src = r + "/iframe_resize.js";

    const c = this.elementRef.nativeElement.querySelector('#demodesk-iframe-inserter');
    const o = c.parentElement;
    this.renderer.removeChild(o, c);
    this.renderer.appendChild(o, i);
    this.renderer.appendChild(o, m);
  }

}
