
<div class="cookie-bar-backdrop flex column center">
  <div class="cookie-bar-wrapper-x">
    <div class="flex column flex-space-between gap height-100" >
      <h4 class="small-info-header">Cookies akzeptieren <i class="fa fa-cookie-bite"></i></h4>

      <div *ngIf="!detailedView" class="flex column flex-space-between">
        <div class="cookie-bar-content">
          Wir nutzen Cookies, Pixel und vergleichbare Technologien, auch von Dritten, um unsere Dienste anzubieten, stetig
          zu verbessern und
          Inhalte sowie Werbeanzeigen personalisiert auf unserer Website, Social Media und Partnerseiten anzuzeigen. Mehr
          erfährst du in
          unserer Datenschutzerklärung. Mit Klick auf „Akzeptieren“ willigst du in die Verwendung dieser Technologien ein.
          Deine Einwilligung kannst du jederzeit auf der <a (click)="routePrivacy()">Datenschutzerklärungsseite</a>
          widerrufen.
        </div>
        <div class="flex row flex-space-between gap actions" >
          <button class="very-light-flat-button" (click)="detailedView = true">
            Mehr Infos
          </button>
          <button  class="accent-button" (click)="submitAll()">
            Akzeptieren
          </button>
        </div>

      </div>
      <div *ngIf="detailedView">
        <div style="font-size: 14px">Gib an, mit der Nutzung welcher Tools du dich einverstanden erklärst. Nähere Informationen zu den einzelnen Komponenten findest du in unserer <a class="link-classic" (click)="routePrivacy()">Datenschutzerklärung</a>.</div>
        <div style="font-size: 14px">Danke, dass du uns bei der Verbesserung unserer Dienste unterstützt!</div>
        <div class="flex column start" [formGroup]="form">
          <mat-checkbox formControlName="consent_facebook">Facebook Pixel</mat-checkbox>
          <mat-checkbox formControlName="consent_ga">Google Analytics</mat-checkbox>
          <mat-checkbox formControlName="consent_google_ads">Google Ads</mat-checkbox>
          <mat-checkbox formControlName="consent_linkedIn">LinkedIn Pixel</mat-checkbox>
          <mat-checkbox formControlName="consent_pinterest">Pinterest Ads</mat-checkbox>
          <mat-checkbox formControlName="consent_tiktok">TikTok Pixel</mat-checkbox>
          <mat-checkbox formControlName="consent_piwik">Piwik PRO</mat-checkbox>
          <mat-checkbox formControlName="consent_hotjar">Hotjar</mat-checkbox>
          <mat-checkbox formControlName="consent_leadfeeder">Leadfeeder</mat-checkbox>
        </div>

        <div class="flex row end gap actions" >
          <button  class="very-light-flat-button" (click)="selectAll()">
            Alles wählen
          </button>
          <button  class="accent-button" (click)="submit()">
            Übernehmen

          </button>
        </div>
      </div>
    </div>
<!--    <div style="height: 30px;" *ngIf="isIOs">-->
<!--      &lt;!&ndash;IOS overlays controls with controls of external applications &ndash;&gt;-->
<!--    </div>-->
  </div>

</div>
