import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {PagedData, NotificationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentNotificationController
 */

@Injectable()
@ResourceParams({})
export class TalentNotificationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/user/talent/notifications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllNotificationsForTalent: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, null, PagedData<NotificationData>> | undefined
    getAllNotificationsForTalent(requestParams: {page: number, pageSize: number}): Promise<PagedData<NotificationData>> {
        if (!this._getAllNotificationsForTalent) throw new Error("resource has not been properly initialized")
        return this._getAllNotificationsForTalent(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/user/talent/notifications/order',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getNotificationsForTalent: IResourceMethodObservableStrict<null, null, null, NotificationData[]> | undefined
    getNotificationsForTalent(): Promise<NotificationData[]> {
        if (!this._getNotificationsForTalent) throw new Error("resource has not been properly initialized")
        return this._getNotificationsForTalent(null, null, null).toPromise()
    }

}