import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {LanguageData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.LanguageController
 */

@Injectable()
@ResourceParams({})
export class LanguageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/languages',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLanguages: IResourceMethodObservableStrict<null, {q?: string, sortMode?: ('Popularity' | 'Alphabetical'), limit?: number}, null, LanguageData[]> | undefined
    getLanguages(requestParams?: {q?: string, sortMode?: ('Popularity' | 'Alphabetical'), limit?: number}): Promise<LanguageData[]> {
        if (!this._getLanguages) throw new Error("resource has not been properly initialized")
        return this._getLanguages(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/languages/async',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLanguagesAsync: IResourceMethodObservableStrict<null, {q?: string, sortMode?: ('Popularity' | 'Alphabetical'), limit?: number, page: number, pageSize: number}, null, PagedData<LanguageData>> | undefined
    getLanguagesAsync(requestParams: {q?: string, sortMode?: ('Popularity' | 'Alphabetical'), limit?: number, page: number, pageSize: number}): Promise<PagedData<LanguageData>> {
        if (!this._getLanguagesAsync) throw new Error("resource has not been properly initialized")
        return this._getLanguagesAsync(null, requestParams, null).toPromise()
    }

}