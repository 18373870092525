<div id="main-wrapper">

  <div
    *ngIf="showNavbar"
    id="navbar-wrapper">
    <div id="navbar" class="flex flex-space-between-center center" *ngIf="!mediaService.getMaxWidthQuery(1200).matches">
      <img style="cursor: pointer" src="assets/images/talentagent-logo.png" routerLink="/talent" alt="">

      <div class="flex row center gap-xl">
        <a routerLink="/talent">
          <button
            [ngClass]="{'active-menupoint': router.url.includes('talent')}"
            class="nav-button" >Für Talente</button>
        </a>

        <a routerLink="/company">
          <button
            [ngClass]="{'active-menupoint': router.url.includes('company')}"
            class="nav-button">Für Unternehmen</button>
        </a>

        <a href="https://uniwunder.com/"  target="_blank"><button class="nav-button">About Us</button></a>
      </div>

      <div *ngIf="!router.url.includes('company') && !router.url.includes('resources') && !router.url.includes('caseStudies') ">
        <a (click)="routeToTalentAgent()">
          <button class="blank-button-small margin-right" >Login</button>
        </a>

        <a (click)="routeToTalentAgentOb()">
          <button  class="accent-button ita_initiate-checkout ita_format-talentagent page-content-cta" >Anmelden</button>
        </a>
      </div>

      <div *ngIf="router.url.includes('company') || router.url.includes('resources') || router.url.includes('caseStudies') " class="flex row center">
        <a (click)="routeToCompanyLogin()">
          <button class="blank-button-small margin-right">Login</button>
        </a>

        <a (click)="routeToDemoDesk('Navbar')">
          <button  class="accent-button">Demo buchen</button>
        </a>
      </div>
    </div>

    <div id="navbar-mobile" class="flex flex-space-between-center center" *ngIf="mediaService.getMaxWidthQuery(1200).matches">
      <img src="assets/images/talentagent-logo.png" alt="">
      <burger-menu></burger-menu>
    </div>

  </div>

  <div id="content-wrapper">
    <router-outlet></router-outlet>
  </div>

  <footer id="footer" class="flex column center">
    <div class="flex column gap-lg content-width width-100">
      <div
        [ngClass]="{'padding-vertical-lg': !mediaService.isMobile()}"
        class="flex row-wrap flex-space-between gap-xl">
        <div class="flex row space-around gap-lg" [ngClass]="{'row': !mediaService.isTablet(), 'row-wrap': mediaService.isTablet()}">
          <div class="flex column gap" [ngClass]="{
        'width-33': !mediaService.isTablet() && !router.url.includes('demo-booking') && showNavbar,
        'width-66': !mediaService.isTablet() && (router.url.includes('demo-booking') || !showNavbar),
        'width-100': mediaService.isTablet()}">
            <img src="assets/images/talentagent-logo.png" alt="">
            <div class="footer-text">
            <span *ngIf="router.url.includes('talent') || router.url.includes('guides') ||
            router.url.includes('tp') || router.url.includes('use-regulation')">
              Finde deinen Einstiegsjob nach dem Studium mit Talentagent, der innovativen Jobplattform von Uniwunder.
            Mit einer Vielzahl von Stellenangeboten helfen wir dir, den Traumjob zu finden und mit ausschließlich passenden Angeboten deine Jobsuche zu erleichtern.
            </span>
              <span *ngIf="router.url.includes('company') || router.url.includes('webinar') || router.url.includes('caseStudies') ||
            router.url.includes('datenschutz') || router.url.includes('imprint')">
              Talentagent ist die Recruiting-Plattform für Nachwuchstalente aus dem Hochschulsegment.
              Egal ob Trainees, Graduates oder Juniors: Unser Pool umfasst über 50.000 Absolvent:innen aus den Bereichen Wirtschaft,
              MINT, Sozialwissenschaften & vielen mehr.
              Sichere dir noch heute deine Fachkräfte von morgen.
            </span>
              <span *ngIf="router.url.includes('demo-booking')">
              Erhalte nur geprüfte Talente für deine Einstiegsjobs mit Talentagent, der führenden Job-Plattform für Uni-Absolventen.
                Reduziere deinen Aufwand und spare Zeit durch unser Qualitätsversprechen.
                Schalte beliebig viele Stellenanzeigen ohne Laufzeit mit garantierten gebuchten Anzahl an Juniorbewerbungen.
            </span>
            </div>
          </div>

          <div class="flex row-wrap flex-space-between" [ngClass]="{'gap-xl': !mediaService.isMobile(), 'gap-lg': mediaService.isMobile()}">
            <div *ngIf="!router.url.includes('demo-booking') && showNavbar" class="flex column gap">
              <h3 class="small-primary-header color-primary">Quick Links</h3>
              <a class="dark-grey-anchor" routerLink="/talent">Home</a>
              <a class="dark-grey-anchor" routerLink="/karriereguide">Karriereguide</a>
              <a class="dark-grey-anchor" (click)="routeToTalentAgent()">Anmelden</a>
              <a class="dark-grey-anchor" (click)="routeToTalentAgentOb()">Registrieren</a>
            </div>

            <div *ngIf="!router.url.includes('demo-booking') && showNavbar" class="flex column gap">
              <h3 class="small-primary-header color-primary">Für Unternehmen</h3>
              <a class="dark-grey-anchor" routerLink="/company">Infos für Unternehmen</a>
              <a class="dark-grey-anchor" (click)="routeToDemoDesk('Footer')">Demo Vereinbaren</a>
              <a class="dark-grey-anchor" (click)="routeToCompanyLogin()">Login</a>
            </div>

            <div *ngIf="!router.url.includes('demo-booking') && showNavbar" class="flex column gap">
              <h3 class="small-primary-header color-primary">Links</h3>
              <a class="dark-grey-anchor" (click)="routeToUniwunder()">Uniwunder</a>
              <a class="dark-grey-anchor" (click)="routeToHID()">Hochschulinitiative</a>
            </div>

            <div *ngIf="!router.url.includes('demo-booking') && showNavbar &&
            (router.url.includes('company') || router.url.includes('webinar') || router.url.includes('caseStudies') ||
            router.url.includes('datenschutz') || router.url.includes('imprint'))" class="flex column gap">
              <h3 class="small-primary-header color-primary">Kontakt</h3>
              <a class="dark-grey-anchor" href="mailto:anfrage@talentagent.de">E-Mail: anfrage&#64;talentagent.de</a>
              <a class="dark-grey-anchor" href="tel:+4935189670255">Telefon: +4935189670255</a>
            </div>
          </div>


        </div>

      </div>

      <div class="divider"></div>

      <div class="flex gap-lg flex-space-between-center" [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}">
        <div class="flex gap-lg" [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}">
          <div class="center-text">Copyright &#169; 2024 Uniwunder</div>

          <div class="flex row-wrap center gap-lg">
            <a class="black-anchor" href="/datenschutz" target="_blank">Datenschutz</a>

            <a class="black-anchor" href="/imprint" target="_blank">Impressum</a>

            <a class="black-anchor" href="assets/pdfs/AGB_Talentagent_Stand_14082024.pdf" target="_blank">AGB</a>
          </div>

        </div>

        <div class="flex row gap start-center">

          <a (click)="routeToSocialMedia('https://tiktok.com/@hochschulinitiative_uw')">
            <button class="primary-icon-button">
              <i class="fa-brands fa-tiktok"></i>
            </button>
          </a>

          <a (click)="routeToSocialMedia('https://www.linkedin.com/company/talentagent-uw')">
            <button class="primary-icon-button">
              <i class="fa-brands fa-linkedin-in"></i>
            </button>
          </a>


          <a (click)="routeToSocialMedia('https://www.facebook.com/hochschulinitiative')">
            <button class="primary-icon-button">
              <i class="fa-brands fa-facebook-f"></i>
            </button>
          </a>


          <a (click)="routeToSocialMedia('https://www.instagram.com/hochschulinitiativedeutschland/')">
            <button class="primary-icon-button">
              <i class="fa-brands fa-instagram"></i>
            </button>
          </a>


        </div>
      </div>
    </div>

  </footer>
</div>
