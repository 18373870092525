import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {AdminUpdateCompanyTalentAgentLandingPageData, AdminUpdateTalentTalentAgentLandingPageData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminTalentAgentLandingPageController
 */

@Injectable()
@ResourceParams({})
export class AdminTalentAgentLandingPageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talentAgentLandingPage/company/update',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateCompanyTalentAgentLandingPage: IResourceMethodObservableStrict<AdminUpdateCompanyTalentAgentLandingPageData, null, null, null> | undefined
    updateCompanyTalentAgentLandingPage(requestBody: AdminUpdateCompanyTalentAgentLandingPageData): Promise<null> {
        if (!this._updateCompanyTalentAgentLandingPage) throw new Error("resource has not been properly initialized")
        return this._updateCompanyTalentAgentLandingPage(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talentAgentLandingPage/talent/update',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateTalentTalentAgentLandingPage: IResourceMethodObservableStrict<AdminUpdateTalentTalentAgentLandingPageData, null, null, null> | undefined
    updateTalentTalentAgentLandingPage(requestBody: AdminUpdateTalentTalentAgentLandingPageData): Promise<null> {
        if (!this._updateTalentTalentAgentLandingPage) throw new Error("resource has not been properly initialized")
        return this._updateTalentTalentAgentLandingPage(requestBody, null, null).toPromise()
    }

}