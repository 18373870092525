import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, ConsultantNewHiringData, PagedData, NotificationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantNotificationController
 */

@Injectable()
@ResourceParams({})
export class ConsultantNotificationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/user/consultant/notifications/newHirings',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getNewHirings: IResourceMethodObservableStrict<null, null, null, ConsultantNewHiringData[]> | undefined
    getNewHirings(): Promise<ConsultantNewHiringData[]> {
        if (!this._getNewHirings) throw new Error("resource has not been properly initialized")
        return this._getNewHirings(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/user/consultant/notifications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getNotificationsForConsultant: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, null, PagedData<NotificationData>> | undefined
    getNotificationsForConsultant(requestParams: {page: number, pageSize: number}): Promise<PagedData<NotificationData>> {
        if (!this._getNotificationsForConsultant) throw new Error("resource has not been properly initialized")
        return this._getNotificationsForConsultant(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/user/consultant/notifications/top5',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTop5NotificationsForConsultant: IResourceMethodObservableStrict<null, null, null, NotificationData[]> | undefined
    getTop5NotificationsForConsultant(): Promise<NotificationData[]> {
        if (!this._getTop5NotificationsForConsultant) throw new Error("resource has not been properly initialized")
        return this._getTop5NotificationsForConsultant(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/user/consultant/notifications/unseenCount',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUnseenNotificationCount: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getUnseenNotificationCount(): Promise<JsonWrappedValue<number>> {
        if (!this._getUnseenNotificationCount) throw new Error("resource has not been properly initialized")
        return this._getUnseenNotificationCount(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/user/consultant/notifications/newHirings/reset',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _resetNewHirings: IResourceMethodObservableStrict<null, null, null, null> | undefined
    resetNewHirings(): Promise<null> {
        if (!this._resetNewHirings) throw new Error("resource has not been properly initialized")
        return this._resetNewHirings(null, null, null).toPromise()
    }

}