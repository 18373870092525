import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {PublicCompanyTalentAgentLandingPageData, CompanyRecallData, PublicTalentTalentAgentLandingPageData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.public.PublicLandingPageController
 */

@Injectable()
@ResourceParams({})
export class PublicLandingPageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/company/talentAgentLandingPage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyTalentAgentLandingPage: IResourceMethodObservableStrict<null, null, null, PublicCompanyTalentAgentLandingPageData> | undefined
    getCompanyTalentAgentLandingPage(): Promise<PublicCompanyTalentAgentLandingPageData> {
        if (!this._getCompanyTalentAgentLandingPage) throw new Error("resource has not been properly initialized")
        return this._getCompanyTalentAgentLandingPage(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/talent/talentAgentLandingPage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentTalentAgentLandingPage: IResourceMethodObservableStrict<null, null, null, PublicTalentTalentAgentLandingPageData> | undefined
    getTalentTalentAgentLandingPage(): Promise<PublicTalentTalentAgentLandingPageData> {
        if (!this._getTalentTalentAgentLandingPage) throw new Error("resource has not been properly initialized")
        return this._getTalentTalentAgentLandingPage(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/public/company/recallRequest',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendCompanyRecallRequest: IResourceMethodObservableStrict<CompanyRecallData, null, null, null> | undefined
    sendCompanyRecallRequest(requestBody: CompanyRecallData): Promise<null> {
        if (!this._sendCompanyRecallRequest) throw new Error("resource has not been properly initialized")
        return this._sendCompanyRecallRequest(requestBody, null, null).toPromise()
    }

}