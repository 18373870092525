import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {UniwunderPositionExportData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.public.PublicUniwunderApiController
 */

@Injectable()
@ResourceParams({})
export class PublicUniwunderApiResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/uniwunder/api/exports/{referenceKey}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUniwunderEmploymentPositionFeed: IResourceMethodObservableStrict<null, null, {referenceKey: ('campusjaeger_gmbh_797a3b75' | 'pluss_personalmanagement_gmbh_14632066' | 'absolventa_gmbh_9807cd9c' | 'joblift_gmbh_a7299299' | 'jobware_gmbh_d83f624e' | 'stellenanzeigende_gmbh_co_kg_57ff8d2a')}, UniwunderPositionExportData> | undefined
    getUniwunderEmploymentPositionFeed(referenceKey: ('campusjaeger_gmbh_797a3b75' | 'pluss_personalmanagement_gmbh_14632066' | 'absolventa_gmbh_9807cd9c' | 'joblift_gmbh_a7299299' | 'jobware_gmbh_d83f624e' | 'stellenanzeigende_gmbh_co_kg_57ff8d2a')): Promise<UniwunderPositionExportData> {
        if (!this._getUniwunderEmploymentPositionFeed) throw new Error("resource has not been properly initialized")
        return this._getUniwunderEmploymentPositionFeed(null, null, {referenceKey: referenceKey}).toPromise()
    }

}