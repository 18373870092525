import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {DKZSubjectMappingData, SubjectGroupData, SubjectData, DkzStudyAreaHierarchyTreeData, PagedData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminSubjectController
 */

@Injectable()
@ResourceParams({})
export class AdminSubjectResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/matching/dkzsubjects/{subjectId}/group/{groupId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addSubjectToGroupMapping: IResourceMethodObservableStrict<null, null, {subjectId: number, groupId: number}, null> | undefined
    addSubjectToGroupMapping(pathVariables: {subjectId: number, groupId: number}): Promise<null> {
        if (!this._addSubjectToGroupMapping) throw new Error("resource has not been properly initialized")
        return this._addSubjectToGroupMapping(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/dkzSubjects',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllDKZSubjectsWithoutMapping: IResourceMethodObservableStrict<null, {page: number, pageSize: number}, null, PagedData<DKZSubjectMappingData>> | undefined
    getAllDKZSubjectsWithoutMapping(requestParams: {page: number, pageSize: number}): Promise<PagedData<DKZSubjectMappingData>> {
        if (!this._getAllDKZSubjectsWithoutMapping) throw new Error("resource has not been properly initialized")
        return this._getAllDKZSubjectsWithoutMapping(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/dkz/hierarchy',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllDkzStudyMappings: IResourceMethodObservableStrict<null, null, null, DkzStudyAreaHierarchyTreeData[]> | undefined
    getAllDkzStudyMappings(): Promise<DkzStudyAreaHierarchyTreeData[]> {
        if (!this._getAllDkzStudyMappings) throw new Error("resource has not been properly initialized")
        return this._getAllDkzStudyMappings(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/dkzSubjectGroups',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllDkzSubjectGroups: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<LabelData>> | undefined
    getAllDkzSubjectGroups(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<LabelData>> {
        if (!this._getAllDkzSubjectGroups) throw new Error("resource has not been properly initialized")
        return this._getAllDkzSubjectGroups(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/subjectGroups/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllSubjectGroups: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<SubjectGroupData>> | undefined
    getAllSubjectGroups(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<SubjectGroupData>> {
        if (!this._getAllSubjectGroups) throw new Error("resource has not been properly initialized")
        return this._getAllSubjectGroups(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/subjects/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllSubjects: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<SubjectData>> | undefined
    getAllSubjects(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<SubjectData>> {
        if (!this._getAllSubjects) throw new Error("resource has not been properly initialized")
        return this._getAllSubjects(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/subjectsLabels/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllSubjectsAsLabelData: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<LabelData>> | undefined
    getAllSubjectsAsLabelData(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<LabelData>> {
        if (!this._getAllSubjectsAsLabelData) throw new Error("resource has not been properly initialized")
        return this._getAllSubjectsAsLabelData(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/subjects/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSubject: IResourceMethodObservableStrict<null, {professionWithGroupNames?: boolean}, {id: number}, SubjectData> | undefined
    getSubject(id: number, requestParams?: {professionWithGroupNames?: boolean}): Promise<SubjectData> {
        if (!this._getSubject) throw new Error("resource has not been properly initialized")
        return this._getSubject(null, requestParams || {}, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/subjectGroups/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSubjectGroup: IResourceMethodObservableStrict<null, null, {id: number}, SubjectGroupData> | undefined
    getSubjectGroup(id: number): Promise<SubjectGroupData> {
        if (!this._getSubjectGroup) throw new Error("resource has not been properly initialized")
        return this._getSubjectGroup(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/matching/subjectGroups',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSubjectGroups: IResourceMethodObservableStrict<null, {subjectGroupIds: number[]}, null, SubjectGroupData[]> | undefined
    getSubjectGroups(requestParams: {subjectGroupIds: number[]}): Promise<SubjectGroupData[]> {
        if (!this._getSubjectGroups) throw new Error("resource has not been properly initialized")
        return this._getSubjectGroups(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/matching/subjects',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _getSubjects: IResourceMethodObservableStrict<number[], null, null, SubjectData[]> | undefined
    getSubjects(requestBody: number[]): Promise<SubjectData[]> {
        if (!this._getSubjects) throw new Error("resource has not been properly initialized")
        return this._getSubjects(requestBody, null, null).toPromise()
    }

}