<div class="padding-lg card-soft-shadow width-100 min-width-card">
  <div class="logo" [ngStyle]="{'background-image': 'url(' + environment.apiUrl + '/public/companyLogo/' + positionData.id + ')'}"></div>
  <h3 class="margin-top">{{positionData.title}}</h3>
  <p class="regular-text">{{positionData.hiringCompanyName}}</p>
  <p class="regular-text">{{positionData.locationDescription}}</p>
  <br>
  <!--
  <div class="flex center width-100">
    <button class="dark-stroked-button" (click)="routeToPosition()">Zur Stelle</button>
  </div>
  -->
</div>
