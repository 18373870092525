import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {WebinarData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.public.PublicWebinarController
 */

@Injectable()
@ResourceParams({})
export class PublicWebinarResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/webinar',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllWebinars: IResourceMethodObservableStrict<null, null, null, WebinarData[]> | undefined
    getAllWebinars(): Promise<WebinarData[]> {
        if (!this._getAllWebinars) throw new Error("resource has not been properly initialized")
        return this._getAllWebinars(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/webinar/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getWebinar: IResourceMethodObservableStrict<null, null, {id: number}, WebinarData> | undefined
    getWebinar(id: number): Promise<WebinarData> {
        if (!this._getWebinar) throw new Error("resource has not been properly initialized")
        return this._getWebinar(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/webinar/{id}/image',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getWebinarImage: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getWebinarImage(id: number): Promise<Blob> {
        if (!this._getWebinarImage) throw new Error("resource has not been properly initialized")
        return this._getWebinarImage(null, null, {id: id}).toPromise()
    }

}