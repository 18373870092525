import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {SearchFilterData, TalentPositionPreviewData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentElasticSearchController
 */

@Injectable()
@ResourceParams({})
export class TalentElasticSearchResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/elastic/matches',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMatchingPositionsWithElasticSearch: IResourceMethodObservableStrict<SearchFilterData, null, null, PagedData<TalentPositionPreviewData>> | undefined
    getMatchingPositionsWithElasticSearch(requestBody: SearchFilterData): Promise<PagedData<TalentPositionPreviewData>> {
        if (!this._getMatchingPositionsWithElasticSearch) throw new Error("resource has not been properly initialized")
        return this._getMatchingPositionsWithElasticSearch(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/elastic/search',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _searchPositions: IResourceMethodObservableStrict<SearchFilterData, {pageNum: number, pageSize: number}, null, PagedData<TalentPositionPreviewData>> | undefined
    searchPositions(requestBody: SearchFilterData, requestParams: {pageNum: number, pageSize: number}): Promise<PagedData<TalentPositionPreviewData>> {
        if (!this._searchPositions) throw new Error("resource has not been properly initialized")
        return this._searchPositions(requestBody, requestParams, null).toPromise()
    }

}