import {Injectable} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {LocalStorage} from "ngx-store";

export class SessionTracking {
  constructor(
    public utm_source: string,
    public utm_content: string,
    public utm_medium: string,
    public utm_campaign: string,
    public occurred: string,
    public gclid?: string,
    public cutm_adacc?: string
  ) {
  }
}

export class GDPRConsent {
  constructor(
    public initial: boolean,
    public consent_ga: boolean,
    public consent_linkedIn: boolean,
    public consent_facebook: boolean,
    public consent_pinterest: boolean,
    public consent_tiktok: boolean,
    public consent_piwik: boolean,
    public consent_hotjar: boolean,
    public consent_leadfeeder: boolean,
    public consent_google_ads: boolean
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    private route: ActivatedRoute) {

  }

  @LocalStorage() sessionTracking: {
    gclid: string;
    occurred: string;
    utm_campaign: string;
    cutm_adacc: string;
    utm_medium: string;
    utm_source: string;
    utm_content: string;
    utm_term: string;
  } = {
    utm_source: null,
    utm_content: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
    occurred: null,
    gclid: null,
    cutm_adacc: null
  };

  //gets initialized in app component via cookiebar
  @LocalStorage() gdprConsent: GDPRConsent = {
    //initial tells us if the user was asked already
    initial: true,
    consent_ga: false,
    consent_linkedIn: false,
    consent_facebook: false,
    consent_pinterest: false,
    consent_tiktok: false,
    consent_piwik: false,
    consent_hotjar: false,
    consent_leadfeeder: false,
    consent_google_ads: false
  };


  updateGDPRConsent(consent: GDPRConsent) {
    consent.initial = false;
    //handle tiktok

    if(consent.consent_tiktok == true && !this.gdprConsent.consent_tiktok){
      this.addTikTokScriptToHead()
    }
    //if tiktok is being disabled, remove the script tag
    if(consent.consent_tiktok == false && this.gdprConsent.consent_tiktok == true){
      this.removeTikTokScriptFromHead();
    }

      //if hotjar is being disabled, remove the script tag
      if(consent.consent_hotjar == true && !this.gdprConsent.consent_hotjar){
          this.addHotjarScriptToHead()
      }
      if(consent.consent_hotjar == false && this.gdprConsent.consent_hotjar == true){
          this.removeHotjarScriptFromHead();
      }
      this.gdprConsent = consent
  }

    removeHotjarScriptFromHead(){
        let node = document.getElementById("hotjarHeadScript")
        if(node) {
            node.parentNode.removeChild(node);
        }
    }

  removeTikTokScriptFromHead(){
    //It gets the ID in the TikTokAnalyticsScript.js
    let node = document.getElementById("tikTokHeadScript")
    if(node) {
      node.parentNode.removeChild(node);
    }
  }

  getGDPRConsent(): GDPRConsent {
    return this.gdprConsent;
  }

  updateSessionTracking() {
    let sub = this.route.queryParamMap.subscribe(params => {
      let tracking = {
        utm_source: params.get('utm_source'),
        utm_term: params.get('utm_term'),
        utm_content: params.get('utm_content'),
        utm_medium: params.get('utm_medium'),
        utm_campaign: params.get('utm_campaign'),
        occurred: params.get('occurred'),
        gclid: params.get('gclid'),
        cutm_adacc: params.get('cutm_adacc')
      }
      this.sessionTracking = tracking
      if (tracking?.utm_source?.length) {
        sub.unsubscribe()
      }
    })
  }

  // getTrackingParams(): string {
  //   let paramsString = '?'
  //   Object.keys(this.sessionTracking).forEach((param: string) => {
  //     if (param) {
  //       if (paramsString.length > 1) paramsString += '&'
  //       paramsString += `${param}=${this.sessionTracking[param]}`
  //     }}
  //   )
  //   return paramsString
  // }

  getSessionTracking(): SessionTracking {
    return this.sessionTracking
  }

  addTikTokScriptToHead(){
    //Add Script 1
    let node = document.createElement('script'); // creates the script tag
    node.src = '../../../assets/scripts/TikTokAnalyticsScript.js'; // sets the source
    node.type = 'text/javascript';
    node.async = true; // makes script run asynchronously
    node.charset = 'utf-8';
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node);
  }
    addHotjarScriptToHead(){
        //Add Script 1
        let node = document.createElement('script'); // creates the script tag
        node.src = '../../../assets/scripts/HotjarScript.js'; // sets the source
        node.type = 'text/javascript';
        node.async = true; // makes script run asynchronously
        node.charset = 'utf-8';
        // append to head of document
        document.getElementsByTagName('head')[0].appendChild(node);
    }



    generateUrlTailFromTracking(redirectUrl: string): string {
    let trackingData = this.getSessionTracking()
    if (!trackingData) return '';

    var paramPrefix =  '?';
    //If URL already has a param, we need to change the first param prefix to &
    if(redirectUrl.split('?').length > 1){
      paramPrefix = '&'
    }


    var res = '';

    if (trackingData.utm_medium) {
      res += paramPrefix + 'utm_medium=' + trackingData.utm_medium
      paramPrefix = '&'
    }

    if (trackingData.utm_campaign) {
      res += paramPrefix + 'utm_campaign=' + trackingData.utm_campaign
      paramPrefix = '&'
    }

    if (trackingData.utm_content) {
      res += paramPrefix + 'utm_content=' + trackingData.utm_content
      paramPrefix = '&'
    }

    if (trackingData.utm_source) {
      res += paramPrefix + 'utm_source=' + trackingData.utm_source
    }

    if (trackingData.cutm_adacc) {
      res += paramPrefix + 'cutm_adacc=' + trackingData.cutm_adacc
    }

    if (trackingData.gclid) {
      res += paramPrefix + 'gclid' + trackingData.gclid
    }

    return res
  }
}
