import {AfterViewChecked, Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {PublicEmploymentPositionDetailsData} from "../../../../../generated/data";
import {MediaService} from "../../../../utility/services/media.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-position-content',
  templateUrl: './position-content.component.html',
  styleUrls: ['./position-content.component.scss']
})
export class PositionContentComponent implements OnInit, AfterViewChecked {

  videoUrl: SafeResourceUrl | null =  null;
  @Input() position: PublicEmploymentPositionDetailsData;

  constructor(    public mediaService: MediaService,
                  private sanitizer: DomSanitizer) { }

  ngAfterViewChecked(): void {
      this.setVideoHeight()
  }

  positionImageUrls: SafeResourceUrl[] = []
  ngOnInit(): void {
      this.positionImageUrls = this.position.content.positionCustomerImages.map(img => this.sanitizer.bypassSecurityTrustUrl(environment.apiUrl + '/public/contentSplits/' + this.position.content.id + '/picture/' + img.id));
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.position.content.videoEmbedLink)
  }

  setVideoHeight() {
    let videoWrapper = document.getElementsByClassName('position-video').item(0)
    if (videoWrapper) {
      let width = videoWrapper.clientWidth
      videoWrapper.children[0].setAttribute('height', `${width/1.8}px`)
    }
  }

  getWeeklyHoursString():string{
      let from = this.position.weeklyHoursFrom
      let to = this.position.weeklyHoursTo

      if (to && from && (to != from)) {
          return `${from} - ${to}h Woche`
      } else if ((to == from) || (!to && from)) return `${from}h Woche`
      else if (to && !from) return `${to}h Woche`
      else return null
  }

  navigateToCompanyWebsite() {
    window.open(this.position.company.website, '_blank');
  }

  getThirdPartyPositionTooltip() {
    return `Vermittlung über unseren Partner ${this.position.company.name}.
    Bei Unternehmen, die anonym über einen unserer Partner nach Bewerbern suchen, erfährst du nach deiner Bewerbung, um welches Unternehmen es sich handelt.`
  }

}
