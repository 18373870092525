import {Component, OnDestroy, OnInit} from '@angular/core';
import {AnimationsService} from '../../../utility/services/animations.service';
import {AnalyticsService} from '../../../utility/services/gdprconsent/analytics.service';
import {MediaService} from '../../../utility/services/media.service';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {NavbarService} from '../../../utility/services/navbar.service';
import {DemodeskService} from "../../demo-booking/demodesk.service";

@Component({
  selector: 'app-single-case-study',
  templateUrl: './single-case-study.component.html',
  styleUrl: './single-case-study.component.scss'
})
export class SingleCaseStudyComponent implements OnInit, OnDestroy{

  caseStudyData: CaseStudyData = {
    companyName: "Userlike",
    studyTitle: "Userlike generiert mit Talentagent gezielt dreimal mehr Bewerbungen von Top-Absolvent:innen",
    companyLogo: 1,
    videoUrl: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
    videoTagline: "Userlike ist die führende deutsche Plattform für Kunden-Messaging und KI-gestützte Support-Automatisierung.",
    studyText:
      "Die Customer-Experience-Plattform Userlike setzt in ihrem Recruiting einen besonderen Fokus auf das Potenzial junger Talente." +
      " Für ihr hauseigenes Traineeship-Programm suchte das Unternehmen gezielt nach Absolvent:innen - und fand mit Talentagent die entscheidende Expertise dafür." +
      "\n" + "<br>" +  "<br>" +
      "Userlike bietet innovative Kommunikationslösungen für Unternehmen, " +
      "die auch bei der digital versierten Generation Z Anklang finden. " +
      "Auf Talentagent konnte Userlike genau diese Absolvent:innen," +
      " deren Technikaffinität und Know-How einen idealen Match für das hauseigene Produkt darstellt, finden und ansprechen. " +
      "Das zeigt sich am Beispiel der ersten Trainee, die durch Talentagent zu Userlike kam: " +
      "Sie erzielte bereits kurze Zeit nach ihrem Hiring bemerkenswerte Erfolge in ihrer Position und bringt täglich ein hohes Maß an Freude für ihre Aufgaben mit." +
      "\n" + "<br>" +  "<br>" +
      "Die Partnerschaft hat es Userlike ermöglicht, ihren Recruiting-Funnel stets mit vielversprechenden Gesprächen und Potentialen versorgt zu wissen. " +
      "Hierbei sorgt Talentagent bereits im Vorhinein für zielgruppengerechte Stelleninserate und qualitativ hochwertige Talente, " +
      "sodass sich Userlike zu 100% auf die stattfindenden Kandidateninterviews fokussieren kann.",
    quoteText:
      "\"Seit wir mit Talentagent zusammenarbeiten, haben wir stets einen gefüllten Recruiting-Funnel für unsere Trainee- und Junior-Positionen. " +
      "Das erste Hiring, das durch Talentagent zu uns kam, hat bereits beeindruckende Erfolge erzielt.\"",
    quoteAuthor: "Kristina",
    quoteAuthorTitle: "Recruiterin bei Userlike"
  }

  constructor(
    private animationsService: AnimationsService,
    public mediaService: MediaService,
    private analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    private navbarService: NavbarService,
    private demodeskService: DemodeskService
  ) { }

  ngOnInit() {
    this.animationsService.handleOnScrollAnimation('.usp-icon-wrapper', 'bounceIn', true)
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if(params['origin'] === 'ad') {
        this.navbarService.setNavBarStatus(false)
      }
    })
  }

  ngOnDestroy() {
    this.navbarService.setNavBarStatus(true)
  }

  navToContact(context: string) {
    this.demodeskService.navToContactForm(context)
  }
}

export interface CaseStudyData {
  companyName: string,
  studyTitle: string,
  companyLogo: number,
  videoUrl: string,
  videoTagline: string,
  studyText: string,
  quoteText: string,
  quoteAuthor: string,
  quoteAuthorTitle: string
}
