import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {CustomCareerEventData, CreateCustomCareerEventData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentCareerEventController
 */

@Injectable()
@ResourceParams({})
export class TalentCareerEventResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talentProfile/careerEvents/custom',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addCustomCareerEvent: IResourceMethodObservableStrict<CreateCustomCareerEventData, null, null, null> | undefined
    addCustomCareerEvent(requestBody: CreateCustomCareerEventData): Promise<null> {
        if (!this._addCustomCareerEvent) throw new Error("resource has not been properly initialized")
        return this._addCustomCareerEvent(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talentProfile/careerEvents/custom/{id}/update',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateCustomCareerEvent: IResourceMethodObservableStrict<CustomCareerEventData, null, {id: number}, null> | undefined
    updateCustomCareerEvent(requestBody: CustomCareerEventData, id: number): Promise<null> {
        if (!this._updateCustomCareerEvent) throw new Error("resource has not been properly initialized")
        return this._updateCustomCareerEvent(requestBody, null, {id: id}).toPromise()
    }

}