import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WebinarDetailComponent} from "./webinar-detail/webinar-detail.component";
import {PublicWebinarResource} from "../../../generated/resources";
import {WebinarCardComponent} from "./webinar-card/webinar-card.component";
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [
    WebinarDetailComponent,
    WebinarCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    WebinarCardComponent
  ],
  providers: [
    PublicWebinarResource
  ]
})
export class WebinarModule { }
