import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {EmploymentPositionRejectedReasonStatisticsData, EmploymentPositionApplicationFailedStatisticsData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company_old.CompanyOldControllingController
 */

@Injectable()
@ResourceParams({})
export class CompanyOldControllingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/controlling/positions/{id}/failedReasons',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationFailedStatisticsForEmploymentPosition: IResourceMethodObservableStrict<null, null, {id: number}, EmploymentPositionApplicationFailedStatisticsData> | undefined
    getApplicationFailedStatisticsForEmploymentPosition(id: number): Promise<EmploymentPositionApplicationFailedStatisticsData> {
        if (!this._getApplicationFailedStatisticsForEmploymentPosition) throw new Error("resource has not been properly initialized")
        return this._getApplicationFailedStatisticsForEmploymentPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/controlling/positions/{id}/rejectedReasons',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRejectedReasonStatisticsForEmploymentPosition: IResourceMethodObservableStrict<null, null, {id: number}, EmploymentPositionRejectedReasonStatisticsData> | undefined
    getRejectedReasonStatisticsForEmploymentPosition(id: number): Promise<EmploymentPositionRejectedReasonStatisticsData> {
        if (!this._getRejectedReasonStatisticsForEmploymentPosition) throw new Error("resource has not been properly initialized")
        return this._getRejectedReasonStatisticsForEmploymentPosition(null, null, {id: id}).toPromise()
    }

}