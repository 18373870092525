import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, TalentFeedbackData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentRateTalentagentController
 */

@Injectable()
@ResourceParams({})
export class TalentRateTalentagentResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talents/talent/fiveStarRating/canSend',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _canTalentSendFiveStarRating: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    canTalentSendFiveStarRating(): Promise<boolean> {
        if (!this._canTalentSendFiveStarRating) throw new Error("resource has not been properly initialized")
        return this._canTalentSendFiveStarRating(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talents/talent/npsScore/canSend',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _canTalentSendNpsScoreFeedback: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    canTalentSendNpsScoreFeedback(): Promise<boolean> {
        if (!this._canTalentSendNpsScoreFeedback) throw new Error("resource has not been properly initialized")
        return this._canTalentSendNpsScoreFeedback(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talents/talent/fiveStarRating',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendFiveStarRating: IResourceMethodObservableStrict<TalentFeedbackData, null, null, null> | undefined
    sendFiveStarRating(requestBody: TalentFeedbackData): Promise<null> {
        if (!this._sendFiveStarRating) throw new Error("resource has not been properly initialized")
        return this._sendFiveStarRating(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talents/talent/npsScore',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sendNpsScoreFeedback: IResourceMethodObservableStrict<TalentFeedbackData, null, null, null> | undefined
    sendNpsScoreFeedback(requestBody: TalentFeedbackData): Promise<null> {
        if (!this._sendNpsScoreFeedback) throw new Error("resource has not been properly initialized")
        return this._sendNpsScoreFeedback(requestBody, null, null).toPromise()
    }

}