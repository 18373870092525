import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentProfileImageController
 */

@Injectable()
@ResourceParams({})
export class TalentProfileImageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfileImage: IResourceMethodObservableStrict<null, null, null, Blob> | undefined
    getProfileImage(): Promise<Blob> {
        if (!this._getProfileImage) throw new Error("resource has not been properly initialized")
        return this._getProfileImage(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _uploadProfileImage: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, null, null> | undefined
    uploadProfileImage(requestBody: ArrayBuffer, requestParams: {fileName: string}): Promise<null> {
        if (!this._uploadProfileImage) throw new Error("resource has not been properly initialized")
        return this._uploadProfileImage(requestBody, requestParams, null).toPromise()
    }

}