import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {SubjectData, PagedData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.StudyInfoController
 */

@Injectable()
@ResourceParams({})
export class StudyInfoResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/studyInfo/studyAreas',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getStudyAreas: IResourceMethodObservableStrict<null, {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<LabelData>> | undefined
    getStudyAreas(requestParams?: {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<LabelData>> {
        if (!this._getStudyAreas) throw new Error("resource has not been properly initialized")
        return this._getStudyAreas(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/studyInfo/studyFields',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getStudyFields: IResourceMethodObservableStrict<null, {q?: string, page?: number, pageSize?: number, additionalStudyFieldIds?: number[]}, null, PagedData<LabelData>> | undefined
    getStudyFields(requestParams?: {q?: string, page?: number, pageSize?: number, additionalStudyFieldIds?: number[]}): Promise<PagedData<LabelData>> {
        if (!this._getStudyFields) throw new Error("resource has not been properly initialized")
        return this._getStudyFields(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/studyInfo/studyFields/byId',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getStudyFieldsById: IResourceMethodObservableStrict<null, {ids?: number[]}, null, LabelData[]> | undefined
    getStudyFieldsById(requestParams?: {ids?: number[]}): Promise<LabelData[]> {
        if (!this._getStudyFieldsById) throw new Error("resource has not been properly initialized")
        return this._getStudyFieldsById(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/studyInfo/subjects',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSubjects: IResourceMethodObservableStrict<null, {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<SubjectData>> | undefined
    getSubjects(requestParams?: {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<SubjectData>> {
        if (!this._getSubjects) throw new Error("resource has not been properly initialized")
        return this._getSubjects(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/studyInfo/subjects/byId',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSubjectsById: IResourceMethodObservableStrict<null, {ids?: number[]}, null, SubjectData[]> | undefined
    getSubjectsById(requestParams?: {ids?: number[]}): Promise<SubjectData[]> {
        if (!this._getSubjectsById) throw new Error("resource has not been properly initialized")
        return this._getSubjectsById(null, requestParams || {}, null).toPromise()
    }

}