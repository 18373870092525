import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {StudiesData, StudiesCreationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentStudiesController
 */

@Injectable()
@ResourceParams({})
export class TalentStudiesResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talentProfile/studies',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createStudies: IResourceMethodObservableStrict<StudiesCreationData, null, null, StudiesData> | undefined
    createStudies(requestBody: StudiesCreationData): Promise<StudiesData> {
        if (!this._createStudies) throw new Error("resource has not been properly initialized")
        return this._createStudies(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talentProfile/studies',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateStudies: IResourceMethodObservableStrict<StudiesData, null, null, StudiesData> | undefined
    updateStudies(requestBody: StudiesData): Promise<StudiesData> {
        if (!this._updateStudies) throw new Error("resource has not been properly initialized")
        return this._updateStudies(requestBody, null, null).toPromise()
    }

}