import {AfterContentInit, AfterViewChecked, AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {CareerArticleData} from "../../../../generated/hid/data";
import {MediaService} from "../../../utility/services/media.service";
import {AnimationsService} from "../../../utility/services/animations.service";

@Component({
  selector: 'app-career-guide-spotlight',
  templateUrl: './career-guide-spotlight.component.html',
  styleUrl: './career-guide-spotlight.component.scss'
})
export class CareerGuideSpotlightComponent implements OnInit, AfterViewInit{

  @Input() careerGuides: CareerArticleData[] = []
  l: boolean = false

  constructor(
    public mediaService: MediaService,
    private animationsService: AnimationsService
  ) {
  }

  ngOnInit() {
    this.handleAnimations()
  }

  ngAfterViewInit() {
  }

  handleAnimations() {
    this.animationsService.handleOnScrollAnimation('.guide', 'bounceIn', false)
  }

}
