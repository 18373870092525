import {Injectable} from '@angular/core';
import {Meta, MetaDefinition} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private meta: Meta,
  ) {
  }

  setMetaTag(tag: MetaDefinition) {

    this.removeMetaTag(tag.name)

    if (this.meta.getTag(tag.name) == null) {
      this.meta.addTag(tag, true);
    } else {
      this.meta.updateTag(tag);
    }

  }

  removeMetaTag(tag: string) {
    this.meta.removeTag(`name =${tag}`)
  }

  resetRobotsTag() {
    this.meta.updateTag({name: 'robots', content: 'index'});
  }

  setRobotsTag(index: boolean) {
    let content = ''
    if (index == true) {
      content = 'index'
    } else content = 'noindex'
    this.setMetaTag({name: 'robots',  content: content})
  }
}
