import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {S2OTalentCreationData, TalentCoachRequestDistributionData, UnisignAccountRegistrationSuccessData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.onboarding.Split2TalentOnboardingController
 */

@Injectable()
@ResourceParams({})
export class Split2TalentOnboardingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/s2fax/account',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateTalentAccount: IResourceMethodObservableStrict<S2OTalentCreationData, null, null, UnisignAccountRegistrationSuccessData> | undefined
    createOrUpdateTalentAccount(requestBody: S2OTalentCreationData): Promise<UnisignAccountRegistrationSuccessData> {
        if (!this._createOrUpdateTalentAccount) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateTalentAccount(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/onboarding/s2fax/distribution',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateDistributionCriteriaForCoachRequest: IResourceMethodObservableStrict<TalentCoachRequestDistributionData, null, null, null> | undefined
    updateDistributionCriteriaForCoachRequest(requestBody: TalentCoachRequestDistributionData): Promise<null> {
        if (!this._updateDistributionCriteriaForCoachRequest) throw new Error("resource has not been properly initialized")
        return this._updateDistributionCriteriaForCoachRequest(requestBody, null, null).toPromise()
    }

}