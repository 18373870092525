import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {ConsultantProfileData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantProfileController
 */

@Injectable()
@ResourceParams({})
export class ConsultantProfileResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultants/me/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getConsultantProfilePicture: IResourceMethodObservableStrict<null, null, null, Blob> | undefined
    getConsultantProfilePicture(): Promise<Blob> {
        if (!this._getConsultantProfilePicture) throw new Error("resource has not been properly initialized")
        return this._getConsultantProfilePicture(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultants/me',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOwnProfileData: IResourceMethodObservableStrict<null, null, null, ConsultantProfileData> | undefined
    getOwnProfileData(): Promise<ConsultantProfileData> {
        if (!this._getOwnProfileData) throw new Error("resource has not been properly initialized")
        return this._getOwnProfileData(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/consultants/me/update',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateConsultant: IResourceMethodObservableStrict<ConsultantProfileData, null, null, ConsultantProfileData> | undefined
    updateConsultant(requestBody: ConsultantProfileData): Promise<ConsultantProfileData> {
        if (!this._updateConsultant) throw new Error("resource has not been properly initialized")
        return this._updateConsultant(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultants/me/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateConsultantProfilePicture: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, null, null> | undefined
    updateConsultantProfilePicture(requestBody: ArrayBuffer, requestParams: {fileName: string}): Promise<null> {
        if (!this._updateConsultantProfilePicture) throw new Error("resource has not been properly initialized")
        return this._updateConsultantProfilePicture(requestBody, requestParams, null).toPromise()
    }

}