import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, FederalStateData, CityData, PagedData, CountryData, CityDistanceData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.LocationController
 */

@Injectable()
@ResourceParams({})
export class LocationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/locations/cities/exists',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _cityExistsByName: IResourceMethodObservableStrict<null, {cityName: string}, null, JsonWrappedValue<boolean>> | undefined
    cityExistsByName(requestParams: {cityName: string}): Promise<boolean> {
        if (!this._cityExistsByName) throw new Error("resource has not been properly initialized")
        return this._cityExistsByName(null, requestParams, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/locations/cities',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCities: IResourceMethodObservableStrict<null, {q?: string, federalStateId?: number, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<CityData>> | undefined
    getCities(requestParams?: {q?: string, federalStateId?: number, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<CityData>> {
        if (!this._getCities) throw new Error("resource has not been properly initialized")
        return this._getCities(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/locations/cities/ids',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCitiesById: IResourceMethodObservableStrict<null, {cityIds: number[]}, null, CityData[]> | undefined
    getCitiesById(requestParams: {cityIds: number[]}): Promise<CityData[]> {
        if (!this._getCitiesById) throw new Error("resource has not been properly initialized")
        return this._getCitiesById(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/locations/cities/query',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCitiesByQueryOrZipCode: IResourceMethodObservableStrict<null, {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<CityData>> | undefined
    getCitiesByQueryOrZipCode(requestParams?: {query?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<CityData>> {
        if (!this._getCitiesByQueryOrZipCode) throw new Error("resource has not been properly initialized")
        return this._getCitiesByQueryOrZipCode(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/locations/countries',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCountries: IResourceMethodObservableStrict<null, {q?: string, page?: number, pageSize?: number}, null, PagedData<CountryData>> | undefined
    getCountries(requestParams?: {q?: string, page?: number, pageSize?: number}): Promise<PagedData<CountryData>> {
        if (!this._getCountries) throw new Error("resource has not been properly initialized")
        return this._getCountries(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/locations/country/{countryId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCountryById: IResourceMethodObservableStrict<null, null, {countryId: number}, CountryData> | undefined
    getCountryById(countryId: number): Promise<CountryData> {
        if (!this._getCountryById) throw new Error("resource has not been properly initialized")
        return this._getCountryById(null, null, {countryId: countryId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/locations/federalStates',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFederalStatesByQuery: IResourceMethodObservableStrict<null, {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<FederalStateData>> | undefined
    getFederalStatesByQuery(requestParams?: {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<FederalStateData>> {
        if (!this._getFederalStatesByQuery) throw new Error("resource has not been properly initialized")
        return this._getFederalStatesByQuery(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/locations/cities/id/exists',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getGermanCityById: IResourceMethodObservableStrict<null, {cityId: number}, null, CityData> | undefined
    getGermanCityById(requestParams: {cityId: number}): Promise<CityData> {
        if (!this._getGermanCityById) throw new Error("resource has not been properly initialized")
        return this._getGermanCityById(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/locations/cities/name/exists',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getGermanCityByName: IResourceMethodObservableStrict<null, {cityName: string}, null, CityData> | undefined
    getGermanCityByName(requestParams: {cityName: string}): Promise<CityData> {
        if (!this._getGermanCityByName) throw new Error("resource has not been properly initialized")
        return this._getGermanCityByName(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/locations/maxRadiusAroundCityForGreenMatch',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMaxRadiusAroundCityForGreenMatch: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getMaxRadiusAroundCityForGreenMatch(): Promise<JsonWrappedValue<number>> {
        if (!this._getMaxRadiusAroundCityForGreenMatch) throw new Error("resource has not been properly initialized")
        return this._getMaxRadiusAroundCityForGreenMatch(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/locations/cities/distances',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentPositionCityDistances: IResourceMethodObservableStrict<null, {talentId: number, positionId: number}, null, CityDistanceData[]> | undefined
    getTalentPositionCityDistances(requestParams: {talentId: number, positionId: number}): Promise<CityDistanceData[]> {
        if (!this._getTalentPositionCityDistances) throw new Error("resource has not been properly initialized")
        return this._getTalentPositionCityDistances(null, requestParams, null).toPromise()
    }

}