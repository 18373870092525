<div (appClickOutside)="close()">
  <div class="burger-menu" [class.active]="active" (click)="onBurgerClicked()">
    <div class="burger-container">
      <div class="burger-inner"></div>
    </div>
  </div>

  <div class="flex column gap menu-list padding center" *ngIf="show" (click)="close()" #menu>

    <a routerLink="/talent">
      <button
        [ngClass]="{'active-menupoint': router.url.includes('talent')}"
        class="menu-button width-100">Talent</button>
    </a>

    <a routerLink="/company">
      <button
        [ngClass]="{'active-menupoint': router.url.includes('company')}"
        class="menu-button width-100">Unternehmen</button>
    </a>

    <a href="https://uniwunder.com/"  target="_blank"><button class="menu-button width-100">About Us</button></a>

    <div class="divider"></div>

    <a  *ngIf="!router.url.includes('company') && !router.url.includes('resources') && !router.url.includes('caseStudies')"
        (click)="routeToTalentAgent()">
      <button
              class="menu-button width-100" >Login</button>
    </a>

    <a *ngIf="!router.url.includes('company') && !router.url.includes('resources') && !router.url.includes('caseStudies')"
       (click)="routeToTalentAgentOb()">
      <button
               class="accent-button ita_initiate-checkout ita_format-talentagent page-content-cta" >Anmelden</button>
    </a>

    <a *ngIf="router.url.includes('company') || router.url.includes('resources') || router.url.includes('caseStudies') "
       (click)="routeToCompanyLogin()">
      <button
               class="menu-button width-100" >Login</button>
    </a>

    <a *ngIf="router.url.includes('company') || router.url.includes('resources') || router.url.includes('caseStudies') "
       (click)="routeToDemoDesk()">
      <button
               class="accent-button">Demo buchen</button>
    </a>

  </div>
</div>

