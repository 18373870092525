import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, InstantApplicationCreatedData, TalentCreationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.InstantApplicationController
 */

@Injectable()
@ResourceParams({})
export class InstantApplicationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/application/position',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createInstantApplication: IResourceMethodObservableStrict<TalentCreationData, null, null, InstantApplicationCreatedData> | undefined
    createInstantApplication(requestBody: TalentCreationData): Promise<InstantApplicationCreatedData> {
        if (!this._createInstantApplication) throw new Error("resource has not been properly initialized")
        return this._createInstantApplication(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/application/talent',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _existsInstantApplicationForTalent: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    existsInstantApplicationForTalent(): Promise<boolean> {
        if (!this._existsInstantApplicationForTalent) throw new Error("resource has not been properly initialized")
        return this._existsInstantApplicationForTalent(null, null, null).toPromise().then((result) => result.value)
    }

}