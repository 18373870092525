import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, PublicEmploymentPositionDetailsData, CityData, PositionChipData, ConsultantEmploymentPositionData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantEmploymentPositionController
 */

@Injectable()
@ResourceParams({})
export class ConsultantEmploymentPositionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talent/{talentId}/positions/{positionId}/chips',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionChipsById: IResourceMethodObservableStrict<null, null, {talentId: number, positionId: number}, PositionChipData[]> | undefined
    getEmploymentPositionChipsById(pathVariables: {talentId: number, positionId: number}): Promise<PositionChipData[]> {
        if (!this._getEmploymentPositionChipsById) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionChipsById(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talent/{talentId}/positions/{positionId}/locations',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLocationsForTalent: IResourceMethodObservableStrict<null, null, {talentId: number, positionId: number}, CityData[]> | undefined
    getLocationsForTalent(pathVariables: {talentId: number, positionId: number}): Promise<CityData[]> {
        if (!this._getLocationsForTalent) throw new Error("resource has not been properly initialized")
        return this._getLocationsForTalent(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talent/{talentId}/positions/{positionId}/personalizedLocation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPersonalizedLocationForTalent: IResourceMethodObservableStrict<null, null, {talentId: number, positionId: number}, JsonWrappedValue<string>> | undefined
    getPersonalizedLocationForTalent(pathVariables: {talentId: number, positionId: number}): Promise<string> {
        if (!this._getPersonalizedLocationForTalent) throw new Error("resource has not been properly initialized")
        return this._getPersonalizedLocationForTalent(null, null, pathVariables).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talent/{talentId}/positions/{positionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionForTalent: IResourceMethodObservableStrict<null, null, {positionId: number, talentId: number}, PublicEmploymentPositionDetailsData> | undefined
    getPositionForTalent(pathVariables: {positionId: number, talentId: number}): Promise<PublicEmploymentPositionDetailsData> {
        if (!this._getPositionForTalent) throw new Error("resource has not been properly initialized")
        return this._getPositionForTalent(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/talent/{talentId}/positions/{positionId}/usps',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionUspsForTalent: IResourceMethodObservableStrict<null, {splitId?: number}, {talentId: number, positionId: number}, PositionChipData[]> | undefined
    getPositionUspsForTalent(pathVariables: {talentId: number, positionId: number}, requestParams?: {splitId?: number}): Promise<PositionChipData[]> {
        if (!this._getPositionUspsForTalent) throw new Error("resource has not been properly initialized")
        return this._getPositionUspsForTalent(null, requestParams || {}, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/consultant/positions/{employmentPositionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleEmploymentPositionById: IResourceMethodObservableStrict<null, null, {employmentPositionId: number}, ConsultantEmploymentPositionData> | undefined
    getSingleEmploymentPositionById(employmentPositionId: number): Promise<ConsultantEmploymentPositionData> {
        if (!this._getSingleEmploymentPositionById) throw new Error("resource has not been properly initialized")
        return this._getSingleEmploymentPositionById(null, null, {employmentPositionId: employmentPositionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/consultant/talent/{talentId}/primePositions/{employmentPositionId}/markAsUnsuitable',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _markPrimePositionAsUnsuitable: IResourceMethodObservableStrict<string, null, {employmentPositionId: number, talentId: number}, null> | undefined
    markPrimePositionAsUnsuitable(requestBody: string, pathVariables: {employmentPositionId: number, talentId: number}): Promise<null> {
        if (!this._markPrimePositionAsUnsuitable) throw new Error("resource has not been properly initialized")
        return this._markPrimePositionAsUnsuitable(requestBody, null, pathVariables).toPromise()
    }

}