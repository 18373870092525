import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, TalentPositionDetailsData, TalentHomeStatusData, TalentApplicationMinimalData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentHomeController
 */

@Injectable()
@ResourceParams({})
export class TalentHomeResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/home/applications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationsForDashboard: IResourceMethodObservableStrict<null, null, null, TalentApplicationMinimalData[]> | undefined
    getApplicationsForDashboard(): Promise<TalentApplicationMinimalData[]> {
        if (!this._getApplicationsForDashboard) throw new Error("resource has not been properly initialized")
        return this._getApplicationsForDashboard(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/home/bookmarks',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getBookmarksForDashboard: IResourceMethodObservableStrict<null, null, null, TalentPositionDetailsData[]> | undefined
    getBookmarksForDashboard(): Promise<TalentPositionDetailsData[]> {
        if (!this._getBookmarksForDashboard) throw new Error("resource has not been properly initialized")
        return this._getBookmarksForDashboard(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/home/status',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getDashboardStatus: IResourceMethodObservableStrict<null, null, null, TalentHomeStatusData> | undefined
    getDashboardStatus(): Promise<TalentHomeStatusData> {
        if (!this._getDashboardStatus) throw new Error("resource has not been properly initialized")
        return this._getDashboardStatus(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/home/matchesCount',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMatchesCount: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getMatchesCount(): Promise<JsonWrappedValue<number>> {
        if (!this._getMatchesCount) throw new Error("resource has not been properly initialized")
        return this._getMatchesCount(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/home/recommendedCount',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRecommendedCount: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getRecommendedCount(): Promise<JsonWrappedValue<number>> {
        if (!this._getRecommendedCount) throw new Error("resource has not been properly initialized")
        return this._getRecommendedCount(null, null, null).toPromise()
    }

}