import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {WebinarRegistrationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyWebinarController
 */

@Injectable()
@ResourceParams({})
export class CompanyWebinarResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/webinarRegistration/create',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createWebinarRegistration: IResourceMethodObservableStrict<WebinarRegistrationData, null, null, WebinarRegistrationData> | undefined
    createWebinarRegistration(requestBody: WebinarRegistrationData): Promise<WebinarRegistrationData> {
        if (!this._createWebinarRegistration) throw new Error("resource has not been properly initialized")
        return this._createWebinarRegistration(requestBody, null, null).toPromise()
    }

}