import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PositionDetailsComponent} from './position-details/position-details.component';
import {RouterModule} from "@angular/router";
import {PositionContentComponent} from './position-details/position-content/position-content.component';
import {UtilityModule} from "../../utility/utility.module";


@NgModule({
  declarations: [PositionDetailsComponent, PositionContentComponent],
  imports: [
    CommonModule,
    UtilityModule,
  ],
  exports:[
    RouterModule
  ]
})
export class PositionDetailsModule { }
