import {Component, Input, OnInit} from '@angular/core';
import {EmploymentPositionData} from "../../../general/talent-page/talent-page.component";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";

@Component({
  selector: 'app-employment-position-card',
  templateUrl: './employment-position-card.component.html',
  styleUrl: './employment-position-card.component.scss'
})
export class EmploymentPositionCardComponent implements OnInit{

  @Input() positionData: EmploymentPositionData = <EmploymentPositionData>{}


  constructor(
    private router: Router
  ) { }

  ngOnInit() {

  }
  routeToPosition() {
    this.router.navigateByUrl(`/position-details/${this.positionData.id}`)
  }

  protected readonly environment = environment;
}
