<div
        [ngClass]="{'padding-vertical-lg': mediaService.getScreenWidth() > 960}"
        class="width-100 flex center column">

  <section *ngIf="mediaService.getScreenWidth() > 960" class="flex column center padding-vertical-lg">
    <a routerLink="/company">
      <img src="assets/images/talentagent-logo.png" alt="">
    </a>
  </section>

  <section class="width-100 height-100 flex column center ">
    <div
            [ngClass]="{'row': mediaService.getScreenWidth() > 960, 'column-reverse': mediaService.getScreenWidth() <= 960}"
            class="demo-desk-container flex center-stretch">
      <div
              [ngClass]="{'width-33': mediaService.getScreenWidth() > 960, 'width-100': mediaService.getScreenWidth() <= 960}"
              class=" padding flex start column">
        <img  *ngIf="mediaService.getScreenWidth() > 960" class="header-logo" src="assets/images/uniwunder_fav.png" alt="">
        <img  *ngIf="mediaService.getScreenWidth() <= 960" class="margin-bottom" src="assets/images/talentagent-logo.png" alt="">

        <!--        <p class="color-accent bold-text"></p>-->
        <p
                [ngClass]="{'bold-text': mediaService.getScreenWidth() > 960, 'large-text': mediaService.getScreenWidth() <= 960}"
                class=" color-marine">Lerne in 20 Minuten die Nr. 1 Jobplattform für Uni-Absolventen kennen:</p>
        <ul class="demo-list">
          <li class="regular-text">
            <i class="mdi mdi-check-circle"></i>
            Erhalte Einblicke in die Funktionsweise unserer Plattform
          </li>
          <li class="regular-text">
            <i class="mdi mdi-check-circle"></i>
            Entdecke das Kandidatenpotenzial auf Talentagent
          </li>
          <li class="regular-text">
            <i class="mdi mdi-check-circle"></i>
            Lass dich zu einem passenden Bewerberpaket beraten
          </li>
        </ul>
        <p class="regular-text">Wir freuen uns auf den gemeinsamen Austausch und zeigen dir, wie einfach Junior-Recruiting sein kann.</p>
      </div>

<!--      <div *ngIf="mediaService.getScreenWidth() > 960" class="absolute-divider"></div>-->

      <div *ngIf=" mediaService.getScreenWidth() > 960" class="vertical-divider"></div>

      <div [ngClass]="{'width-66': mediaService.getScreenWidth() > 960, 'width-100': mediaService.getScreenWidth() <= 960}">
        <div id="demodesk-iframe-inserter"></div>
      </div>
    </div>
  </section>

  <section class="padding-xl"></section>
</div>
