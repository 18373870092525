import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicFilesController
 */

@Injectable()
@ResourceParams({})
export class PublicFilesResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/files/images/{fileId}',
        endpoint: 'net.innospire.hidcms'
    })
    private _getFileById: IResourceMethodObservableStrict<null, null, {fileId: number}, Blob> | undefined
    getFileById(fileId: number): Promise<Blob> {
        if (!this._getFileById) throw new Error("resource has not been properly initialized")
        return this._getFileById(null, null, {fileId: fileId}).toPromise()
    }

}