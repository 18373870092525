<div>
  Junior-Recruiting auf klassischen Stellenbörsen führt meist zu einer Vielzahl unpassender Bewerbungen,
  was den Sichtungsaufwand stark erhöht und wertvolle Zeit raubt. Mit Talentagent hingegen werden Ressourcen durch geprüfte Juniorbewerber gespart.
  Die garantierte Anzahl an Bewerbungen ohne begrenzte Laufzeit sorgt für vollständige Kostentransparenz – die Recruiter wissen von Anfang an,
  wie viel jede Bewerbung kostet.
</div>

<div class="cards-wrapper">
  <div class="competition-card">
    <h3>Auf klassischen Stellenbörsen</h3>
    <div class="flex column">
      <div class="flex row start-center gap-sm bold-text">
        <i class="mdi mdi-close-circle light-text"></i>
        Hohe Anzahl unpassender Bewerber
      </div>
      <p>Recruiter erhalten überwiegend unpassende Juniorbewerbungen, welche nur unnötigen Aufwand mitbringen.</p>
    </div>

    <div class="flex column">
      <div class="flex row start-center gap-sm bold-text">
        <i class="mdi mdi-close-circle light-text"></i>
        Unsicherheit über Bewerberzahlen
      </div>
      <p>Recruiter haben keine Garantie, ob ihre Stellenanzeigen genügend passende Talente überzeugen werden.</p>
    </div>

    <div class="flex column">
      <div class="flex row start-center gap-sm bold-text">
        <i class="mdi mdi-close-circle light-text"></i>
        Zeitdruck durch begrenzte Laufzeiten
      </div>
      <p>Nach Ablauf der Befristung gehen die Anzeigen offline, bevor genug geeignete Absolventen gefunden werden.</p>
    </div>

    <div class="flex column">
      <div class="flex row start-center gap-sm bold-text">
        <i class="mdi mdi-close-circle light-text"></i>
        Keine Sichtbarkeit bei der Zielgruppe
      </div>
      <p>Die meisten Stellenanzeigen erreichen nicht die relevanten Kandidaten, welche die gewünschten Anforderungen erfüllen.</p>
    </div>
  </div>

  <div class="ta-card">
    <h3>Mit Talentagent</h3>

    <div class="flex column">
      <div class="flex row start-center gap-sm bold-text">
        <i class="mdi mdi-check-circle color-primary"></i>
        Weniger Aufwand, mehr Relevanz
      </div>
      <p>Auf Talentagent werden nur Bewerber mit Hochschulabschluss, Deutsch auf C1-Niveau und EU-Arbeitserlaubnis übermittelt.</p>
    </div>

    <div class="flex column">
      <div class="flex row start-center gap-sm bold-text">
        <i class="mdi mdi-check-circle color-primary"></i>
        Garantierte Anzahl an Bewerbungen
      </div>
      <p>Auf Talentagent bleiben die Jobanzeigen online, bis das gebuchte Bewerberpaket ausgeschöpft ist. </p>
    </div>

    <div class="flex column">
      <div class="flex row start-center gap-sm bold-text">
        <i class="mdi mdi-check-circle color-primary"></i>
        Anzeigen ohne feste Laufzeit
      </div>
      <p>Die flexible Verwaltung der Jobangebote ohne feste Laufzeit ermöglicht es, Anzeigen jederzeit zu schalten und zu bearbeiten.</p>
    </div>

    <div class="flex column">
      <div class="flex row start-center gap-sm bold-text">
        <i class="mdi mdi-check-circle color-primary"></i>
        Zielgerichtete Stellenausspielung
      </div>
      <p>Die Jobangebote erreichen nur passende Uni-Absolventen auf Talentagent. Zusätzlich werden die Talente per E-Mail und SMS auf die Stellenanzeigen aufmerksam gemacht.</p>
    </div>

  </div>
</div>
