import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {LinkHubPageData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicLinkHubController
 */

@Injectable()
@ResourceParams({})
export class PublicLinkHubResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/linkhub/{routingUrl}',
        endpoint: 'net.innospire.hidcms'
    })
    private _getPageByRoutingUrl: IResourceMethodObservableStrict<null, null, {routingUrl: string}, LinkHubPageData> | undefined
    getPageByRoutingUrl(routingUrl: string): Promise<LinkHubPageData> {
        if (!this._getPageByRoutingUrl) throw new Error("resource has not been properly initialized")
        return this._getPageByRoutingUrl(null, null, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/linkhub/preview/{id}',
        endpoint: 'net.innospire.hidcms'
    })
    private _getSplitPreview: IResourceMethodObservableStrict<null, null, {id: string}, LinkHubPageData> | undefined
    getSplitPreview(id: string): Promise<LinkHubPageData> {
        if (!this._getSplitPreview) throw new Error("resource has not been properly initialized")
        return this._getSplitPreview(null, null, {id: id}).toPromise()
    }

}