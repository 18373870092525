import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {ConsultantTalentPositionPreviewData, SearchFilterData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.consultant.ConsultantPositionSearchController
 */

@Injectable()
@ResourceParams({})
export class ConsultantPositionSearchResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/consultant/positions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _searchPositionsForTalent: IResourceMethodObservableStrict<SearchFilterData, {talentId: number, pageNum: number, pageSize: number}, null, PagedData<ConsultantTalentPositionPreviewData>> | undefined
    searchPositionsForTalent(requestBody: SearchFilterData, requestParams: {talentId: number, pageNum: number, pageSize: number}): Promise<PagedData<ConsultantTalentPositionPreviewData>> {
        if (!this._searchPositionsForTalent) throw new Error("resource has not been properly initialized")
        return this._searchPositionsForTalent(requestBody, requestParams, null).toPromise()
    }

}