import {Injectable} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UtmService {

  params: Params = {}

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  startUtmParamsSubscription() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.params = params
    })
  }

  getUtmParamsAsString(): string {
    let utmString =  new URLSearchParams(this.params).toString()
    if (utmString == '') return ''
    return '?' + utmString
  }
}
