import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, AccountInfoData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.AccountController
 */

@Injectable()
@ResourceParams({})
export class AccountResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/finalize',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _finalizeOnboarding: IResourceMethodObservableStrict<null, null, null, null> | undefined
    finalizeOnboarding(): Promise<null> {
        if (!this._finalizeOnboarding) throw new Error("resource has not been properly initialized")
        return this._finalizeOnboarding(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/accountInfo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAccountInfo: IResourceMethodObservableStrict<null, null, null, AccountInfoData> | undefined
    getAccountInfo(): Promise<AccountInfoData> {
        if (!this._getAccountInfo) throw new Error("resource has not been properly initialized")
        return this._getAccountInfo(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/version',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getVersion: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<string>> | undefined
    getVersion(): Promise<string> {
        if (!this._getVersion) throw new Error("resource has not been properly initialized")
        return this._getVersion(null, null, null).toPromise().then((result) => result.value)
    }

}